<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible" class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-[625px] rounded-lg bg-white dark:bg-boxdark max-h-full overflow-y-auto">

          <div class="flex w-full justify-between border-b border-stroke p-8.5">
            <h3 class="font-inter font-bold text-2xl text-black">Редактирование роли {{ role?.role.name }}</h3>
            <CloseButton @click="closeSelf"/>
          </div>

          <RoleForm
              @form:submit="submit"
              @form:cancel="closeSelf"
              class="py-6 px-8.5"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {type Ref, watch} from "vue";
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {useRoleForm} from "@/stores/roleForm";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import CloseButton from "@/shared/Buttons/CloseButton.vue";
import RoleForm from "@/shared/Forms/RoleForm.vue";
import type {getRoleResponse} from "@/types/responses";

const props = defineProps<{
  role: getRoleResponse | null
  keepFormOnClose?: boolean
}>()

const visible: Ref<boolean> = defineModel({ required: true })
const emit = defineEmits(['refresh:table'])

const alerts = useAlerts()
const api = useApi()
const roleForm = useRoleForm()

watch(() => props.role, role => {
  if (role === null) return
  roleForm.set(role)
})

function closeSelf() {
  if (!props.keepFormOnClose) roleForm.clearAndClose()
  visible.value = false
}

function submit(): void {
  roleForm.clearErrors()
  if (!roleForm.validate() || props.role === null) return
  const request = roleForm.toRequest()

  api.user.updateRole(props.role.role.id, request)
      .then(response => {
        alerts.flash('success', 'Роль успешно обновлена')
        closeSelf()
        emit('refresh:table')
      })
      .catch(err => {
        if (err instanceof UserServiceValidationError) {
          for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (roleForm.errors.hasOwnProperty(field)) {
              roleForm.errors[field] = validationErrors
            }
          }
        }
      })
}

</script>
