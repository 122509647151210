import {useApi} from "@/stores/api";
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
import type {LastLogin, PermissionGroupList, User} from "@/types/entities";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {UserServiceForbiddenError} from "@/errors/UserServiceForbiddenError";

export default function useUserInfo() {
    const api = useApi()
    const route = useRoute()
    const router = useRouter()

    const user = ref<User>()
    const roleId = ref(0)
    const roleName = ref('Не назначена')
    const permissions = ref<PermissionGroupList>()
    const lastLogin = ref<LastLogin>()
    const canEdit = ref(false)

    const userId = Number(route.params.id)

    if (isNaN(userId)) router.push('/404')

    api.user.user(userId)
        .then(response => {
            user.value = response.user
            permissions.value = response.permissions
            lastLogin.value = response.lastLogin
            canEdit.value = response.can_edit

            if (response.user.role) {
                roleId.value = response.user.role.id
                roleName.value = response.user.role.name
            }
        })
        .catch(err => {
            if (err instanceof UserServiceForbiddenError) router.push('/403')
        })

    return {
        user,
        userId,
        roleId,
        roleName,
        permissions,
        lastLogin,
        canEdit
    }
}
