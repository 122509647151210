<template>
  <Card>
    <div class="mb-5.5 flex flex-col gap-5.5">
      <TextInput
          v-model="form.name"
          label="Нзвание"
          parameter="name"
          class="w-full"
          placeholder="Название"
          :errors="errors.name"
      />

      <SearchSelect
          v-model="form.geo"
          label="Гео"
          parameter="geo"
          class="w-full"
          :options="geoOptions"
          placeholder="Выберите GEO"
          :errors="errors.geo"
      />

      <SearchSelect
          v-model="form.theme_id"
          label="Тематика"
          placeholder="Выберите тематику"
          parameter="theme_id"
          class="w-full"
          :options="themeOptions"
          :errors="errors.theme_id"
      />

      <StreamLinkForm
          v-model="form.links"
          :links="links"
          :link-options="linkOptions"
          :disabled="form.geo.length===0"
          :errors="errors.links"
      />
    </div>
    <StandardButton @click="submit">{{ buttonText }}</StandardButton>
  </Card>
</template>

<script setup lang="ts">
import type {MultiSelectOptions, SelectOption, StreamForm,} from "@/types/props";
import {type ModelRef} from "vue";
import Card from "@/shared/Card.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import GeoSelect from "@/shared/Inputs/GeoSelect.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import StreamLinkForm from "@/shared/Forms/StreamLinkForm.vue";
import SearchSelect from "@/shared/Inputs/SearchSelect.vue";

const props = defineProps<{
  errors: Record<keyof(StreamForm), string[]>
  linkOptions: MultiSelectOptions
  links: Record<number, string>
  themeOptions: MultiSelectOptions
  geoOptions: MultiSelectOptions
  buttonText: string
}>()

const form: ModelRef<StreamForm> = defineModel({ required: true })

const emit = defineEmits(['form:submit'])

function submit() { 
  emit('form:submit')
}

</script>
