const validatorErrorMessages = {
    accepted: 'Вы должны принять :attribute.',
    alpha: 'Поле :attribute может содержать только буквы.',
    alpha_dash: 'Поле :attribute может содержать только буквы, цифры, дефисы и символы подчёркивания.',
    alpha_num: 'Поле :attribute может содержать только буквы и цифры.',
    between: 'Поле :attribute должно быть между :min и :max.',
    confirmed: 'Поле :attribute не совпадает с подтверждением.',
    email: 'Поле :attribute должно быть действительным электронным адресом.',
    def: 'Поле :attribute содержит ошибки.',
    digits: 'Длина цифрового поля :attribute должна быть :digits.',
    digits_between: 'Длинна цифрового поля :attribute должна быть от :min до :max знаков.',
    different: 'Поля :attribute и :different должны различаться.',
    in: 'Выбранное значение для :attribute ошибочно.',
    integer: 'Поле :attribute должно быть целым числом.',
    hex: 'Поле :attribute должно иметь шестнадцатеричный формат',
    min: {
        numeric: 'Значение поля :attribute должно быть больше или равно :min.',
        string: 'Количество символов в поле :attribute должно быть не менее :min.'
    },
    max: {
        numeric: 'Значение поля :attribute должно быть меньше или равно :max.',
        string: 'Количество символов в поле :attribute не может превышать :max.'
    },
    not_in: 'Выбранное значение для :attribute ошибочно.',
    numeric: 'Поле :attribute должно быть числом.',
    present: 'Поле :attribute должно присутствовать (но может быть пустым).',
    required: 'Поле :attribute обязательно для заполнения.',
    required_if: 'Поле :attribute требуется когда значения поля :other равно :value.',
    same: 'Значение :attribute должно совпадать с :same.',
    size: {
        numeric: 'Значение поля :attribute должно быть равным :size.',
        string: 'Количество символов в поле :attribute должно быть равно :size.'
    },
    url: 'Поле :attribute должно содержать валидный URL.',
    regex: 'Неверный формат поля :attribute.',
    attributes: {}
};

export default validatorErrorMessages
