<template>
  <div>
    <Card class="w-full sm:w-2/3">
      <template #title>Базовые настройки</template>

      <div class="mb-5.5 flex flex-col gap-5.5 w-3/4">
        <TextInput v-model="form.name" label="Название" placeholder="Название" parameter="name" class="w-full"
          :errors="errors.name">
        </TextInput>

        <SearchMultiSelect v-model="form.news_sources" parameter="news_sources" :options="templatesOptions"
          placeholder="Выберите из списка" label="Источники" class="w-full" :errors="errors.news_sources" />
      </div>
    </Card>


    <Card class="w-full sm:w-2/3 mt-10">
      <template #title>Настройки шаблона кампаний</template>

      <div class="mb-5.5 flex flex-col gap-5.5 w-3/4">
        <TextInput v-model="form.utm_source" label="utm_source" placeholder="utm_source" parameter="utm_source"
          class="w-full" :errors="errors.utm_source">
        </TextInput>
        <TextInput v-model="form.utm_campaign" label="utm_campaign" placeholder="utm_campaign" parameter="utm_campaign"
          class="w-full" :errors="errors.utm_campaign">
        </TextInput>
        <TextInput v-model="form.utm_medium" label="utm_medium" placeholder="utm_medium" parameter="utm_medium"
          class="w-full" :errors="errors.utm_medium">
        </TextInput>
        <TextInput v-model="form.utm_term" label="utm_term" placeholder="utm_term" parameter="utm_term" class="w-full"
          :errors="errors.utm_term">
        </TextInput>
        <TextInput v-model="form.utm_content" label="utm_content" placeholder="utm_content" parameter="utm_content"
          class="w-full" :errors="errors.utm_content">
        </TextInput>
        <TextInput v-model="form.link_tale" label="Хвост ссылки" placeholder="Хвост ссылки" parameter="link_tale"
          class="w-full" :errors="errors.link_tale">
        </TextInput>

        <div class="mb-5.5 flex gap-5.5">
          <div class="mb-5.5 w-1/2 flex flex-col gap-5.5">
            <TextInput :numbers-only="true" v-model="form.title_min" label="Заголовок мин. знаков" placeholder="Заголовок мин. знаков"
              parameter="title_min" class="w-full" :errors="errors.title_min">
            </TextInput>
            <TextInput :numbers-only="true" v-model="form.desc_min" label="Описание мин. знаков" placeholder="Описание мин. знаков"
              parameter="desc_min" class="w-full" :errors="errors.desc_min">
            </TextInput>
            <TextInput :numbers-only="true" v-model="form.img_width" label="Изображение ширина" placeholder="Изображение ширина"
              parameter="img_width" class="w-full" :errors="errors.img_width">
            </TextInput>
            <TextInput :numbers-only="true" v-model="form.parse_fix" label="parse_fix" placeholder="parse_fix" parameter="parse_fix"
              class="w-full" :errors="errors.parse_fix">
            </TextInput>
          </div>

          <div class="mb-5.5 w-1/2 flex flex-col gap-5.5">
            <TextInput :numbers-only="true" v-model="form.title_max" label="Заголовок макс. знаков"
              placeholder="Заголовок макс. знаков" parameter="title_max" class="w-full" :errors="errors.title_max">
            </TextInput>
            <TextInput :numbers-only="true" v-model="form.desc_max" label="Описание макс. знаков" placeholder="Описание макс. знаков"
              parameter="desc_max" class="w-full" :errors="errors.desc_max">
            </TextInput>
            <TextInput :numbers-only="true" v-model="form.img_height" label="Изображение высота" placeholder="Изображение высота"
              parameter="img_height" class="w-full" :errors="errors.img_height">
            </TextInput>
          </div>
        </div>

        <div class="mb-5.5 flex gap-5.5">
          <div class="mb-5.5 w-1/2 flex flex-col gap-5.5">

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                Парсер расходов
              </label>
              <ToggleInput v-model="form.parse_expenses" parameter="parse_expenses" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                Автостоп и автоудаление
              </label>
              <ToggleInput v-model="form.auto_stop_delete" parameter="auto_stop_delete" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                Синхронизация
              </label>
              <ToggleInput v-model="form.sync" parameter="sync" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                Использование блэклистов
              </label>
              <ToggleInput v-model="form.use_blacklist" parameter="use_blacklist" />
            </div>
          </div>

          <div class="mb-5.5 w-1/2 flex flex-col gap-5.5">

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                is_visible
              </label>
              <ToggleInput v-model="form.is_visible" parameter="is_visible" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                clean_flag
              </label>
              <ToggleInput v-model="form.clean_flag" parameter="clean_flag" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                upload_time_flag
              </label>
              <ToggleInput v-model="form.upload_time_flag" parameter="upload_time_flag" />
            </div>

            <div class="flex justify-between w-full">
              <label class="mb-3 block font-medium text-black dark:text-white">
                acceptable_ratings
              </label>
              <ToggleInput v-model="form.acceptable_ratings" parameter="acceptable_ratings" />
            </div>
          </div>
        </div>

      </div>

      <div class="flex gap-4.5">
        <RouterLink to="/sources">
          <ClearButton>Отмена</ClearButton>
        </RouterLink>

        <StandardButton @click="submit">Сохранить</StandardButton>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { type Ref, ref, toRaw } from "vue";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import type { AlphaNumericalRecord, SelectOption } from "@/types/props";
import useTemplateForm from "@/composables/useTemplateForm";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import ToggleInput from "@/shared/Inputs/ToggleInput.vue";

const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()
const templatesOptions: Ref<AlphaNumericalRecord> = ref({})

api.sources.sources()
  .then(response => {
    let options: AlphaNumericalRecord = {}
    for (let source of response.newsSources) {
      options[Number(source.id)] = source.name;
    }

    templatesOptions.value = options
  })

const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors,
  prepareToRequest
} = useTemplateForm()

function submit() {
  clearErrors()
  prepareToRequest()

  if (!validateForm() || !userStore.user) return

  api.sources.templateCreate(toRaw(form))
    .then(response => {
      alertStore.flash('success', 'Шаблон успешно создан')
      router.push('/templates')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
