<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable
          v-if="data"
          @refresh:rows="getData"
          :headers="headers"
          :rows="data"
          :actions="actions"
          :column-order="['id', 'description', 'key', 'geo', 'source', 'utm_source', 'locale', 'teaser_count', 'updated_at']"
          :per-page="20"
          hide-actions-title
          empty-top
      >
        <div class="flex flex-col-reverse justify-between pt-8 pb-4 px-7.5 gap-4 sm:flex-row sm:gap-0">
          <TableSearchInput v-model="filters.searchValue" placeholder="Поиск" />

          <RouterLink to="/manual-tops/add">
            <StandardButton class="items-center w-full sm:w-auto"><PlusIcon class="fill-current mr-2"/>Создать ручной топ</StandardButton>
          </RouterLink>
        </div>

        <div class="flex gap-4 pb-8 px-7.5 w-full border-b border-stroke flex-col sm:flex-row">
          <SelectInput v-model="filters.key" parameter="key" :options="keyOptions" placeholder="Топ" class="flex-1">
            <FilterIcon class="mt-1" />
          </SelectInput>

          <SearchMultiSelect v-model="filters.geo" parameter="geo" :options="geoOptions" placeholder="Гео" class="flex-1">
            <FilterIcon class="mt-1"/>
          </SearchMultiSelect>

          <SearchMultiSelect v-model="filters.source" parameter="source" :options="sourceOptions" placeholder="Группы источников" class="flex-1">
            <FilterIcon class="mt-1"/>
          </SearchMultiSelect>

          <SelectInput v-model="filters.utm_source" parameter="utm_source" :options="utmSourceOptions" placeholder="UTM Source" class="flex-1">
            <FilterIcon class="mt-1" />
          </SelectInput>

          <SelectInput v-model="filters.locale" parameter="locale" :options="localeOptions" placeholder="Локализация" class="flex-1">
            <FilterIcon class="mt-1" />
          </SelectInput>
        </div>
      </StandardTable>
    </Card>
  </div>

  <DeleteModal
      v-if="isDeleteModalVisible"
      v-model="isDeleteModalVisible"
      :submit="deleteAction"
      :id="deletedId"
  >
    <template #title>Удаление ручного топа</template>
    <template #text>
      <p>Вы действительно хотите удалить ручной топ?</p>
    </template>
  </DeleteModal>

  <ConfirmModal
    v-model="isWarningModalVisible"
    :modal-text="warningModalText"
    @submit="activateAction(warningId)"
    @reject="isWarningModalVisible = false"
  />

  <Teleport to="#hiddenContainer">
    <PlayIcon id="playIcon" class="fill-current transition hover:fill-primary"/>
    <StopIcon id="stopIcon" class="fill-current transition hover:fill-primary"/>
    <PencilWithoutPaperIcon id="pencilIcon" class="fill-current transition hover:fill-primary"/>
    <CopyIcon id="copyIcon" class="fill-current transition hover:fill-primary"/>
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red"/>
  </Teleport>
</template>

<script setup lang="ts">
import StandardTable from "@/shared/Tables/StandardTable.vue";
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive, type Ref, ref, watch} from "vue";
import type {AlphaNumerical, MultiSelectOptions, SelectOption, TableAction, TableHeader} from "@/types/props";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import FilterIcon from "@/icons/FilterIcon.vue";
import TableSearchInput from "@/shared/Inputs/TableSearchInput.vue";
import SearchMultiSelect from "@/shared/Inputs/SearchMultiSelect.vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import CopyIcon from "@/icons/CopyIcon.vue";
import PencilWithoutPaperIcon from "@/icons/PencilWithoutPaperIcon.vue";
import StopIcon from "@/icons/StopIcon.vue";
import {useApi} from "@/stores/api";
import type {ManualTopExistsRequest, ManualTopListRequest} from "@/types/requests";
import arrayToSelectOptions from "@/helpers/arrayToSelectOptions";
import debounce from "@/helpers/debounce";
import {useAlerts} from "@/stores/alert";
import DeleteModal from "@/shared/Modals/DeleteModal.vue";
import PlayIcon from "@/icons/PlayIcon.vue";
import type {ManualTopListItem} from "@/types/responses";
import ConfirmModal from "@/shared/Modals/ConfirmModal.vue";
import moment from "moment/moment";

const router = useRouter()
const route = useRoute()
const { sort, order, page } = route.query

const keyOptions: Ref<SelectOption[]> = ref([])
const sourceOptions: Ref<MultiSelectOptions> = ref([])
const localeOptions: Ref<SelectOption[]> = ref([])
const geoOptions: Ref<MultiSelectOptions> = ref([])
const utmSourceOptions: Ref<SelectOption[]> = ref([])

const filters = reactive({
  searchValue: '',
  key: '',
  source: [] as string[],
  locale: '',
  utm_source: '',
  geo: [] as string[]
})

const isDeleteModalVisible = ref(false)
const deletedId = ref(0)

const isWarningModalVisible = ref(false)
const warningId = ref(0)
const warningModalText = ref('')

const data = ref<Record<string, AlphaNumerical>[]>();

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Описание' },
  { name: 'Ключ топа', sortKey: 'key' },
  { name: 'Гео', sortKey: 'geo' },
  { name: 'Группа источников', sortKey: 'source' },
  { name: 'UTM Source', sortKey: 'utm_source' },
  { name: 'Локализация', sortKey: 'locale' },
  { name: 'Кол-во тизеров', sortKey: 'teaser_count' },
  { name: 'Дата редактирования', sortKey: 'updated_at' }
]

const actions = ref<TableAction[]>();

const api = useApi()
const alerts = useAlerts()

const topMap = ref<Map<number, ManualTopListItem>>(new Map())

getData()

api.products.manualTopOptions()
    .then(response => {
      keyOptions.value = arrayToSelectOptions(response.keys)
      localeOptions.value = arrayToSelectOptions(response.locales)
      sourceOptions.value = response.sources
      geoOptions.value = response.geos
      utmSourceOptions.value = arrayToSelectOptions(response.utm_sources)
    })

function getData() {
  const rows: Record<string, AlphaNumerical>[] = []
  topMap.value.clear()

  const request: ManualTopListRequest = {
    page: page ? Number(page) : 1,
    sort: sort ? String(sort) : '',
    order: order ? String(order): '',
    search: filters.searchValue,
    key: filters.key,
    source: filters.source,
    geo: filters.geo,
    locale: filters.locale,
    utm_source: filters.utm_source
  }

  api.products.manualTops(request)
    .then(response => {
      for(let top of response.manualTops) {
        const updatedAt = new Date(top.updated_at)

        const row: Record<string, AlphaNumerical> = {
          id: top.id,
          description: top.description ?? '-',
          key: top.key,
          geo: top.geo,
          source: top.source,
          utm_source: top.utm_source ?? '-',
          locale: top.locale ?? '-',
          teaser_count: top.manual_top_creatives_count,
          updated_at: moment(updatedAt).format('YYYY-MM-DD<br>HH:mm')
        }

        if (!top.enabled) {
          row.color = 'bg-gray'
        } else {
          const duration = moment.duration({ from: updatedAt, to: new Date()})
          if (duration.asDays() >= 7) {
            row.color = 'bg-danger bg-opacity-20'
          } else if (duration.asDays() >= 3) {
            row.color = 'bg-warning bg-opacity-20'
          }
        }

        rows.push(row)
        topMap.value.set(top.id, top)
      }

      data.value = rows
    })
}

function deleteAction(id: number) {
  isDeleteModalVisible.value = false
  api.products.deleteTop(id)
    .then(res => getData())
    .catch(err => alerts.flash('danger', 'Ошибка при удалении ручного топа'))
}

function activateAction(id: number) {
  isDeleteModalVisible.value = false
  isWarningModalVisible.value = false

  api.products.manualTopStatus(id, { status: true })
      .then(res => {
        if (res.success) {
          alerts.flash('success', 'Ручной топ успешно активирован')
          getData()
        } else {
          alerts.flash('danger', 'Ручной топ не удалось активировать')
        }
      })
      .catch(err => alerts.flash('danger', 'Ошибка при активации ручного топа'))
}

watch(() => route.fullPath, getData)
watch(filters, () => {
  debounce(getData)()
}, { deep: true })

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        if (!topMap.value.has(id)) return
        const top = <ManualTopListItem>topMap.value.get(id)
        const request: ManualTopExistsRequest = {
          geo: top.geo,
          source: top.source
        }

        if (top.locale) request.locale = top.locale
        if (top.utm_source) request.utm_source = top.utm_source

        api.products.manualTopExists(request)
          .then(response => {
            if (response.exists && response.manualTop) {
              warningId.value = id
              warningModalText.value = 'Топ с таким ключом уже существует<br>' +
                  `Включить этот топ вместо id: ${response.manualTop.id} ${response.manualTop.description}?`
              isWarningModalVisible.value = true
            } else {
              activateAction(id)
            }
          })
      },
      visible: (id: number) => {
        if (!topMap.value.has(id)) return false
        const top = <ManualTopListItem>topMap.value.get(id)
        return !Boolean(top.enabled)
      },
      parameter: 'id',
      title: 'Активировать',
      icon: document.getElementById('playIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        api.products.manualTopStatus(id, { status: false })
          .then(response => {
            if (response.success) {
              alerts.flash('success', 'Ручной топ успешно остановлен')
              getData()
            } else {
              alerts.flash('danger', 'Ручной топ не удалось остановить')
            }
          })
          .catch(err => {
            alerts.flash('danger', err.message)
          })
      },
      visible: (id: number) => {
        if (!topMap.value.has(id)) return false
        const top = <ManualTopListItem>topMap.value.get(id)
        return Boolean(top.enabled)
      },
      parameter: 'id',
      title: 'Остановить',
      icon: document.getElementById('stopIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        const route = router.resolve(`/manual-tops/${id}`)
        window.open(route.href, '_blank')
      },
      visible: () => true,
      parameter: 'id',
      title: 'Редактировать топ',
      icon: document.getElementById('pencilIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        const route = router.resolve(`/manual-tops/copy/${id}`)
        window.open(route.href, '_blank')
      },
      visible: () => true,
      parameter: 'id',
      title: 'Копировать топ',
      icon: document.getElementById('copyIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        isDeleteModalVisible.value = true
        deletedId.value = id
      },
      visible: () => true,
      parameter: 'id',
      title: 'Удаление аккаунта',
      icon: document.getElementById('trashIcon')?.outerHTML ?? ''
    }
  ]
})
</script>
