<template>
  <Card>
    <template #title>Персональные данные</template>

    <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="user.name" label="Имя Фамилия" parameter="name" class="w-full sm:w-1/2" disabled>
        <UserInputIcon/>
      </TextInput>

      <TextInput v-model="user.telegram" label="Telegram" parameter="telegram" class="w-full sm:w-1/2" disabled/>
    </div>

    <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="user.email" label="Рабочий email" parameter="email" class="w-full sm:w-1/2" disabled>
        <MailInputIcon/>
      </TextInput>

      <TextInput model-value="password" label="Пароль" parameter="password" class="w-full sm:w-1/2" is-password disabled/>
    </div>

    <div class="mb-5.5">
      <TextInput v-model="role" label="Роль" parameter="role" disabled/>
    </div>

    <div class="flex justify-end gap-4.5">
      <RouterLink
          :to="`/users/${user.id}/edit`"
          v-if="hasEditPermissions()"
      >
        <StandardButton>Редактировать</StandardButton>
      </RouterLink>
    </div>
  </Card>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import Card from "@/shared/Card.vue";
import MailInputIcon from "@/icons/MailInputIcon.vue";
import UserInputIcon from "@/icons/UserInputIcon.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import type {User} from "@/types/entities";
import {ref} from "vue";
import {useUserStore} from "@/stores/user";

const props = defineProps<{
  user: User
  roleName: string
  canEdit: boolean
}>()

const role = ref(props.roleName)
const userStore = useUserStore()

function hasEditPermissions(): boolean {
  if (props.user.id === 1) return userStore.isSuperAdmin()
  if (props.user.role_id === 2) return userStore.isSuperAdmin() || props.user.id === userStore.user?.id
  return props.canEdit
}
</script>
