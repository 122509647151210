import {ref} from "vue";
import domainsClient from "@/api/domainsClient";
import useRequestHandler from "@/composables/api/useRequestHandler";
import routes from "@/api/routes";
import {Method} from "@/enums/Method";
import type {DomainListResponse, DomainOptionsResponse, DomainRotationResponse, MessageResponse} from "@/types/responses";
import type {Domain, DomainRotation} from "@/types/entities";
import type {ListRequest, StoreDomainRequest, StoreDomainRotationRequest} from "@/types/requests";

export default function useDomainsApi() {
    const { handleRequest } = useRequestHandler(domainsClient, 'domain')

    const domains = ref({
        list: (request: ListRequest) => <Promise<DomainListResponse>>handleRequest(routes.domains.domains, Method.GET, {} , request),
        store: (request: StoreDomainRequest) => <Promise<Domain>>handleRequest(routes.domains.domains, Method.POST, request),
        options: () => <Promise<DomainOptionsResponse>>handleRequest(routes.domains.options),
        rotations: () => <Promise<DomainRotationResponse>>handleRequest(routes.domains.rotations),
        storeRotation: (request: StoreDomainRotationRequest) => <Promise<DomainRotation>>handleRequest(routes.domains.rotations, Method.POST, request),
        nextRotation: (id: number) => <Promise<MessageResponse>>handleRequest(`${routes.domains.next}/${id}`, Method.POST)
    })

    return {
        domains
    }
}
