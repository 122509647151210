<template>
  <Card no-padding v-if="rows.length">
    <template #header><h3 class="font-bold text-2xl text-black">Тизеры</h3></template>

    <StandardTable
      :headers="headers"
      :rows="rows"
      :column-order="['place', 'creative_id', 'image', 'title', 'ctr', 'cpm', 'cpc']"
      :per-page="0"
      align="top"
    />
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import type {TableHeader, TableRow} from "@/types/props";
import {ref, watchEffect} from "vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import {useRotationEmulator} from "@/stores/rotationEmulator";
import {storeToRefs} from "pinia";

const headers: TableHeader[] = [
  { name: 'Место' },
  { name: 'ID' },
  { name: 'Изображение' },
  { name: 'Заголовок' },
  { name: 'CTR' },
  { name: 'CPC' },
  { name: 'CPM' }
]

const rotationEmulator = useRotationEmulator()

const {predictions} = storeToRefs(rotationEmulator)

const rows = ref<TableRow[]>([])

watchEffect(() => {
  rows.value = []

  if (predictions.value.length) {
    for (const prediction of predictions.value) {
      rows.value.push({
        place: prediction.place,
        creative_id: prediction.creative_id,
        image: `<img src="${prediction.image}" alt="${prediction.title}" class="w-[170px]">`,
        title: prediction.title,
        ctr: `${prediction.calculations.CTR}%`,
        cpc: `${prediction.calculations.CPC}₽`,
        cpm: `${prediction.calculations.CPM}₽`
      })
    }
  }
})

</script>
