import {defineStore} from "pinia";
import {useApi} from "@/stores/api";
import {ref} from "vue";
import type {Role} from "@/types/entities";
import type {SelectOption} from "@/types/props";

export const useRoleStore = defineStore('role', () => {
    const roles = ref<Role[]>([])
    const api = useApi()

    async function loadRoles(): Promise<boolean> {
        if (roles.value && roles.value.length) return true

        return api.user.roles()
            .then(response => {
                roles.value = response.roles
                return true
            })
            .catch(() => false)
    }

    function setLoadedRoles(loadedRoles: Role[]): void {
        roles.value = loadedRoles
    }

    function rolesToSelectOptions(): SelectOption[] {
        const options = []

        for (const role of roles.value) {
            options.push({
                key: role.id,
                value: role.name
            })
        }

        return options
    }

    return {
        roles,
        loadRoles,
        rolesToSelectOptions,
        setLoadedRoles
    }
})
