<template>
  <span @mouseleave="showTooltip = false" @mouseover="showTooltip = true"
    class="flex max-w-[40%] items-center justify-center rounded border-[.5px] border-stroke bg-gray py-1.5 px-2.5 text-sm font-medium dark:border-strokedark dark:bg-white/30">
    <span class="flex max-w-[90%] truncate text-sm font-medium">
      {{ item }}
    </span>
    <span @click="removeItem" class="cursor-pointer pl-2">
      <TimesIcon class="fill-current hover:fill-danger" height="8" width="8" />
    </span>
    <div
      class="absolute bottom-13 z-9999999 whitespace-normal break-words rounded-lg bg-gray py-1.5 px-3 font-sans text-sm font-normal focus:outline-none"
      :class="showTooltip && item.toString().length > 20 ? 'visible' : 'invisible'">
      {{ item }}
    </div>
  </span>
</template>

<script setup lang="ts">
import TimesIcon from "@/icons/TimesIcon.vue";
import type { AlphaNumerical } from "@/types/props";
import { ref, type Ref } from "vue";

const showTooltip: Ref<boolean> = ref(false);

const props = defineProps<{
  item: AlphaNumerical
}>()


const emit = defineEmits(['remove:item'])

function removeItem() {
  emit('remove:item', props.item)
}

</script>
