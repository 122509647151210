<template>
  <div class="relative w-full">
    <label v-if="label" class="mb-3 block font-medium text-black dark:text-white">
      {{ label }}
    </label>
    <div
      @click="openDropdownIfNotDisabled"
      :class="{ 'border-primary': isDropdownOpened, 'border-stroke': !isDropdownOpened, 'bg-gray': disabled }"
      class="relative z-20 w-full rounded border p-3 pl-4 pr-8 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input min-h-[50px] cursor-pointer font-inter"
    >
      <div class="font-medium text-black text-[15px]">
        <span v-if="selectedInput">{{ optionValue(selectedInput) }}</span>
        <span v-else-if="placeholder" class="text-bodydark2 font-inter font-medium">{{ placeholder }}</span>
        <span class="absolute top-1/2 right-4 z-10 -translate-y-1/2"><CaretInputIcon class="fill-[#999999]"/></span>
      </div>
    </div>

    <div
        v-click-outside="closeDropdown"
        :class="{ flex: isDropdownOpened, 'hidden': !isDropdownOpened }"
        class="p-2 absolute w-full flex-col border mt-0.5 bg-white z-99999 rounded border-stroke font-inter gap-1 max-h-[152px] overflow-y-auto text-black text-[15px] shadow-default"
    >
      <p
          v-for="option in options"
          @click="selectOption(option.key)"
          class="hover:bg-gray py-2.5 px-2 rounded cursor-pointer"
          :class="{ 'bg-gray': selectedInput === option.key }"
      >
        {{ option.value }}
      </p>
    </div>
    <p v-if="errors && errors.length" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import type {Ref} from "vue";
import type {AlphaNumerical, SelectOption} from "@/types/props";
import useDropdownToggle from "@/composables/useDropdownToggle";
import vClickOutside from "@/directives/clickOutside";
import CaretInputIcon from "@/icons/CaretInputIcon.vue";

const props = defineProps<{
  parameter: string
  options: SelectOption[]
  label?: string
  disabled?: string
  errors?: string[]
  placeholder?: string
}>()

const selectedInput: Ref<AlphaNumerical | null> = defineModel({ required: true })

const {
  isDropdownOpened,
  openDropdown,
  closeDropdown
} = useDropdownToggle(props.parameter)

function openDropdownIfNotDisabled() {
  if (props.disabled) return
  openDropdown()
}

function optionValue(item: AlphaNumerical): AlphaNumerical {
  const option = props.options.find(option => option.key == item)
  if (option) return option.value
  return item
}

function selectOption(key: AlphaNumerical): void {
  selectedInput.value = selectedInput.value === key ? null : key
  closeDropdown()
}

</script>
