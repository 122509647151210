<template>
  <div>
    <label for="streams" class="mb-3 block text-sm font-medium text-black dark:text-white">Настройка потока</label>
    <div class="flex flex-wrap justify-between">
      <p class="w-[5%]">ID</p>
      <p class="w-[80%]">Ссылка</p>
      <p class="w-[10%]"> Значение</p>
      <div class="w-[5%]"></div>


      <template v-for="(link, index) in streamLinks">
        <p class="w-[5%] mt-2 flex items-center h-[50px]">{{ link.id }}</p>
        <div class="w-[80%] mt-2 pr-4">
          <SearchSelect v-model="link.id" :parameter="`link-id-${index}`" :options="filterOptions(link.id)"
            placeholder="Выберите ссылку" />
          <a class="mt-2 text-black hover:text-primary hover:underline inline-block text-sm" :href="links[link.id]">{{
            links[link.id] }}</a>
        </div>
        <TextInput :parameter="`link-value-${index}`" v-model="link.probability" class="w-[10%] mt-2" />
        <div class="w-[5%] flex items-center justify-end mt-2 h-[50px]">
          <TimesIcon @click="deleteLink(index)" class="fill-black cursor-pointer hover:fill-primary" width="20"
            height="20" />
        </div>
      </template>
    </div>

    <div class="flex justify-end mt-2">
      <StandardButton :disabled="disabled" class="justify-self-end" :class="{ '!cursor-not-allowed': disabled }"
        @click="addLink">+ ссылку</StandardButton>
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>

  </div>

</template>

<script setup lang="ts">

import type { ModelRef } from "vue";
import type { MultiSelectOptions, StreamLink } from "@/types/props";
import TimesIcon from "@/icons/TimesIcon.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import SearchSelect from "@/shared/Inputs/SearchSelect.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";

const props = defineProps<{
  links: Record<number, string>
  linkOptions: MultiSelectOptions
  disabled?: boolean
  errors?: string[]
}>()

function filterOptions(selected: number) {
  const options: MultiSelectOptions = {}
  Object.keys(props.linkOptions).forEach((key) => {
    if (!streamLinks.value.some((item) => item.id.toString() === key) || selected.toString() === key) {
      options[key] = props.linkOptions[Number(key)]
    }
  })
  return options
}

const streamLinks: ModelRef<StreamLink[]> = defineModel({ required: true })

function addLink() {
  streamLinks.value.push({
    id: 0,
    probability: 0
  })
}

function deleteLink(index: number) {
  streamLinks.value.splice(index, 1)
}

</script>
