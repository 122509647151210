<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ url: '/roles', prev: 'Роли', current: data?.role.name || '' }">
        Карточка роли
      </PageTitle>
      <div class="grid grid-cols-9 gap-8">
        <div class="col-span-9 xl:col-span-6">
          <RoleUsersTable
              v-if="data"
              :role-name="data.role.name"
              :role-id="Number(route.params.id)"
              :can-edit="data.can_edit"
          />
        </div>

        <div class="col-span-9 xl:col-span-3 flex flex-col gap-4">
          <RoleShortCard
              v-if="data"
              :id="data.role.id"
              :role-name="data.role.name"
              :parent="data.role.parent || null"
              :can-edit="data.can_edit"
              @edit:trigger="isEditModalVisible = true"
          />

          <RolePermissionsCard
              v-if="data"
              :permissions="data.permissions"
              :role-name="data.role.name"
              :parent="data.role.parent"
          />
        </div>
      </div>
    </div>

    <RoleEditModal
      v-model="isEditModalVisible"
      :role="data || null"
      :keep-form-on-close="true"
      @refresh:table="getData"
    />
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue"; 
import PageTitle from "@/shared/PageTitle.vue";
import RoleUsersTable from "@/shared/Tables/RoleUsersTable.vue";
import RoleShortCard from "@/shared/Cards/RoleShortCard.vue";
import RolePermissionsCard from "@/shared/Cards/RolePermissionsCard.vue";
import {useRoute, useRouter} from "vue-router";
import { useApi } from "@/stores/api";
import {onBeforeMount, onBeforeUnmount, ref} from "vue";
import type { getRoleResponse } from "@/types/responses";
import RoleEditModal from "@/shared/Modals/RoleEditModal.vue";
import {useUserStore} from "@/stores/user";
import {useRoleForm} from "@/stores/roleForm";
import {UserServiceForbiddenError} from "@/errors/UserServiceForbiddenError";

const userStore = useUserStore()
onBeforeMount(() => userStore.guardMany([
  {section: 'roles', permission: 'read'},
  {section: 'roles', permission: 'personnel_read'}
]))

const roleForm = useRoleForm()
roleForm.loadSections()
onBeforeUnmount(roleForm.clear)


const route = useRoute()
const router = useRouter()
const api = useApi()
const data = ref<getRoleResponse>()
getData()

function getData() {
  api.user.role(Number(route.params.id))
    .then(response => {
      data.value = response
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}

const isEditModalVisible = ref(false)

</script>
