<template>
  <div class="data-table-common data-table-one data-table-small-p">
    <div class="datatable-wrapper datatable-loading no-footer fixed-columns">
      <div class="datatable-top !p-4">
        <StandardButton @click="submitNext" :class="{ 'cursor-not-allowed !bg-body hover:bg-body': !rotation.newDomainsCount }">
          Следующий
        </StandardButton>
        <div>
          <h4 class="font-bold text-black text-lg">{{ rotation.name }}</h4>
          <span :class="{ 'text-red': !rotation.newDomainsCount }" class="text-sm">Новых: {{ rotation.newDomainsCount }}</span>
        </div>
      </div>

      <div class="datatable-container overflow-y-auto lg:h-[263px] max-h-[263px]">
        <table class="table w-full table-auto datatable-table">
          <tbody>
          <tr v-for="domain in rotation.domains">
            <td>{{ domain.name }}</td>
            <td class="text-left">{{ domain.status.toUpperCase() }}</td>
            <td class="text-right">{{ domain.created_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import type {DomainRotationProps} from "@/types/props";

const props = defineProps<{
  rotation: DomainRotationProps
}>()

const emit = defineEmits(['next:submit'])

function submitNext() {
  if (!props.rotation.newDomainsCount) return
  emit('next:submit', {
    id: props.rotation.id,
    name: props.rotation.name
  })
}

</script>
