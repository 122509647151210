import {reactive, toRaw} from "vue";
import type { TemplateForm } from "@/types/props";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import type { Source, Template } from "@/types/entities";
import Validator from "validatorjs";

export default function useTemplateForm() {

    const form = reactive<TemplateForm>({
        name: '',
        news_sources: [],
        utm_source: '',
        utm_campaign: '',
        utm_medium: '',
        utm_term: '',
        utm_content: '',
        link_tale: '',
        title_min: 0,
        title_max: 0,
        desc_min: 0,
        desc_max: 0,
        img_width: 0,
        img_height: 0,
        parse_fix: 0,
        parse_expenses: false,
        auto_stop_delete: false,
        sync: false,
        use_blacklist: false,
        is_visible: false,
        clean_flag: false,
        upload_time_flag: false,
        acceptable_ratings: false,
    })

    const errors = reactive<Record<string, string[]>>({
        name: [],
        utm_source: [],
        utm_campaign: [],
        utm_medium: [],
        utm_term: [],
        utm_content: [],
        link_tale: [],
        title_min: [],
        title_max: [],
        desc_min: [],
        desc_max: [],
        img_width: [],
        img_height: [],
        news_sources: []
    })

    function validateForm(): boolean {
        const validator = new Validator(toRaw(form), {
            name: 'required',
            utm_source: 'required',
            utm_campaign: 'required',
            utm_medium: 'required',
            utm_term: 'required',
            utm_content: 'required',
            link_tale: 'required',
            title_max: 'between:1,65535|gte:title_min',
            title_min: 'between:1,65535|lte:title_max',
            desc_max: 'between:1,65535|gte:desc_min',
            desc_min: 'between:1,65535|lte:desc_max',
            img_width: 'between:1,65535',
            img_height: 'between:1,65535',
            parse_fix: 'max:99999'
        })
        const passes = Boolean(validator.passes())

        if (!passes) populateValidationErros(validator.errors.all())

        return passes
    }


    function clearErrors() {
        errors.name = []
        errors.news_sources = []
        errors.utm_source = []
        errors.utm_campaign = []
        errors.utm_medium = []
        errors.utm_term = []
        errors.utm_content = []
        errors.link_tale = []
        errors.title_min = []
        errors.title_max = []
        errors.desc_min = []
        errors.desc_max = []
        errors.img_width = []
        errors.img_height = []
        errors.parse_fix = []
    }

    function clear() {
        form.name = ''
        form.news_sources = []
        form.utm_source = ''
        form.utm_campaign = ''
        form.utm_medium = ''
        form.utm_term = ''
        form.utm_content = ''
        form.link_tale = ''
        form.title_min = 0
        form.title_max = 0
        form.desc_min = 0
        form.desc_max = 0
        form.img_width = 0
        form.img_height = 0
        form.parse_fix = 0
        form.parse_expenses = false
        form.auto_stop_delete = false
        form.sync = false
        form.use_blacklist = false
        form.is_visible = false
        form.clean_flag = false
        form.upload_time_flag = false
        form.acceptable_ratings = false
        clearErrors()
    }

    function prepareToRequest() {
        form.title_min = Number(form.title_min)
        form.title_max = Number(form.title_max)
        form.desc_min = Number(form.desc_min)
        form.desc_max = Number(form.desc_max)
        form.img_width = Number(form.img_width)
        form.img_height = Number(form.img_height)
        form.parse_fix = Number(form.parse_fix)
    }

    function set(templateData: Template) {
        form.name = templateData.name
        form.news_sources = templateData.news_sources.map((source:Source)=>source.id)
        form.utm_source = templateData.utm_source
        form.utm_campaign = templateData.utm_campaign
        form.utm_medium = templateData.utm_medium
        form.utm_term = templateData.utm_term
        form.utm_content = templateData.utm_content
        form.link_tale = templateData.link_tale
        form.title_min = templateData.title_min
        form.title_max = templateData.title_max
        form.desc_min = templateData.desc_min
        form.desc_max = templateData.desc_max
        form.img_width = templateData.img_width
        form.img_height = templateData.img_height
        form.parse_fix = templateData.parse_fix
        form.parse_expenses = templateData.parse_expenses
        form.auto_stop_delete = templateData.auto_stop_delete
        form.sync = templateData.sync
        form.use_blacklist = templateData.use_blacklist
        form.is_visible = templateData.is_visible
        form.clean_flag = templateData.clean_flag
        form.upload_time_flag = templateData.upload_time_flag
        form.acceptable_ratings = templateData.acceptable_ratings
    }

    function populateErrors(err: UserServiceValidationError): void {
        populateValidationErros(err.validationErrors)
    }

    function populateValidationErros(err: Record<string, string[]>): void {
        for (const [field, validationErrors] of Object.entries(err)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    return {
        form,
        errors,
        validateForm,
        clearErrors,
        set,
        clear,
        populateErrors,
        prepareToRequest
    }
}
