<template>
  <Card>
    <template #title>Персональные данные</template>

    <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="form.name" label="Имя Фамилия" placeholder="Имя Фамилия" parameter="name" class="w-full sm:w-1/2" :errors="errors.name">
        <UserInputIcon/>
      </TextInput>

      <TextInput v-model="form.telegram" label="Telegram" placeholder="Telegram" parameter="telegram" class="w-full sm:w-1/2" :errors="errors.telegram"/>
    </div>

    <div class="mb-6.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="form.email" label="Рабочий email" placeholder="Рабочий email" parameter="email" class="w-full sm:w-1/2" :errors="errors.email">
        <MailInputIcon/>
      </TextInput>

      <TextInput
          v-model="form.password"
          label="Пароль"
          placeholder="Новый пароль"
          parameter="new-password"
          class="w-full sm:w-1/2"
          is-password eye-icon
          :errors="errors.password"
      />
    </div>

    <div class="mb-14">
      <SelectInput
          v-model="form.role_id"
          :options="roleOptions"
          parameter="role_id"
          placeholder="Выберите роль"
          label="Должность (роль)"
          :errors="errors.role_id"
      />
    </div>

    <div class="flex justify-end gap-4.5">
      <RouterLink to="/users">
        <ClearButton>Отмена</ClearButton>
      </RouterLink>

      <StandardButton @click="submit">Сохранить</StandardButton>
    </div>
  </Card>
</template>

<script setup lang="ts">
import UserInputIcon from "@/icons/UserInputIcon.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import MailInputIcon from "@/icons/MailInputIcon.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {useRoleStore} from "@/stores/role";
import useUserForm from "@/composables/useUserForm";
import {type Ref, ref, toRaw} from "vue";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {useRouter} from "vue-router";
import {useUserStore} from "@/stores/user";
import type {SelectOption} from "@/types/props";

const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()
const roleStore = useRoleStore()
const roleOptions: Ref<SelectOption[]> = ref([{key: 1, value: 'Сотрудник'}])
roleStore.loadRoles()
  .then(() => {
    // roles without roles-edit permission can only set default role
    let roles = roleStore.rolesToSelectOptions()
    if (!userStore.can('roles', 'edit')) {
      roles = roles.filter(role => role.key === 1)
    }
    // only default admin users can see admin role in select
    if(!userStore.isSuperAdmin()) {
      roles = roles.filter(role => role.key !== 2)
    }

    if (roles.length) roleOptions.value = roles
  })


const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors
} = useUserForm()

function submit() {
  clearErrors()
  if (!validateForm()) return

  api.user.create(toRaw(form))
      .then(response => {
        alertStore.flash('success', 'Пользователь успешно создан')
        router.push('/users')
      })
      .catch(err => {
        if (err instanceof UserServiceValidationError) {
          populateErrors(err)
        }
      })
}

</script>
