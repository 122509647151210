<template>
  <Card class="w-full sm:w-3/4">
    <template #title>Базовые настройки</template>

    <div class="mb-5.5 flex flex-col gap-5.5">
      <TextInput v-model="form.href" label="Ссылка перехода" placeholder="Ссылка перехода" parameter="href"
        class="w-full" :errors="errors.href" />

      <GroupedSelectInput v-model="form.preland_id" :options="prelandOptions" parameter="preland"
        placeholder="Без прокладки" label="Прокладка" :errors="errors.preland_id" />

      <TextInput v-model="form.tag" label="Тег" placeholder="Тег" parameter="tag" class="w-full" :errors="errors.tag" />

      <TextInput v-model="form.offer" label="Оффер" placeholder="Оффер" parameter="offer" class="w-full"
        :errors="errors.offer" />

      <TextInput v-model="form.label" label="Имя" placeholder="Имя" parameter="label" class="w-full"
        :errors="errors.label" />

      <SelectInput v-model="form.affiliate_id" :options="affiliateOptions" parameter="affiliate"
        placeholder="Выберите из списка" label="ПП" :errors="errors.affiliate_id" />

      <CheckboxAccordionInput v-model="form.geo" parameter="geos" :options="geoOptions" label="Гео"
        placeholder="Выберите из списка" :errors="errors.geo" class="w-full" />

    </div>

    <div class="flex mt-10 gap-4.5">
      <RouterLink to="/links">
        <ClearButton>Отмена</ClearButton>
      </RouterLink>

      <StandardButton @click="submit">Сохранить</StandardButton>
    </div>
  </Card>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import useLinkForm from "@/composables/useLinkForm";
import SelectInput from "../Inputs/SelectInput.vue";
import CheckboxAccordionInput from "../Inputs/CheckboxAccordionInput.vue";
import GroupedSelectInput from "@/shared/Inputs/GroupedSelectInput.vue"

const {
  form,
  errors,
  geoOptions,
  affiliateOptions,
  prelandOptions,
  getOptions,
  validateForm,
  clearErrors,
  set,
  populateErrors,
  prepareToRequest
} = useLinkForm()


const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()



getOptions()

function submit() {
  clearErrors()
  if (!validateForm() || !userStore.user) return

  api.products.linkCreate(prepareToRequest())
    .then(response => {
      alertStore.flash('success', 'Ссылка успешно создана')
      router.push('/links')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })

}

</script>
