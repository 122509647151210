import {defineStore} from "pinia";
import {reactive} from "vue";
import type {AlertProps} from "@/types/props";

type AlertType = 'success' | 'danger'
export const useAlerts = defineStore('alert', () => {
    const alerts = reactive<Record<AlertType, AlertProps | null>>({
        success: null,
        danger: null
    })

    const storageKeys = {
        success: 'alertSuccess',
        danger: 'alertDanger'
    }

    for (const [alertType, storageKey] of <[AlertType, string][]>Object.entries(storageKeys)) {
        const storageItem = localStorage.getItem(storageKey)

        if (storageItem) {
            const alert = JSON.parse(storageItem)
            flash(alertType, alert.title, alert.message ?? '')
            localStorage.removeItem(storageKey)
        }
    }

    function flash(type: AlertType, title: string, message?: string): void {
        const alert: AlertProps = { title: title }
        if (message) alert.message = message
        alerts[type] = alert

        setTimeout(() => alerts[type] = null, 10000)
    }

    function flashOnReload(type: AlertType, title: string, message?: string): void {
        const alert: AlertProps = { title: title }
        if (message) alert.message = message
        localStorage.setItem(storageKeys[type], JSON.stringify(alert))
    }

    return {
        alerts,
        flash,
        flashOnReload
    }
})
