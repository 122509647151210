<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_730_1276)">
      <path
        d="M17.3258 3.65684C16.4258 2.70059 15.4696 1.74434 14.5133 0.816211C14.3164 0.619336 14.0914 0.506836 13.8383 0.506836C13.5852 0.506836 13.3321 0.591211 13.1633 0.788086L2.56018 11.3068C2.39143 11.4756 2.27893 11.6725 2.19456 11.8693L0.647681 16.5943C0.563306 16.8193 0.619556 17.0443 0.732056 17.2131C0.872681 17.3818 1.06956 17.4943 1.32268 17.4943H1.43518L6.24456 15.8912C6.46956 15.8068 6.66643 15.6943 6.80706 15.5256L17.3539 5.00684C17.5227 4.83809 17.6352 4.58496 17.6352 4.33184C17.6352 4.07871 17.5227 3.85371 17.3258 3.65684ZM5.90706 14.6537C5.87893 14.6818 5.85081 14.6818 5.82268 14.71L2.19456 15.9193L3.40393 12.2912C3.40393 12.2631 3.43206 12.235 3.46018 12.2068L11.1946 4.50059L13.6414 6.94746L5.90706 14.6537ZM14.5133 6.04746L12.0664 3.60059L13.7821 1.88496C14.5977 2.67246 15.4133 3.51621 16.2008 4.33184L14.5133 6.04746Z"
        fill="" />
    </g>
    <defs>
      <clipPath id="clip0_730_1276">
        <rect width="18" height="18" fill="white" transform="translate(0.113281 0.000488281)" />
      </clipPath>
    </defs>
  </svg>
</template>
