<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ url: '/accounts', prev: 'Аккаунты в источниках', current: 'Редактирование аккаунта в источниках' }">
        Редактирование аккаунта в источниках
      </PageTitle>

      <AccountEditCard v-if="data" :account="data" />
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import AccountEditCard from "@/shared/Cards/AccountEditCard.vue"


import { useRoute, useRouter } from "vue-router";
import { useApi } from "@/stores/api";
import { ref } from "vue";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";
import type { AccountData,  } from "@/types/props";

const route = useRoute()
const router = useRouter()
const api = useApi()

const data = ref<AccountData>()
getData()

function getData() {
  api.sources.account(Number(route.params.id))
    .then(response => {
      data.value = response.account
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}

</script>
