import useRequestHandler from "@/composables/api/useRequestHandler";
import productsClient from "@/api/productsClient";
import {ref} from "vue";
import type {
    getCreativeOrderedResponse,
    getLinkResponse,
    getLinksOptionsResponse,
    getLinksResponse,
    getManualTopExistsResponse,
    getManualTopOptionsResponse,
    getManualTopResponse,
    getManualTopsResponse,
    getProductCreateOptionsResponse,
    getProductResponse,
    getProductsResponse,
    getProductsTableOptionsResponse,
    getStreamResponse,
    getStreamsListResponse, getStreamsOptions,
    getStreamsResponse,
    getThemeResponse,
    getThemesResponse
} from "@/types/responses";
import routes from "@/api/routes";
import type {
    CreateLinkRequest, CreateThemeRequest,
    LinkListRequest,
    ManualTopExistsRequest,
    ManualTopListRequest, ManualTopStatusRequest, ManualTopStoreRequest,
    ProductTeaserEditRequest,
    ProductTeaserRequest,
    ProductTeasersRequest, StreamCreateRequest,
    StreamListRequest, StreamsListRequest, ThemesListRequest
} from "@/types/requests";
import {Method} from "@/enums/Method";

export default function useProductsApi() {
    const { handleRequest } = useRequestHandler(productsClient, 'products')

    const products = ref({
        product: (id: number) => <Promise<getProductResponse>>handleRequest(`${routes.products.creatives}/${id}`),
        products: (request: ProductTeasersRequest) => <Promise<getProductsResponse>>handleRequest(routes.products.creatives, Method.GET, {}, request),
        productCreate: (request: ProductTeaserRequest) => handleRequest(routes.products.creatives, Method.POST, request),
        productUpdate: (id: number, request: ProductTeaserEditRequest) => handleRequest(`${routes.products.creatives}/${id}`, Method.PUT, request),
        productCopy: (id: number, request: ProductTeaserRequest) => handleRequest(`copy/${routes.products.creatives}/${id}`, Method.POST, request),
        productDelete: (id: number) => handleRequest(`${routes.products.creatives}/${id}`, Method.DELETE),
        productActivate: (id: number) => handleRequest(`${routes.products.activate}/${id}`, Method.PUT),
        productDeactivate: (id: number) => handleRequest(`${routes.products.deactivate}/${id}`, Method.PUT),
        productCreateOptions: () => <Promise<getProductCreateOptionsResponse>>handleRequest(routes.products.productCreateOptions, Method.GET),
        productsTableOptions: () => <Promise<getProductsTableOptionsResponse>>handleRequest(routes.products.productTableOptions, Method.GET),

        manualTops: (request: ManualTopListRequest) => <Promise<getManualTopsResponse>>handleRequest(routes.products.manualTops, Method.GET, {}, request),
        manualTop: (id: number) => <Promise<getManualTopResponse>>handleRequest(`${routes.products.manualTops}/${id}`),
        manualTopOptions: () => <Promise<getManualTopOptionsResponse>>handleRequest(`${routes.products.manualTops}/options`),
        manualTopCount: (request: { source: string }) => <Promise<{ count: number }>>handleRequest(`${routes.products.manualTops}/count`, Method.GET, {}, request),
        manualTopCreate: (request: ManualTopStoreRequest) => <Promise<getManualTopResponse>>handleRequest(routes.products.manualTops, Method.POST, request),
        manualTopUpdate: (id: number, request: ManualTopStoreRequest) => <Promise<getManualTopResponse>>handleRequest(`${routes.products.manualTops}/${id}`, Method.PUT, request),
        manualTopExists: (request: ManualTopExistsRequest) => <Promise<getManualTopExistsResponse>>handleRequest(`${routes.products.manualTops}/exists`, Method.GET, {}, request),
        manualTopStatus: (id: number, request: ManualTopStatusRequest) => <Promise<{ success: boolean }>>handleRequest(`${routes.products.manualTops}/${id}/status`, Method.PATCH, request),
        deleteTop: (id: number) => handleRequest(`${routes.products.manualTops}/${id}`, Method.DELETE),

        creativesOrdered: (ids: number[]) => <Promise<getCreativeOrderedResponse>>handleRequest(`${routes.products.creatives}/ordered`, Method.GET, {}, { id: ids }),

        link: (id: number) => <Promise<getLinkResponse>>handleRequest(`${routes.products.link}/${id}`),
        links: (request: LinkListRequest) => <Promise<getLinksResponse>>handleRequest(`${routes.products.link}/list`, Method.GET, {}, request),
        linkCreate: (request: CreateLinkRequest) => handleRequest(routes.products.link, Method.POST, request),
        linkUpdate: (id: number, request: CreateLinkRequest) => handleRequest(`${routes.products.link}/${id}`, Method.PUT, request),
        linkDelete: (id: number) => handleRequest(`${routes.products.link}/${id}`, Method.DELETE),
        linksOptions: () => <Promise<getLinksOptionsResponse>>handleRequest(`${routes.products.link}/list-options`, Method.GET),

        theme: (id: number) => <Promise<getThemeResponse>>handleRequest(`${routes.products.themes}/${id}`),
        themes: (request: ThemesListRequest) => <Promise<getThemesResponse>>handleRequest(routes.products.themes, Method.GET, {}, request),
        themeCreate: (request:CreateThemeRequest) => handleRequest(routes.products.themes, Method.POST, request),
        themeUpdate: (id: number, request: CreateThemeRequest) => handleRequest(`${routes.products.themes}/${id}`, Method.PUT, request),
        themeDelete: (id: number) => handleRequest(`${routes.products.themes}/${id}`, Method.DELETE),

        stream: (id: number) => <Promise<getStreamResponse>>handleRequest(`${routes.products.streams}/${id}`),
        streams: (request: StreamListRequest) => <Promise<getStreamsListResponse>>handleRequest(routes.products.streamsList, Method.GET, {}, request),

        streamsList: (request: StreamsListRequest) => <Promise<getStreamsResponse>>handleRequest(routes.products.streams, Method.GET, {}, request),
        streamCreate: (request: StreamCreateRequest) => handleRequest(routes.products.streams, Method.POST, request),
        streamUpdate: (id: number, request: StreamCreateRequest) => handleRequest(`${routes.products.streams}/${id}`, Method.PUT, request),
        streamDelete: (id: number) => handleRequest(`${routes.products.streams}/${id}`, Method.DELETE),
        streamsOptions: () => <Promise<getStreamsOptions>>handleRequest(`${routes.products.streams}/options`)
    })

    return {
        products
    }
}
