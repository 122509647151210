<template>
  <Card>
    <div class="flex gap-5.5 flex-wrap">
      <!-- First Row -->
      <SelectInput v-model="form.ssp" parameter="ssp" :options="options.ssps" placeholder="" label="SSP" class="lg:w-[30%] w-full" :errors="errors.ssp"/>
      <SelectInput
          v-model="form.site_id"
          parameter="site_id"
          :options="options.siteIds"
          :disabled="!form.ssp"
          :placeholder="!form.ssp ? 'Сначала выберите SSP': ''"
          label="id площадки"
          class="lg:w-[30%] w-full"
          :errors="errors.site_id"
      />
      <SearchMultiSelect
          v-model="form.block_ids"
          parameter="block_ids"
          :options="options.blockIds"
          :disabled="!form.site_id"
          :placeholder="!form.site_id ? 'Сначала выберите id площадки' : ''"
          label="id блока"
          class="lg:w-[30%] w-full"
          :errors="errors.block_ids"
      />

      <!-- Second Row -->
      <SelectInput
          v-model="form.news_category"
          parameter="news_cat"
          :options="options.newsCategories"
          label="Новостная категория"
          placeholder=""
          class="lg:w-[30%] w-full"
          :errors="errors.news_category"
      />
      <GeoSelect
          v-model="form.country"
          parameter="geo"
          :options="options.geos"
          placeholder=""
          label="Гео"
          class="lg:w-[30%] w-full"
          :errors="errors.country"
      />
      <SelectInput
          v-model="form.city"
          parameter="city"
          :options="options.cities"
          :disabled="!form.country"
          :placeholder="!form.country ? 'Сначала выберите гео': ''"
          label="Город"
          class="lg:w-[30%] w-full"
          :errors="errors.city"
      />

      <!-- Third Row -->
      <SelectInput
          v-model="form.device"
          parameter="device"
          :options="options.devices"
          placeholder=""
          label="Платформа"
          class="lg:w-[30%] w-full"
          :errors="errors.device"
      />
      <SelectInput
          v-model="form.browser"
          parameter="browser"
          :options="options.browsers"
          placeholder=""
          label="Браузер"
          class="lg:w-[30%] w-full"
          :errors="errors.browsers"
      />
      <SelectInput
          v-model="form.os"
          parameter="os"
          :options="options.os"
          placeholder=""
          label="Система"
          class="lg:w-[30%] w-full"
          :errors="errors.os"
      />

      <!-- Fourth Row -->
      <SelectInput
          v-model="form.us"
          parameter="utm_source"
          :options="options.utmSources"
          placeholder=""
          label="Utm Source"
          class="lg:w-[30%] w-full"
          :errors="errors.us"
      />
      <SelectInput
          v-model="form.ucm"
          parameter="utm_campaign"
          :options="options.utmCampaigns"
          placeholder=""
          label="Utm Campaign"
          class="lg:w-[30%] w-full"
          :errors="errors.ucm"
      />
      <SelectInput
          v-model="form.um"
          parameter="utm_medium"
          :options="options.utmMediums"
          placeholder=""
          label="Utm Medium"
          class="lg:w-[30%] w-full"
          :errors="errors.um"
      />

      <!-- Fifth Row -->
      <SelectInput
          v-model="form.uct"
          parameter="utm_content"
          :options="options.utmContents"
          placeholder=""
          label="Utm Content"
          class="lg:w-[30%] w-full"
          :errors="errors.uct"
      />
      <SelectInput
          v-model="form.ut"
          parameter="utm_term"
          :options="options.utmTerms"
          placeholder=""
          label="Utm Term"
          class="lg:w-[30%] w-full"
          :errors="errors.ut"
      />

    </div>
    <StandardButton @click="rotationEmulator.submit" class="mt-10 gap-3">Готово</StandardButton>
  </Card>
</template>

<script setup lang="ts">
import SearchMultiSelect from "@/shared/Inputs/SearchMultiSelect.vue";
import GeoSelect from "@/shared/Inputs/GeoSelect.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import Card from "@/shared/Card.vue";
import {useRotationEmulator} from "@/stores/rotationEmulator";
import {storeToRefs} from "pinia";


const rotationEmulator = useRotationEmulator()

const {
  form,
  errors,
  options
} = storeToRefs(rotationEmulator)
</script>
