import './assets/main.css'
import * as Sentry from "@sentry/vue";

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import Validator from "validatorjs";
import validatorErrorMessages from "@/dictionaries/validatorErrorMessages";
Validator.setMessages('ru', validatorErrorMessages)
Validator.useLang('ru')

Validator.register('gte', function (this: any, value, requirement, attribute) {
    return value >= this.validator.input[requirement]
}, 'Поле :attribute должно быть больше либо равно :gte')
Validator.register('gt', function (this: any, value, requirement, attribute) {
    return value > this.validator.input[requirement]
}, 'Поле :attribute должно быть больше :gt')
Validator.register('lte', function (this: any, value, requirement, attribute) {
    return value <= this.validator.input[requirement]
}, 'Поле :attribute должно быть меньше либо равно :lte')
Validator.register('lt', function (this: any, value, requirement, attribute) {
    return value < this.validator.input[requirement]
}, 'Поле :attribute должно быть меньше :lt')
Validator.register('nnv', function (this: any, value, requirement, attribute) {
    return this.validator.input[attribute].length === 0 || !this.validator.input[attribute].some((item: any) => item[requirement] === 0)
}, 'Поле :attribute не должно содержать :nnv равный 0')
Validator.register('sum_of_is100', function (this: any, value, requirement, attribute) {
    return this.validator.input[attribute].length === 0 || 
    this.validator.input[attribute].reduce((partialSum: number, a: any) => Number(partialSum) + Number(a[requirement]), 0) === 100;
}, `Сумма значений :sum_of_is100 поля :attribute должна быть равна 100`)

const app = createApp(App)
if (import.meta.env.MODE === 'production') {
    Sentry.init({
        dsn: "https://75c1feb6ee4e88eecc012e9d3f7a809d@o4505846834003968.ingest.sentry.io/4506579623870464",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        app: app,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

app.use(createPinia())
app.use(router)

app.mount('#app')
