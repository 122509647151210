import userClient from "@/api/userClient";
import {ref} from "vue";
import type {
    AuthResponse, getEmployeesResponse, GetPermissionGroupResponse, getRoleResponse, getRolesResponse,
    getUserResponse,
    getUsersResponse,
    LoginResponse,
    RefreshTokenResponse
} from "@/types/responses";
import routes from "@/api/routes";
import type {
    JwtRefreshRequest,
    LoginCredentials,
    RoleUpdateRequest,
    RoleUserRequest,
    UserUpdateRequest
} from "@/types/requests";
import {Method} from "@/enums/Method";
import {AwaitingResponsesError} from "@/errors/AwaitingResponseError";
import type {AxiosError} from "axios";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {UserServiceForbiddenError} from "@/errors/UserServiceForbiddenError";
import {useRoute} from "vue-router";
import {useAlerts} from "@/stores/alert";
import {useLoadingQueue} from "@/stores/loadingQueue";
import {storeToRefs} from "pinia";

export default function useUserApi() {
    const { loadingQueue } = storeToRefs(useLoadingQueue())
    const route = useRoute()
    const alerts = useAlerts()

    const user = ref({
        // userRoutes
        auth: () => <Promise<AuthResponse>>handleRequest(routes.user.get),
        refresh: (request: JwtRefreshRequest) => <Promise<RefreshTokenResponse>>handleRequest(routes.user.refresh, Method.POST, request),
        login: (credentials: LoginCredentials) => <Promise<LoginResponse>>handleRequest(routes.user.login, Method.POST, credentials),
        create: (request: UserUpdateRequest) => handleRequest(routes.user.users, Method.POST, request),
        update: (id: number, request: UserUpdateRequest) => handleRequest(`${routes.user.users}/${id}`, Method.PUT, request),
        user: (id: number) => <Promise<getUserResponse>>handleRequest(`${routes.user.users}/${id}`),
        users: () => <Promise<getUsersResponse>>handleRequest(routes.user.users),
        delete: (id: number) => handleRequest(`${routes.user.users}/${id}`, Method.DELETE),
        employees: () => <Promise<getEmployeesResponse>>handleRequest(routes.user.employees),
        // role
        roles: () => <Promise<getRolesResponse>>handleRequest(routes.user.roles),
        role: (id: number) => <Promise<getRoleResponse>>handleRequest(`${routes.user.roles}/${id}`),
        createRole: (request: RoleUpdateRequest) => handleRequest(routes.user.roles, Method.POST, request),
        updateRole: (id: number, request: RoleUpdateRequest) => handleRequest(`${routes.user.roles}/${id}`, Method.PUT, request),
        deleteRole: (id: number) => handleRequest(`${routes.user.roles}/${id}`, Method.DELETE),
        addRoleUsers: (id: number, request: RoleUserRequest) => handleRequest(`${routes.user.roles}/${id}/users/add`, Method.POST, request),
        removeRoleUsers: (id: number, request: RoleUserRequest) => handleRequest(`${routes.user.roles}/${id}/users/remove`, Method.POST, request),
        // permissions
        permissionGroups: () => <Promise<GetPermissionGroupResponse>>handleRequest(routes.user.permissiongGroups)
    })

    async function handleRequest(
        route: string,
        method: Method = Method.GET,
        data: Object = {},
        params: Object = {}
    ) {
        if (loadingQueue.value.has(route)) throw new AwaitingResponsesError(`User service: Route ${route} is already awaiting response`)

        loadingQueue.value.add(route)

        try {
            const response = await userClient.request({
                method: method,
                url: route,
                data: data,
                params: params
            })

            return response.data
        } catch (err) {
            handleErrorResponse(err as AxiosError)
        } finally {
            loadingQueue.value.delete(route)
        }
    }

    function handleErrorResponse(error: AxiosError): void {
        const status = error?.response?.status
        const data = <{ data: any, message: string }>error?.response?.data

        if (status === 401) {
            if (route.path !== '/login') {
                // AuthLayout is going to refresh token on reload
                // and redirect to /login if both tokens are stale
                localStorage.removeItem('casAccessToken')
                location.reload()
            }
        }
        else if (status === 400 && data.hasOwnProperty('message') && data.message === 'Validation Error') {
            throw new UserServiceValidationError(data.message, data.data)
        } else if (status === 403) {
            throw new UserServiceForbiddenError(data.message)
        } else {
            alerts.flash('danger', 'Произошла ошибка', error.message)
            throw error
        }
    }

    return {
        user
    }
}

