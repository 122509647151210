<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ current: 'Добавить в очередь' }">
        Добавить в очередь push рассылки
      </PageTitle>
      <Card>
        <div class="mb-14 mt-5.5">
          <SelectInput v-model="form.localization" :options=options parameter="localization" placeholder="Выберите гео"
            label="Geo" class="w-full" />
        </div>

        <div class="mb-5.5">
          <TextArea v-model="form.teasers" label="Teaser Ids" placeholder="" parameter="teasers" class="w-full"
            :rowCount="3" />
        </div>
        <div class="mb-5.5">
          <p>
            Через запятую внутренние id тизеров. Тизеры будут добавлены в конец очереди рассылки.
          </p>
          <p>
            За сутки отправится пушей: 24 шт.
          </p>
        </div>
        <StandardButton @click="submit" class="items-center mb-5.5">
          Сохранить
        </StandardButton>
      </Card>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import TextArea from "@/shared/Inputs/TextArea.vue";
import { reactive } from "vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";

const api = useApi()

const form = reactive({
  localization: 'ru',
  teasers: ''
})
function submit(){
  api.notifications.createNotification(form)
}
const options = [{ key: 'ru', value: 'Россия' }]

</script>
