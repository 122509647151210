<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
        <PageTitle :breadcrumbs="{ url: '/links', prev: 'Ссылки', current: 'Редактирование ссылки' }">
          Редактирование ссылки
        </PageTitle>

        <LinkEditCard v-if="data" :link="data"/>
      </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import { useRoute, useRouter } from "vue-router";
import { useApi } from "@/stores/api";
import { ref } from "vue";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";
import type { Link, Source } from "@/types/entities";
import LinkEditCard from "@/shared/Cards/LinkEditCard.vue";

const route = useRoute()
const router = useRouter()
const api = useApi()

const data = ref<Link>()
getData()

function getData() {
  api.products.link(Number(route.params.id))
    .then(({link}) => {
      data.value = link
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}

</script>
