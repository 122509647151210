import axios from 'axios'
import {VITE_API_DOMAINS_BASE_URL, VITE_API_PRODUCTS_BASE_URL} from '@/application.config'

const domainsClient = axios.create({
    baseURL: VITE_API_DOMAINS_BASE_URL
})

domainsClient.interceptors.request.use(
    config => {
        if (localStorage.getItem('casAccessToken')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('casAccessToken')}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
export default domainsClient
