<template>
  <div class="w-full flex flex-col gap-8 2xl:flex-row">
    <ManualTopCard
        v-model="form"
        :errors="errors"
        :geo-options="geoOptions"
        :locale-options="localeOptions"
        :source-options="sourceOptions"
        :utm-source-options="utmSourceOptions"
        :source-count="sourceCount"
        button-text="Редактировать"
        class="w-full 2xl:w-1/2"
        @form:submit="submit"
    />

    <ManualTopTeasersCard v-model="teasers" class="w-full 2xl:w-1/2 h-full"/>

    <ConfirmModal
        v-model="isWarningModalVisible"
        :modal-text="warningModalText"
        @submit="finalSubmit(true)"
        @reject="finalSubmit(false)"
    />
  </div>
</template>

<script setup lang="ts">

import useManualTopForm from "@/composables/useManualTopForm";
import ManualTopCard from "@/shared/Cards/ManualTopCard.vue";
import ManualTopTeasersCard from "@/shared/Cards/ManualTopTeasersCard.vue";
import {useAlerts} from "@/stores/alert";
import {useApi} from "@/stores/api";
import {useRoute, useRouter} from "vue-router";
import {UserServiceForbiddenError} from "@/errors/UserServiceForbiddenError";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import ConfirmModal from "@/shared/Modals/ConfirmModal.vue";
import {ref} from "vue";

const route = useRoute()


const {
  form,
  errors,
  utmSourceOptions,
  teasers,
  geoOptions,
  localeOptions,
  sourceOptions,
  sourceCount,
  formRequest,
  validateRequest,
  populateErrors,
  existsRequest
} = useManualTopForm(Number(route.params.id))

const alerts = useAlerts()
const api = useApi()
const router = useRouter()

const isWarningModalVisible = ref(false)
const warningModalText = ref('')

function finalSubmit(isEnabled: boolean | null = null) {
  if (isEnabled !== null) form.enabled = isEnabled

  api.products.manualTopUpdate(Number(route.params.id), formRequest())
    .then(response => {
      alerts.flash('success', 'Ручной топ обновлен')
      router.push('/manual-tops')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

function submit() {
  if (!validateRequest(formRequest())) return

  api.products.manualTopExists(existsRequest())
    .then(response => {
      if (response.exists && response.manualTop && Number(route.params.id) !== response.manualTop.id) {
        warningModalText.value = 'Топ с таким ключом уже существует<br>' +
            `Включить этот топ вместо id: ${response.manualTop.id} ${response.manualTop.description}?`
        isWarningModalVisible.value = true
      } else {
        finalSubmit()
      }
    })
}
</script>
