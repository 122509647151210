import useRequestHandler from "@/composables/api/useRequestHandler";
import userClient from "@/api/userClient";
import {ref} from "vue";
import type {GetGeosResponse, GetNotificationsResponse} from "@/types/responses";
import routes from "@/api/routes";
import {Method} from "@/enums/Method";
import type {NotificationRequest} from "@/types/requests";

export default function useNotificationApi() {
    const { handleRequest } = useRequestHandler(userClient, 'notification')

    const notifications = ref({
        //notifications
        getNotifications: () => <Promise<GetNotificationsResponse>>handleRequest(routes.notifications.notifications),
        deleteNotification: (id: number) => handleRequest(`${routes.notifications.notifications}/${id}`, Method.DELETE),
        createNotification: (request: NotificationRequest) => handleRequest(routes.notifications.notifications, Method.POST, request),
        //geo
        geo: () => <Promise<GetGeosResponse>>handleRequest(routes.notifications.geo)
    })

    return {
        notifications
    }
}
