import { defineStore } from "pinia"
import { computed, ref } from "vue"
import userClient from '@/api/userClient'
import rotationEmulatorClient from "@/api/rotationEmulatorClient";
import sourcesClient from "@/api/sourcesClient";
import productsClient from "@/api/productsClient"
import spyClient from "@/api/spyClient"
import routes from '@/api/routes'
import type {
    LoginCredentials,
    UserUpdateRequest,
    CitiesRequest,
    SiteIdsRequest,
    BlockIdsRequest,
    RotationRequest,
    RoleUpdateRequest,
    RoleUserRequest,
    SourceRequest,
    NotificationRequest,
    NewsTeasersRequest,
    JwtRefreshRequest,
    TemplateRequest,
    AccountRequest,
    AccountsRequest,
    ProductTeaserRequest,
    ProductTeasersRequest,
    StreamListRequest,
    ProductTeaserEditRequest,
    ManualTopListRequest,
    ManualTopExistsRequest,
    ManualTopStatusRequest,
    ManualTopStoreRequest,
    SpyListRequest,
    LinkListRequest,
    CreateLinkRequest,
    ThemesListRequest,
    CreateThemeRequest,
    StreamCreateRequest,
    StreamsListRequest,
} from "@/types/requests";
import type {
    AuthResponse,
    getRoleResponse,
    getRolesResponse,
    getUserResponse,
    getUsersResponse,
    getRotationOptionsResponse,
    DataArrayResponse,
    RotationPredictionResponse,
    DataIdNameResponse,
    GetPermissionGroupResponse,
    getAccountsResponse,
    getAccountResponse,
    GetNotificationsResponse,
    GetGeosResponse,
    GetNewsTeasersResponse,
    getEmployeesResponse,
    LoginResponse,
    RefreshTokenResponse,
    getSourcesResponse,
    getSourceResponse,
    getTemplateResponse,
    getTemplatesResponse,
    getAccountOptionsResponse,
    getProductsResponse,
    getProductResponse,
    getProductCreateOptionsResponse,
    getProductsTableOptionsResponse,
    getStreamsListResponse,
    getManualTopsResponse,
    getManualTopOptionsResponse,
    getManualTopExistsResponse,
    getManualTopResponse,
    getSourceMapResponse,
    getCreativeOrderedResponse,
    getSpyListResponse,
    getSpyOptionsResponse,
    getLinksOptionsResponse,
    getLinkResponse,
    getLinksResponse,
    getThemeResponse,
    getThemesResponse,
    getStreamResponse,
    getStreamsResponse,
    getStreamsOptions,
} from "@/types/responses";
import { AwaitingResponsesError } from "@/errors/AwaitingResponseError";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRoute, useRouter } from "vue-router";
import type { AxiosError } from "axios";
import { useAlerts } from "@/stores/alert";
import { RotationServiceValidationError } from "@/errors/RotationServiceValidationError";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";
import {Method} from "@/enums/Method";
import useDomainsApi from "@/composables/api/useDomainsApi";
import useProductsApi from "@/composables/api/useProductsApi";
import useSpyApi from "@/composables/api/useSpyApi";
import useSourceApi from "@/composables/api/useSourceApi";
import useRotationEmulatorApi from "@/composables/api/useRotationEmulatorApi";
import useUserApi from "@/composables/api/useUserApi";
import useNotificationApi from "@/composables/api/useNotificationApi";


export const useApi = defineStore('api', () => {
    const { user } = useUserApi()
    const { sources } = useSourceApi()
    const { products } = useProductsApi()
    const { spy } = useSpyApi()
    const { domains } = useDomainsApi()
    const { notifications} = useNotificationApi()
    const { rotationEmulator } = useRotationEmulatorApi()

    return {
        user,
        sources,
        products,
        notifications,
        spy,
        rotationEmulator,
        domains
    }
})
