<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <ExpandableTable v-if="filteredData" :selectableRows="true" @change:page="getData" @refresh:rows="getData" :headers="headers"
        :rows="filteredData"
        :column-order="['id', 'img', 'news_id', 'author', 'created_at', 'title', 'views', 'clicks', 'costs', 'ctr', 'roi', 'ratio']"
        :actions="actions" :per-page="24" :actions-on-hover="true" :external-teasers-rows="expandedRows" :row-count="itemsCount" :sub-table-type="'news'">

        <div>
          <div class="flex  justify-between datatable-top">
            <div class="flex flex-col gap-2 sm:flex-row">
              <StandardButton class="items-center">
                <PlusIcon class="fill-current mr-2" />Добавить внешний тизер в DSP
              </StandardButton>
              <StandardButton class="items-center">
                <PlusIcon class="fill-current mr-2" />Добавить внешний тизер
              </StandardButton>
              <StandardButton class="items-center bg-white text-primary border border-primary">
                В топ лист
              </StandardButton>
            </div>
            <SearchMultiSelect v-model="filtersForm.selectedGeos" parameter="block_ids" :options="geos"
              placeholder="GEO" class="lg:w-[30%] w-full">
              <GeoIcon />
            </SearchMultiSelect>
          </div>

          <div
            class="flex justify-between sm:flex-row-reverse sm:items-center sm:justify-between gap-4 pt-8 px-7.5 flex-col-reverse flex sm:gap-x-4 sm:gap-y-0 after:hidden">
            <TextInput v-model="filtersForm.views" parameter="views" placeholder="Просмотры" />
            <TextInput v-model="filtersForm.title" parameter="title" placeholder="Заголовок" />
            <VueTailwindDatepicker v-if="showDatePicker" :options="dateInputOptions" i18n="ru" class="sm:max-w-60"
              placeholder="Создан" :formatter="dateInputFormatter" v-model="filtersForm.created_at"
              :disable-date="disableDates"
              input-classes="text-black relative z-20 w-[100%] appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary disabled:bg-gray">
              <template #inputIcon="{ value }">
                {{ "" }}
              </template>
            </VueTailwindDatepicker>
            <SelectInput v-model="filtersForm.author" parameter="author" class="sm:w-60 " :options="authors"
              placeholder="Автор" />
            <TextInput v-model="filtersForm.news" parameter="news" placeholder="Новость" />
            <TextInput v-model="filtersForm.id" parameter="id" @change="demoFilter(data)" placeholder="ID" />
          </div>
          <div class="flex justify-between datatable-top">
            <IconButton @click="filtersToRequest()">
              <CheckmarkIcon class="fill-white transition" width="25" height="25" />
            </IconButton>
            <IconButton @click="resetFilters()" class="items-center bg-white text-primary border border-red">
              <TimesIcon class="fill-red transition" width="25" height="25" />
            </IconButton>
            <TextInput v-model="filtersForm.ratio" parameter="ratio" placeholder="RATIO" />
            <TextInput v-model="filtersForm.roi" parameter="roi" placeholder="ROI" />
            <TextInput v-model="filtersForm.ctr" parameter="ctr" placeholder="CTR" />
            <TextInput v-model="filtersForm.costs" parameter="costs" placeholder="Затраты" />
            <TextInput v-model="filtersForm.clicks" parameter="clicks" placeholder="Клики" />
          </div>
        </div>

      </ExpandableTable>
    </Card>
  </div>

  <Teleport to="#hiddenContainer">
    <CopyIcon id="copyIcon" class="fill-current" />
    <StopIcon id="stopIcon" class="fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import ExpandableTable from "@/shared/Tables/ExpandableTable.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import IconButton from "../Buttons/IconButton.vue";
import type { AlphaNumerical, TableAction, TableHeader } from "@/types/props";
import { ref, onMounted, reactive, nextTick, toRaw } from "vue";
import CopyIcon from "@/icons/CopyIcon.vue";
import StopIcon from "@/icons/StopIcon.vue";
import TextInput from "../Inputs/TextInput.vue";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import GeoIcon from "@/icons/GeoIcon.vue";
import SelectInput from "../Inputs/SelectInput.vue";
import type { ExternalTeaser, NewsTeaser } from "@/types/entities";
import { type SelectOption } from "@/types/props";
import TimesIcon from "@/icons/TimesIcon.vue";
import CheckmarkIcon from "@/icons/CheckmarkIcon.vue";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { useRoute } from "vue-router";
import type { NewsTeasersRequest } from "@/types/requests";
import { useRouter } from "vue-router";


const itemsCount = ref<number>(100)
const router = useRouter()

const route = useRoute()
const api = useApi()
const geos = {
  'RU': 'Россия',
  'KZ': 'Казахстан',
  'UZ': 'Узбекистан',
  'RM': 'Румыния',
  'UK': 'Украина',
  'TA': 'Тайланд',
  'IN': 'Индонезия',
}
const authors = ref<SelectOption[]>([])

const data = ref<Record<string, AlphaNumerical>[]>([])
const filteredData = ref<Record<string, AlphaNumerical>[]>([])

const expandedRows = ref<Record<number, ExternalTeaser>[]>([])

let showDatePicker = ref(true)

const dateInputFormatter = ref({
  date: 'DD/MM/YYYY',
  month: 'MMM',
})

const dateInputOptions = ref({
  shortcuts: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    past: (period: number) => `${period} дней`,
    currentMonth: 'Этот месяц',
    pastMonth: 'Прошлый месяц',
  },
  footer: {
    apply: 'Применить',
    cancel: 'Отмена',
  },
})

function disableDates(date: Date) {
  return date > new Date()
}

const filtersFormInitialState = {
  ratio: '',
  roi: '',
  ctr: '',
  costs: '',
  clicks: '',
  views: '',
  title: '',
  created_at: { start: '', end: '' },
  author: 0,
  news: '',
  id: '',
  selectedGeos: [] as string[]
}

let filtersForm = reactive({ ...filtersFormInitialState })

async function resetFilters() {
  Object.assign(filtersForm, filtersFormInitialState);
  showDatePicker.value = false;
  await nextTick()
  showDatePicker.value = true;
  filteredData.value = data.value
}


getData()

function filtersToRequest() {
  const { sort, order, page } = route.query
  const { id, news, author, created_at, title, views, clicks, costs, ctr, roi, ratio, selectedGeos } = filtersForm

  const request: NewsTeasersRequest = {
    page: Number(page),
    itemsPerPage: 24,
    sortBy: sort ? sort.toString() : undefined,
    sortOrder: order ? order.toString() : undefined,
    id: id.length ? Number(id) : undefined,
    newsId: news.length ? Number(news) : undefined,
    authorId: author || undefined,
    createdAtFrom: created_at.start.length ? created_at.start : undefined,
    createdAtTo: created_at.end.length ? created_at.end : undefined,
    title: title.length ? title : undefined,
    views: views.length ? Number(views) : undefined,
    clicks: clicks.length ? Number(clicks) : undefined,
    costs: costs.length ? Number(costs) : undefined,
    ctr: ctr.length ? Number(ctr) : undefined,
    roi: roi.length ? Number(roi) : undefined,
    ratio: ratio.length ? Number(ratio) : undefined,
    geos: selectedGeos.length ? selectedGeos : undefined,
  }
  router.replace({ query: toRaw(request) })
  filteredData.value = demoFilter(data.value)
}

function queryToFilters() {
  const { id, newsId, authorId, createdAtFrom, createdAtTo, title, views, clicks, costs, ctr, roi, ratio, geos } = route.query
  const selectedGeos = [] as string[]
  if (geos) {
    if (Array.isArray(geos)) {
      geos.forEach(item => {
        if (item) {
          selectedGeos.push(item.toString())
        }
      })
    }
    else {
      selectedGeos.push(geos.toString())
    }
  }

  Object.assign(filtersForm, 
  {
    ratio: ratio?.toString() || '',
    roi: roi?.toString() || '',
    ctr: ctr?.toString() || '',
    costs: costs?.toString() || '',
    clicks: clicks?.toString() || '',
    views: views?.toString() || '',
    title: title?.toString() || '',
    created_at: { start: createdAtFrom?.toString() || '', end: createdAtTo?.toString() || '' },
    author: authorId ? Number(authorId) : 0,
    news: newsId?.toString() || '',
    id: id?.toString() || '',
    selectedGeos,
  });
}

queryToFilters()

function demoFilter(rows: Record<string, AlphaNumerical>[]) {
  let filtered_rows = rows
  const { id, news, author, created_at, title, views, clicks, costs, ctr, roi, ratio, selectedGeos } = filtersForm
  if (id.length) {
    filtered_rows = filtered_rows.filter(row => row.id.toString().includes(id))
  }
  if (news.length) {
    filtered_rows = filtered_rows.filter(row => row.news_id.toString().includes(news))
  }
  if (author !== 0) {
    filtered_rows = filtered_rows.filter(row => row.user_id === author)
  }
  if (created_at.start.length && created_at.end.length) {
    filtered_rows = filtered_rows.filter(row => new Date(row.created_at_filter).getTime() > new Date(created_at.start).getTime() && new Date(row.created_at_filter).getTime() < new Date(created_at.end).getTime())
  }
  if (title.length) {
    filtered_rows = filtered_rows.filter(row => row.title_sort.toString().includes(title))
  }
  if (views.length) {
    filtered_rows = filtered_rows.filter(row => row.views.toString().includes(views))
  }
  if (clicks.length) {
    filtered_rows = filtered_rows.filter(row => row.clicks.toString().includes(clicks))
  }
  if (costs.length) {
    filtered_rows = filtered_rows.filter(row => row.costs.toString().includes(costs))
  }
  if (ctr.length) {
    filtered_rows = filtered_rows.filter(row => row.ctr.toString().includes(ctr))
  }
  if (roi.length) {
    filtered_rows = filtered_rows.filter(row => row.roi.toString().includes(roi))
  }
  if (ratio.length) {
    filtered_rows = filtered_rows.filter(row => row.ratio.toString().includes(ratio))
  }
  if (selectedGeos.length) {
    filtered_rows = filtered_rows.filter(row => selectedGeos.includes(row.geo_filter.toString()))
  }
  return filtered_rows
}

function getData() {
  const rows: Record<string, AlphaNumerical>[] = []
  const subRows: Record<number, ExternalTeaser>[] = []
  const userOptions: SelectOption[] = []
  const mock: NewsTeaser = {
    id: 30303,
    news_id: 345,
    user_id: 345345,
    img_url: 'https://masterpiecer-images.s3.yandex.net/d123c198603b11ee88ec92669a1675b3:upscaled',
    news_url: 'https://s-24.news/404.html?u=den-vlyublyonnyh-sladkij-prazdnik-ili-kak-otmetit-14-fevralya',
    created_at: '10/03/2024 12:20:30',
    title: "Новость Новость Новость",
    views: 100,
    clicks: 10,
    costs: 20,
    ctr: 0.00,
    roi: 0,
    ratio: 0.00,
    geo: 'RU',
    external_teasers: [
      {
        source: 'geoza',
        ext_id: 33,
        campaign_id: 1,
        status: 'active',
        url: 'https://s-24.news/404.html?u=den-vlyublyonnyh-sladkij-prazdnik-ili-kak-otmetit-14-fevralya',
        views: 100,
        clicks: 20,
        costs: 10,
        ctr: 4,
        roi: 4,
        created_at: '22.02.2020',
        stopped_at: '30.01.2021',
      },
      {
        source: 'geoza',
        ext_id: 33,
        campaign_id: 1,
        status: 'active',
        url: 'https://s-24.news/404.html?u=den-vlyublyonnyh-sladkij-prazdnik-ili-kak-otmetit-14-fevralya',
        views: 100,
        clicks: 20,
        costs: 10,
        ctr: 4,
        roi: 4,
        created_at: '22.02.2020',
        stopped_at: '30.01.2021',
      }
    ]
  }

  api.user.users()
    .then(({ users }) => {
      for (let i = 0; i < 100; i = i + 1) {
        rows.push({
          id: i,
          img: `<img src="${mock.img_url}" width="90">`,
          title_sort: mock.title,
          title: `<div class="w-50 text-truncate">${mock.title}<br><a style="color: #0000EE; cursor: pointer;" href="${mock.news_url}">${mock.title}</a></div>`,
          created_at: `<div class="w-25 text-truncate">${mock.created_at.toString().split(' ')[0]}<br>${mock.created_at.toString().split(' ')[1]}</div>`,
          created_at_filter: mock.created_at,
          views: Math.floor(Math.random() * 100),
          news_id: Math.floor(Math.random() * 1000),
          clicks: Math.floor(Math.random() * 20),
          costs: Math.floor(Math.random() * 10),
          user_id: i % 2 == 0 ? 1 : 8,
          author: users.find((u) => u.id == (i % 2 == 0 ? 1 : 8))?.name || '',
          isSelected: 0,
          ctr: mock.ctr,
          roi: mock.roi,
          ratio: mock.ratio,
          geo: i % 2 == 0 ? 'Россия' : (i % 3 == 0 ? 'Узбекистан' : 'Казахстан'),
          geo_filter:i % 2 == 0 ? 'RU' : (i % 3 == 0 ? 'UZ' : 'KZ'),
          is_expandable: i%2
        })
        subRows[i] = mock.external_teasers
      }
      for (const user of users) {
        userOptions.push({ key: user.id, value: user.name })
      }
      data.value = rows
      filteredData.value = demoFilter(data.value)
      expandedRows.value = subRows
      authors.value = userOptions
    })
}

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Изображение' },
  { name: 'Новость', sortKey: 'news_id' },
  { name: 'Автор', sortKey: 'author' },
  { name: 'Создан', sortKey: 'created_at' },
  { name: 'Заголовок', sortKey: 'title_sort' },
  { name: 'Просмотры', sortKey: 'views' },
  { name: 'Клики', sortKey: 'clicks' },
  { name: 'Затраты', sortKey: 'costs' },
  { name: 'CTR', sortKey: 'ctr' },
  { name: 'ROI', sortKey: 'roi' },
  { name: 'RATIO', sortKey: 'ratio' },
]
const actions = ref<TableAction[]>();

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => { },
      visible: () => true,
      parameter: "id",
      title: 'Копировать',
      icon: document.getElementById("copyIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => { },
      visible: () => true,
      parameter: "id",
      title: 'Копировать',
      icon: document.getElementById("copyIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => { },
      visible: () => true,
      parameter: "id",
      title: 'Остановить',
      icon: document.getElementById("stopIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => { },
      visible: () => true,
      parameter: "id",
      title: 'Копировать',
      icon: document.getElementById("copyIcon")?.outerHTML ?? "",
    },
  ];
});
</script>
