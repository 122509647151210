<template>
  <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
    <div v-if="slots.title || slots.header" class="border-b border-stroke py-4 px-7 dark:border-strokedark">
      <h3 class="font-medium text-black dark:text-white">
        <slot name="title"/>
      </h3>
      <template v-if="slots.header">
        <slot name="header"/>
      </template>
    </div>

    <div v-if="!hideBody" :class="noPadding === true ? 'p-0' : 'p-7'">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useSlots} from "vue";

defineProps<{
  noPadding?: boolean
  hideBody?: boolean
}>()

const slots = useSlots()
</script>
