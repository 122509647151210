<template>
  <div>
    <label v-if="label" :for="parameter" class="mb-3 block text-sm font-medium text-black dark:text-white">
      {{ label }}
    </label>
    <div class="relative">
      <span v-if="slots.default" class="absolute left-4.5 top-4">
        <slot/>
      </span>
      <textarea
          v-model="inputModel"
          :class="{ 'pl-11.5 pr-4.5': slots.default, 'px-4.5': !slots.default, 'border-red': errors && errors.length, 'resize-none': resizable === false }"
          :disabled="disabled"
          :rows="rowCount || 4"
          class="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          type="'text'"
          :name="parameter"
          :id="parameter"
          :placeholder="placeholder || ''"
      />
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import {type Ref, useSlots} from 'vue'

defineProps<{
  parameter: string
  label?: string
  rowCount?: number
  placeholder?: string
  disabled?: boolean
  errors?: string[]
  resizable?: boolean
}>()

const inputModel: Ref<string> = defineModel({ required: true })

const slots = useSlots()
</script>
