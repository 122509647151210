import type {AxiosError, AxiosInstance} from "axios";
import {useAlerts} from "@/stores/alert";
import {Method} from "@/enums/Method";
import {AwaitingResponsesError} from "@/errors/AwaitingResponseError";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {UserServiceForbiddenError} from "@/errors/UserServiceForbiddenError";
import {storeToRefs} from "pinia";
import {useLoadingQueue} from "@/stores/loadingQueue";

export default function useRequestHandler(
    axiosClient: AxiosInstance,
    serviceName: string
) {
    const alerts = useAlerts()
    const { loadingQueue } = storeToRefs(useLoadingQueue())

    async function handleRequest(
        route: string,
        method: Method = Method.GET,
        data: Object = {},
        params: Object = {}
    ) {
        if (loadingQueue.value.has(route)) {
            throw new AwaitingResponsesError(
                `${serviceName[0].toUpperCase() + serviceName.slice(1)} service: Route ${route} is already awaiting response`
            )
        }
        loadingQueue.value.add(route)

        try {
            const response = await axiosClient.request({
                method: method,
                url: route,
                data: data,
                params: params
            })

            return response.data
        } catch (err) {
            handleErrorResponse(err as AxiosError)
        } finally {
            loadingQueue.value.delete(route)
        }
    }

    function handleErrorResponse(error: AxiosError): void {
        const status = error?.response?.status
        const data = <{ data: any, message: string }>error?.response?.data

        if (status === 400 && data.hasOwnProperty('message') && data.message === 'Validation Error') {
            throw new UserServiceValidationError(data.message, data.data)
        } else if (status === 403) {
            throw new UserServiceForbiddenError(data.message)
        } else {
            alerts.flash('danger', 'Произошла ошибка', error.message)
            throw error
        }
    }

    return {
        handleRequest
    }
}
