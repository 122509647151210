<template>
  <div class="relative">
    <label v-if="label" class="mb-3 block text-sm font-medium text-black dark:text-white">
      {{ label }}
    </label>
    <Checkbox @click.prevent="handleAll()" :fill-box="selectedItems.length < optionsAsArray.length"
      :is-checked="selectedItems.length > 0" label="Выбрать все" />
    <div class="flex flex-col ml-10 mt-3">
      <div v-for="item in options">
        <Checkbox @click.prevent="handleOne(item.code)" class="ml-5 mb-3" v-if="item.type == 'option' && item.code"
          :is-checked="selectedItems.includes(item.code)" :label="item.label" />
        <div v-if="item.type == 'group' && item.items" class="flex flex-col mb-3">
          <div class="flex flex-row gap-3 items-center">
            <span @click="item.isOpen = !item.isOpen"
              class="text-sm font-medium cursor-pointer text-black dark:text-white">+</span>
            <Checkbox @click.prevent="handleGroup(item.items)"
              :fill-box="!item.items.every(i => selectedItems.includes(i.code!))"
              :is-checked="item.items.some(i => selectedItems.includes(i.code!))" :label="item.label" />
          </div>
          <div v-if="item.isOpen" class="flex flex-col gap-3 ml-10 mt-3">
            <div v-for="option in item.items">
              <Checkbox @click.prevent="handleOne(option.code)" class="ml-7" v-if="option.code"
                :is-checked="selectedItems.includes(option.code)" :label="option.label" />
            </div>
          </div>

        </div>
      </div>
    </div>

    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed } from "vue";
import type { AccordionItem, AlphaNumerical } from "@/types/props";
import Checkbox from "@/shared/Inputs/Checkbox.vue";

const props = defineProps<{
  parameter: string
  options: AccordionItem[]
  placeholder: string
  label?: string
  disabled?: boolean
  errors?: string[]
}>()

const selectedItems: Ref<AlphaNumerical[]> = defineModel({ required: true })

const optionsAsArray = computed(() => {
  const items: AccordionItem[] = [];
  if (props.options) {
    props.options.map(item => {
      if (item.type === 'option' && item.code) {
        items.push(item)
      } else {
        if (item.items) {
          item.items.forEach((i) => i.code && items.push(i))
        }
      }
    })
  }


  return items
})

function handleOne(option: AlphaNumerical) {
  !selectedItems.value.includes(option) ? selectedItems.value.push(option) : selectedItems.value.splice(selectedItems.value.indexOf(option), 1);
}

function handleAll() {
  if (selectedItems.value.length) {
    selectedItems.value = []
  } else {
    optionsAsArray.value.forEach((item) => item.code && selectedItems.value.push(item.code))
  }
}

function handleGroup(items: AccordionItem[]) {
  if (items.every(item => selectedItems.value.includes(item.code!))) {
    selectedItems.value = selectedItems.value.filter((item) => !items.some((i) => i.code === item))
  } else {
    items.forEach((item) => item.code && selectedItems.value.push(item.code))
  }
}

</script>
