<template>
  <div class="data-table-common data-table-one max-w-full overflow-x-auto">
    <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
      <div v-if="slots.default || searchFields" :class="{ 'datatable-top': !emptyTop }">
        <slot />
        <div v-if="searchFields || title" class="w-full flex-row justify-between items-center sm:flex">
          <h2 v-if="title" class="text-title-md mb-5 font-bold text-black dark:text-white sm:mb-0">
            {{ title }}
          </h2>
          <TableSearchInput v-if="searchFields" @set:search="setSearch" v-model="searchValue" placeholder="Поиск" />
        </div>
      </div>

      <div class="datable-container">
        <table class="table w-full table-auto datatable-table" :class="align || 'middle'">
          <thead>
            <tr>
              <TableHeaderCell @click.prevent="setSort(header?.sortKey)" v-for="header in headers"
                :sort-key="header.sortKey" :name="header.name" :active="form.sort === header.sortKey"
                :order="form.order" />
              <TableHeaderCell v-if="actions" :name="!hideActionsTitle ? 'Действия' : ''" :active="false" order=""
                class="!text-right" />
            </tr>
          </thead>
          <tbody v-for="group in groups">
            <tr class="w-full items-center">
              <td :colspan="columnCount" @click="group.isOpen = !group.isOpen"
                class="w-full !pt-3 !pb-3 cursor-pointer text-center  font-bold !text-black dark:text-white ">
                <div class="flex justify-center items-center gap-3">
                  <EyeClosedIcon v-if="!group.isOpen" class="h-[20px] fill-primary" />
                  <EyeOpenedIcon v-if="group.isOpen" class="h-[20px] fill-red" />
                  {{ group.label }}<div class="flex gap-1">
                    <p :class="!group.isOpen && '!text-primary'">{{ group.rows.filter((row) => row.isActive).length }}
                    </p> / <p :class="group.isOpen && '!text-red'">{{ group.rows.length }}</p>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-for="row in group.rows.filter((row) => row.isActive || group.isOpen)" class="row-hover"
              :class="[row.hasOwnProperty('color') ? row.color : 'row-hover']">
              <td v-for="col in columnOrder" @click="cellActions && onCellClick(row, col, cellActions)">
                <div class="flex gap-3">
                  <span v-html="row[col]"></span>
                  <CopyIcon v-if="copiedColumns && copiedColumns[col]" @click="copy(row[copiedColumns[col]])"
                    class="fill-body showOnHover hover:fill-primary cursor-pointer" />
                </div>
              </td>
              <td v-if="actions">
                <div class="flex justify-end gap-2" :class="{ showOnHover: actionsOnHover }">
                  <a href="#" v-if="actions" v-for="action in actions" v-show="action.visible(row[action.parameter])"
                    @click.prevent="action.action(row[action.parameter])" :title="action.title" v-html="action.icon" />
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>

      <TablePaginator :disable-pagination="disablePagination" v-if="perPage !== 0" @set:page="setPage" :current-page="form.page" :max-page="maxPage"
        :item-count="rowCount" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AlphaNumerical, TableProps, TableRow, TableCellActions, GroupedTableProps } from "@/types/props";
import TableHeaderCell from "@/shared/Tables/TableHeaderCell.vue";
import TablePaginator from "@/shared/Tables/TablePaginator.vue";
import useGroupedTable from "@/composables/useGroupedTable";
import TableSearchInput from "@/shared/Inputs/TableSearchInput.vue";
import { useSlots } from "vue";
import CopyIcon from "@/icons/CopyIcon.vue";
import EyeClosedIcon from "@/icons/EyeClosedIcon.vue";
import EyeOpenedIcon from "@/icons/EyeOpenedIcon.vue";

const props = defineProps<GroupedTableProps>()
const emit = defineEmits(['refresh:rows'])
const slots = useSlots()

const {
  form,
  searchValue,
  maxPage,
  rowCount,
  groups,
  setSearch,
  setSort,
  setPage,
} = useGroupedTable(props, emit)


function onCellClick(row: TableRow, col: string, cellActions: TableCellActions) {
  switch (col) {
    case "name":
      cellActions.openRole && cellActions.openRole?.action(row[cellActions.openRole.parameter])
      break;
    case "parentName":
      cellActions.openParent && cellActions.openRole?.action(row[cellActions.openParent.parameter])
      break;
    case "source":
      cellActions.openAccount && cellActions.openAccount?.action(row[cellActions.openAccount.parameter])
      break;
    default:
      break;
  }
}

function copy(value: AlphaNumerical) {
  navigator.clipboard.writeText(value.toString())
}

</script>
