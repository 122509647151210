<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ current: 'Главная' }">
        Главная
      </PageTitle>
    </div>
  </AuthLayout>
</template>
<script setup lang="ts">
import {useRouter} from "vue-router";
import {onBeforeMount} from "vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";

const router = useRouter()
onBeforeMount(() => {
  if (!localStorage.getItem('casAccessToken')) {
    router.push('/login')
  }
})


</script>
