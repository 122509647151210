<template>
  <div>
    <div class="flex gap-3 items-end">
      <SelectInput v-model="userId" parameter="user_ids" :options="options" label="Выберите пользователя" placeholder=""
        class="w-full" />
      <AddButton v-if="!hide_add" @click="confirm" />
    </div>
    <p v-if="errors && errors.length" class="text-red mt-2">{{ errors.join(', ') }}</p>

    <Transition name="scale">
      <div v-if="selectedUsers.length"
        class="transition origin-top mt-8.5 bg-whiten rounded flex flex-col gap-2 p-4 max-h-[304px] overflow-y-auto">
        <div v-for="(user, key) in selectedUsers" class="p-3 flex justify-between bg-white rounded">
          <p class="text-black font-medium">{{ user.value }}</p>
          <UserMinusIcon @click="removeUser(key)" width="24" height="24" class="fill-red cursor-pointer" />
        </div>
      </div>
    </Transition>
    <ConfirmModal v-model="confirmVisible" @submit="addUser" @reject="reject"
      :modalText="modalText" />
  </div>
</template>

<script setup lang="ts">
import AddButton from "@/shared/Buttons/AddButton.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import UserMinusIcon from "@/icons/UserMinusIcon.vue";
import type { SelectOption } from "@/types/props";
import { ref, type Ref, watch } from "vue";
import type { UserList } from "@/types/entities";
import ConfirmModal from "@/shared/Modals/ConfirmModal.vue"


const props = defineProps<{
  options: SelectOption[]
  errors?: string[]
  need_confirm?: boolean
  userList?:UserList
  hide_add?: boolean
}>()

const modalText: Ref<string> = ref('')
const confirmVisible: Ref<boolean> = ref(false)
const selectedUserIds: Ref<number[]> = defineModel({ required: true })
const selectedUsers: Ref<SelectOption[]> = ref([])
const userId: Ref<number | null> = ref(null)

function confirm() {
  if (!userId.value || selectedUserIds.value.includes(userId.value)) return
  const user = props.userList?.find((i) => i.id === userId.value)
  if (props.need_confirm && user?.role_id) {
    modalText.value = `У юзера ${user.name} уже есть роль ${user.role.name}. Подтвердить смену роли?`
    confirmVisible.value = true
  } else {
    addUser()
  }
}

preload()
  
function reject() {
  confirmVisible.value = false
}

function addUser() {
  confirmVisible.value = false
  if (!userId.value || selectedUserIds.value.includes(userId.value)) return
  const user = props.options.find(user => user.key === userId.value)
  if (user) {
    selectedUserIds.value.push(userId.value)
    selectedUsers.value.push(user)
  }
}

function preload() {
  selectedUserIds.value.forEach(i => {
    const user = props.options.find(user => user.key === i)
    if (user) {
      selectedUsers.value.push(user)
    }
  });
}

watch(selectedUserIds, () => {
  if (!(selectedUsers.value.length === selectedUserIds.value.length)) {
    preload()
  }
})

function removeUser(index: number) {
  if (selectedUsers.value[index]) {
    const option = selectedUsers.value[index]

    selectedUserIds.value.splice(selectedUserIds.value.indexOf(Number(option.key)), 1)
    selectedUsers.value.splice(index, 1)
  }
}

// case when selectedUserIds are cleared outside of component
watch(selectedUserIds, userIds => {
  if (!userIds.length) {
    userId.value = null
    selectedUsers.value = []
  }
})

</script>
