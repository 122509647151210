<template>
  <svg width="23" height="22" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98828 13.4253C10.0508 13.4253 11.7383 11.7378 11.7383 9.67529C11.7383 7.61279 10.0508 5.92529 7.98828 5.92529C5.92578 5.92529 4.23828 7.61279 4.23828 9.67529C4.23828 11.7378 5.92578 13.4253 7.98828 13.4253ZM7.98828 7.61279C9.11328 7.61279 10.0508 8.55029 10.0508 9.67529C10.0508 10.8003 9.11328 11.7378 7.98828 11.7378C6.86328 11.7378 5.92578 10.8003 5.92578 9.67529C5.92578 8.55029 6.86328 7.61279 7.98828 7.61279Z"
        fill-rule="evenodd" clip-rule="evenodd" />
      <path
        d="M7.98831 14.063C5.4383 14.063 2.9633 14.963 1.0508 16.5755C0.713305 16.8755 0.638305 17.4005 0.938305 17.7755C1.2383 18.113 1.7633 18.188 2.1383 17.888C3.7508 16.5005 5.85081 15.7505 7.98831 15.7505C10.1258 15.7505 12.2258 16.5005 13.8383 17.888C13.9883 18.038 14.1758 18.0755 14.4008 18.0755C14.6258 18.0755 14.8883 17.963 15.0383 17.7755C15.3383 17.438 15.3008 16.8755 14.9258 16.5755C13.0133 14.963 10.5383 14.063 7.98831 14.063Z"
        fill-rule="evenodd" clip-rule="evenodd"  />
      <path
        d="M22.6133 13.6878H21.5258V12.6003C21.5258 12.1503 21.1508 11.7378 20.6633 11.7378C20.1758 11.7378 19.8008 12.1128 19.8008 12.6003V13.6878H18.7133C18.2633 13.6878 17.8508 14.0628 17.8508 14.5503C17.8508 15.0378 18.2258 15.4128 18.7133 15.4128H19.8008V16.5003C19.8008 16.9503 20.1758 17.3628 20.6633 17.3628C21.1508 17.3628 21.5258 16.9878 21.5258 16.5003V15.3753H22.6133C23.0633 15.3753 23.4758 15.0003 23.4758 14.5128C23.4758 14.0253 23.0633 13.6878 22.6133 13.6878Z"
        fill-rule="evenodd" clip-rule="evenodd"  />
    
    <defs>
      <clipPath id="clip0_730_1265">
        <rect width="24" height="24" fill="white" transform="translate(0.113281 0.000488281)" />
    </clipPath>
  </defs>
</svg></template>
