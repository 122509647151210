<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable
          v-if="data"
          @refresh:rows="getData"
          :headers="headers"
          :rows="data"
          :column-order="['formattedId', 'name', 'parentName', 'userCount']"
          :search-fields="['id', 'name', 'parentName']"
          :actions="actions"
          :per-page="15"
          :cell-actions="cellActions"
      >
        <StandardButton @click="isCreateModalVisible = true" v-if="userStore.can('roles', 'create')">
          Добавить роль
        </StandardButton>
      </StandardTable>
    </Card>
  </div>

  <RoleCreateModal v-model="isCreateModalVisible" @refresh:table="getData" />

  <RoleEditModal v-model="isEditModalVisible" @refresh:table="getData" :role="editModalRole" />

  <RoleAddModal v-model="isAddModalVisible" @refresh:table="getData" :role-id="addModalId" />

  <DeleteModal
      v-if="isDeleteModalVisible && deleteModalContent"
      v-model="isDeleteModalVisible"
      :id="currentRowId"
      flashText="Роль успешно удалена"
      @refresh:table="getData"
      :submit="deleteAction"
  >
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <PencilWithoutPaperIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
    <AddUserIcon id="addUserIcon" class="fill-current transition hover:fill-primary" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableCellActions, TableHeader } from "@/types/props";
import { onMounted, type Ref, ref, watch } from "vue";
import RoleCreateModal from "@/shared/Modals/RoleCreateModal.vue";
import { useRoleStore } from "@/stores/role";
import { useRouter } from "vue-router";
import PencilWithoutPaperIcon from "@/icons/PencilWithoutPaperIcon.vue";
import RoleEditModal from "@/shared/Modals/RoleEditModal.vue";
import type { getRoleResponse } from "@/types/responses";
import RoleAddModal from "@/shared/Modals/RoleAddModal.vue";
import AddUserIcon from "@/icons/AddUserIcon.vue";
import { useUserStore } from "@/stores/user";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue"
import type {Role} from "@/types/entities";

const router = useRouter()
const roleStore = useRoleStore()
const userStore = useUserStore()
const api = useApi()
const data = ref<Record<string, AlphaNumerical>[]>()
getData()

const roleMap = ref<Map<number, Role>>(new Map())

function getData() {
  const rows: Record<string, AlphaNumerical>[] = []
  api.user.roles()
    .then(response => {
      roleMap.value.clear()
      for (const role of response.roles) {
        roleMap.value.set(role.id, role)

        const parentName = role.parent_id ? response.roles.find((item) => item.id === role.parent_id)?.name : null;
        rows.push({
          id: role.id,
          formattedId: `<span class="text-body">${role.id}</span>`,
          name: `<span style="cursor: pointer" class="text-primary">${role.name}</span>`,
          parentName: parentName ? `<span style="cursor: pointer" class="text-primary">${parentName}</span>` : '',
          userCount: role.userCount,
          role_id: role.id,
          parent_id: role.parent_id || '',
          placeholder: role.name,
        })
      }

      data.value = rows

      roleStore.setLoadedRoles(response.roles)
    })
}

const isCreateModalVisible = ref(false)
const isEditModalVisible = ref(false)
const editModalRole: Ref<getRoleResponse | null> = ref(null)
const isAddModalVisible = ref(false)
const addModalId: Ref<number | null> = ref(null)
const actions = ref<TableAction[]>();

watch(isEditModalVisible, isVisible => {
  if (!isVisible) editModalRole.value = null
})

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Название', sortKey: 'name' },
  { name: 'Родительская роль', sortKey: 'parentName' },
  { name: 'Пользователей', sortKey: 'userCount' },
]

const notDefaultOrAdmin = (id: number) => ![1, 2].includes(id)

const cellActions: TableCellActions = {
  openRole: {
    action: (role_id: AlphaNumerical) => { router.push(`/roles/${role_id}`) },
    parameter: 'role_id',
    column: 'name'
  },
  openParent: {
    action: (parent_id: AlphaNumerical) => { router.push(`/roles/${parent_id}`) },
    parameter: 'parent_id',
    column: 'parentName'
  }
}

function openEditModal(id: AlphaNumerical) {
  api.user.role(Number(id))
    .then(response => {
      editModalRole.value = response
      isEditModalVisible.value = true
    })
}

function openAddModal(id: AlphaNumerical) {
  addModalId.value = Number(id)
  isAddModalVisible.value = true
}
const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.user.deleteRole(id).then((res) => {
    getData()
  })
}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => openAddModal(id),
      visible: (id: number) => {
        if (id === 2) return userStore.isSuperAdmin()

        const role = <Role>roleMap.value.get(id)
        return role.can_edit
      },
      parameter: 'id',
      title: 'Добавить пользователей',
      icon: document.getElementById('addUserIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => openEditModal(id),
      visible: (id: number) => {
        const role = <Role>roleMap.value.get(id)
        return notDefaultOrAdmin(id) && role.can_edit
      },
      parameter: 'id',
      title: 'Редактирование роли',
      icon: document.getElementById('pencilIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        const row = data.value?.find((row) => row.id == id);
        deleteModalContent.value = {
          title: 'Удаление роли',
          modalText: `Вы действительно хотите удалить роль: <b>${row?.placeholder}</b>?<br><br>Все пользователи с этой ролью получать роль <b>Сотрудник</b> с доступом только в разделы с персональными данными.`,
        }
      },
      visible: (id: number) => {
        const role = <Role>roleMap.value.get(id)
        return notDefaultOrAdmin(id) && role.can_delete
      },
      parameter: 'id',
      title: 'Удаление роли',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ]
})
</script>
