<template>
  <Card>
    <template #title>Базовые настройки</template>

    <div class="mb-5.5 flex flex-col gap-5.5">
      <TextArea
          v-model="form.description"
          label="Описание"
          parameter="description"
          class="w-full"
          :row-count="3"
          :resizable="false"
          :errors="errors.description"
      />

      <GeoSelect
          v-model="form.geo"
          label="Гео"
          parameter="geo"
          :options="geoOptions"
          placeholder="Выберите GEO"
          :errors="errors.geo"
      />

      <div>
        <SelectInput
            v-model="form.source"
            label="Источник"
            parameter="source"
            :options="sourceOptions"
            placeholder="Выберите источник"
            :errors="errors.source"
        />

        <p class="mt-2">Топы источника: {{ sourceCount }}</p>
      </div>

      <SelectInput
          v-model="form.utm_source"
          label="UTM Source"
          parameter="utm_source"
          :options="utmSourceOptions"
          :placeholder="form.source ? 'Выберите UTM Source' : 'Сначала выберите источник'"
          :errors="errors.utm_source"
      />

      <GeoSelect
          v-model="form.locale"
          label="Локализация"
          parameter="locale"
          :options="localeOptions"
          placeholder="Выберите локализацию"
          :errors="errors.locale"
      />

      <div class="flex gap-4.5">
        <StandardButton @click="submit">{{ buttonText }}</StandardButton>
      </div>

    </div>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import TextArea from "@/shared/Inputs/TextArea.vue";
import GeoSelect from "@/shared/Inputs/GeoSelect.vue";
import type {ManualTopForm, SelectOption} from "@/types/props";
import type {ModelRef} from "vue";

const props = defineProps<{
  errors: Record<keyof(ManualTopForm), string[]>
  geoOptions: SelectOption[]
  localeOptions: SelectOption[]
  sourceOptions: SelectOption[]
  utmSourceOptions: SelectOption[]
  sourceCount: number
  buttonText: string
}>()

const form: ModelRef<ManualTopForm> = defineModel({ required: true })

const emit = defineEmits(['form:submit'])

function submit() {
  emit('form:submit')
}

</script>
