const routes = {
    user: {
        get: 'auth',
        refresh: 'refreshToken',
        login: 'login',
        users: 'users',
        permissions: 'permissions',
        roles: 'roles',
        permissiongGroups: 'permissionGroups',
        employees: 'employees'
    },
    rotation: {
        predict: 'get_prediction',
        options: 'get_options',
        cities: 'get_cities',
        siteIds: 'get_site_id_options',
        blockIds: 'get_block_id_options'
    },
    sources: {
        sources: 'news_sources',
        templates: 'campaign_settings_templates',
        accounts: 'accounts',
        options: 'options/edit-account'
    },
    products: {
        creatives: 'creatives',
        activate:'activate-creative',
        deactivate:'deactivate-creative',
        productTableOptions:'options/edit-creative',
        productCreateOptions:'options/edit-creative',
        streamsList: 'search/streams-for-creatives',
        manualTops: 'manual-tops',
        link:'link',
        themes:'themes',
        streams: 'streams',
    },
    notifications: {
        notifications: 'http://krakend.local/push-api/push/notifications',
        geo: 'geo',
    },
    teasers: {
        teasers: 'teasers',
    },
    spy: {
        list: 'external-creatives',
        options: 'external-creatives/list-options'
    },
    domains: {
        domains: 'domains',
        options: 'options/domains-create',
        rotations: 'domain_rotations',
        next: 'domain_rotations-next'
    }
}

export default routes
