<template>
  <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_701_1286)">
      <path fill-rule="evenodd" d="M16.5907 6.2156H14.9032V2.92498C14.9032 1.96873 14.1439 1.20935 13.1876 1.20935H3.90636C2.95011 1.20935 2.19074 1.96873 2.19074 2.92498V7.84685C2.02199 7.98748 1.88136 8.18435 1.79699 8.43748L0.756365 13.5C0.700115 13.7812 0.78449 14.0906 0.981365 14.3156C1.15011 14.5406 1.43136 14.6531 1.71261 14.6531H10.7689V15.6375C10.7689 16.2844 11.3032 16.8187 11.9501 16.8187H16.5907C17.2376 16.8187 17.772 16.2844 17.772 15.6375V7.39685C17.772 6.74998 17.2376 6.2156 16.5907 6.2156ZM2.07824 13.3875L3.00636 8.9156H3.14699H10.797V13.3875H2.07824ZM10.7689 7.39685V7.64998H3.45636V2.92498C3.45636 2.67185 3.65324 2.47498 3.90636 2.47498H13.1876C13.4407 2.47498 13.6376 2.67185 13.6376 2.92498V6.2156H11.9501C11.3032 6.2156 10.7689 6.74998 10.7689 7.39685ZM16.5064 15.525H12.0345V7.48123H16.5064V15.525Z"/>
      <path fill-rule="evenodd" d="M13.6376 9.6469H15.0438C15.3813 9.6469 15.6907 9.36565 15.6907 9.00002C15.6907 8.66252 15.4095 8.35315 15.0438 8.35315H13.6376C13.3001 8.35315 12.9907 8.6344 12.9907 9.00002C12.9907 9.33752 13.272 9.6469 13.6376 9.6469Z"/>
      <path fill-rule="evenodd" d="M14.2846 14.0062C14.2564 14.0062 14.2002 14.0343 14.1721 14.0343C14.1439 14.0624 14.0877 14.0624 14.0596 14.0906C14.0314 14.1187 14.0033 14.1468 13.9752 14.1749C13.8627 14.2874 13.7783 14.4562 13.7783 14.6249C13.7783 14.7937 13.8346 14.9624 13.9752 15.0749C14.0033 15.1031 14.0314 15.1312 14.0596 15.1593C14.0877 15.1874 14.1439 15.2156 14.1721 15.2156C14.2002 15.2437 14.2564 15.2437 14.2846 15.2437C14.3127 15.2437 14.3689 15.2437 14.3971 15.2437C14.5658 15.2437 14.7346 15.1874 14.8471 15.0468C14.9596 14.9343 15.0439 14.7656 15.0439 14.5968C15.0439 14.4281 14.9877 14.2593 14.8471 14.1468C14.7064 14.0343 14.4814 13.9781 14.2846 14.0062Z"/>
    </g>
    <defs>
      <clipPath id="clip0_701_1286">
        <rect width="18" height="18" fill="white" transform="translate(0.25)"/>
      </clipPath>
    </defs>
  </svg>
</template>
