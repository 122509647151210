<template>
  <AuthLayout>
    <StandardViewContainer>
      <PageTitle :breadcrumbs="{ url: '/streams', prev: 'Потоки', current: 'Редактирование потока' }">
        Редактирование потока
      </PageTitle>

      <StreamUpdateForm v-if="data" :stream="data"/>

    </StandardViewContainer>
  </AuthLayout>
</template>

<script setup lang="ts">
 
import PageTitle from "@/shared/PageTitle.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import StandardViewContainer from "@/shared/Containers/StandardViewContainer.vue";
import StreamUpdateForm from "@/shared/Forms/StreamUpdateForm.vue";
import { useRoute, useRouter } from "vue-router";
import { useApi } from "@/stores/api";
import type { Stream } from "@/types/entities";
import { ref } from "vue";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";


const route = useRoute()
const router = useRouter()
const api = useApi()

const data = ref<Stream>()
getData()

function getData() {
  api.products.stream(Number(route.params.id))
    .then(({stream}) => {
      data.value = stream
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}
</script>
