<template>
  <Card class="w-full sm:w-1/2">
    <template #title>Базовые настройки</template>

    <div class="mb-5.5 flex flex-col gap-5.5">
      <TextInput v-model="form.name" label="Название" placeholder="Название" parameter="name" class="w-full"
        :errors="errors.name">
      </TextInput>

      <TextInput v-model="form.url" label="Ссылка на личный кабинет" placeholder="Ссылка на личный кабинет"
        parameter="url" class="w-full" :errors="errors.url" />

        <SearchMultiSelect v-model="form.campaign_settings_templates" parameter="campaign_settings_templates"
        :options="templatesOptions" placeholder="Выберите из списка" label="Шаблон настроек кампаний" class="w-full"
        :errors="errors.campaign_settings_templates" />
    </div>

    <div class="flex gap-4.5">
      <RouterLink to="/sources">
        <ClearButton>Отмена</ClearButton>
      </RouterLink>

      <StandardButton @click="submit">Сохранить</StandardButton>
    </div>
  </Card>
</template>

<script setup lang="ts">
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { type Ref, ref, toRaw } from "vue";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import type { AlphaNumericalRecord, SourceData } from "@/types/props";
import useSourceForm from "@/composables/useSourceForm";

const props = defineProps<{
  source: SourceData
}>()

const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()
const templatesOptions: Ref<AlphaNumericalRecord> = ref({})

  api.sources.templates()
  .then(response => {
    let options: AlphaNumericalRecord = {}
    for (let template of response.campaignSettingsTemplates) {
      options[Number(template.id)] = template.name;
    }

    templatesOptions.value = options
  })

const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors,
  set
} = useSourceForm()

set(props.source)

function submit() {
  clearErrors()
  if (!validateForm() || !userStore.user) return

  api.sources.sourceUpdate(props.source.id, toRaw({ ...form,  utm_source: userStore.user?.id }))
    .then(response => {
      alertStore.flash('success', 'Источник успешно изменен')
      router.push('/sources')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
