<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
        <PageTitle :breadcrumbs="{ url: '/users', prev: 'Пользователи', current: 'Карточка' }">
          Карточка пользователя
        </PageTitle>

        <div class="grid grid-cols-5 gap-8">
          <div class="col-span-5 xl:col-span-3">
            <UserInfoCard v-if="user" :user="user" :role-name="roleName" :can-edit="canEdit"/>
          </div>

          <div class="col-span-5 xl:col-span-2 flex flex-col gap-8">
            <RoleInfoCard v-if="permissions" :permissions="permissions" :role-name="roleName" :id="roleId"/>
            <LastLoginInfoCard v-if="lastLogin" :last-login="lastLogin"/>
          </div>
        </div>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import PageTitle from "@/shared/PageTitle.vue";
import UserInfoCard from "@/shared/Cards/UserInfoCard.vue";
import RoleInfoCard from "@/shared/Cards/RoleInfoCard.vue";
import LastLoginInfoCard from "@/shared/Cards/LastLoginInfoCard.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import useUserInfo from "@/composables/useUserInfo";
import {useUserStore} from "@/stores/user";
import {onBeforeMount} from "vue";

const {
  user,
  roleId,
  roleName,
  permissions,
  lastLogin,
  canEdit
} = useUserInfo()

const userStore = useUserStore()
onBeforeMount(() => userStore.guardMany([
    {section:'users', permission: 'read'},
    {section:'users', permission: 'personnel_read'}
]))

</script>
