<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
        <PageTitle :breadcrumbs="{ url: '/users', prev: 'Пользователи', current: 'Новый пользователь' }">
          Новый пользователь
        </PageTitle>

        <UserCreateCard/>
      </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import UserCreateCard from "@/shared/Cards/UserCreateCard.vue";
import {useUserStore} from "@/stores/user";
import {onBeforeMount} from "vue";

const userStore = useUserStore()
onBeforeMount(() => userStore.guard('users', 'create'))

</script>
