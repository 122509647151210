<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['id', 'name', 'utm_source', 'url']" :search-fields="['id', 'name', 'utm_source', 'url']"
        :actions="actions" :per-page="5" :hide-actions-title="true">
        <RouterLink to="/sources/add">
          <StandardButton class="items-center">
            <PlusIcon class="fill-current mr-2" />Добавить источник
          </StandardButton>
        </RouterLink>
      </StandardTable>
    </Card>
  </div>
  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
     @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <PlusIcon id="plusIcon" class="fill-current transition hover:fill-primary" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
    <PencilWithoutPaperIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableHeader, TableRow } from "@/types/props";
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue";
import PencilWithoutPaperIcon from "@/icons/PencilWithoutPaperIcon.vue";
import router from "@/router";
import type { UserListItem, Source } from "@/types/entities";

const api = useApi()
const data = ref<Record<string, AlphaNumerical>[]>()
const userMap = ref<Map<number, UserListItem>>(new Map())
getData()

function getData() {
  const rows: TableRow[] = []

  api.sources.sources()
    .then(response => {
      for(let source of response.newsSources){
        rows.push({
          id: source.id,
          name: `<span class="text-primary">${source.name}</span>`,
          utm_source: `<span class="text-primary">${0}</span>`,
          url: `<a href="${source.url}" class="text-primary" target="_blank">${source.url}</a>`
        })
      }

      data.value = rows
    })
}


const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Название', sortKey: 'name' },
  { name: 'utm_source', sortKey: 'utm_source' },
  { name: 'Личный кабинет', }
];

const actions = ref<TableAction[]>();

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.sources.sourceDelete(id).then((res) => getData())
}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        const route = router.resolve(`/sources/${id}`)
        window.open(route.href, '_blank')
      },
      visible: (id: number) => true,
      parameter: 'id',
      title: 'Редактировать источник',
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id
        isDeleteModalVisible.value = true
        deleteModalContent.value = {
          title: 'Удалить источник',
          modalText: 'Уверены, что хотите удалить источник?',
        }
      },
      visible: (id: number) => true,
      parameter: 'id',
      title: 'Удалить источник',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => router.push(`/sources/`),
      visible: () => true,
      parameter: 'id',
      title: 'Добавить utm_source',
      icon: document.getElementById("plusIcon")?.outerHTML ?? "",
    }
  ];
});

</script>
