import useRequestHandler from "@/composables/api/useRequestHandler";
import rotationEmulatorClient from "@/api/rotationEmulatorClient";
import {ref} from "vue";
import type {BlockIdsRequest, CitiesRequest, RotationRequest, SiteIdsRequest} from "@/types/requests";
import type {
    DataArrayResponse,
    DataIdNameResponse,
    getRotationOptionsResponse,
    RotationPredictionResponse
} from "@/types/responses";
import routes from "@/api/routes";
import {Method} from "@/enums/Method";

export default function useRotationEmulatorApi() {
    const { handleRequest } = useRequestHandler(rotationEmulatorClient, 'rotation emulator')

    const rotationEmulator = ref({
        predict: (request: RotationRequest) => <Promise<RotationPredictionResponse>>handleRequest(routes.rotation.predict, Method.GET, {}, request),
        options: () => <Promise<getRotationOptionsResponse>>handleRequest(routes.rotation.options),
        cities: (request: CitiesRequest) => <Promise<DataIdNameResponse>>handleRequest(routes.rotation.cities, Method.GET, {}, request),
        siteIds: (request: SiteIdsRequest) => <Promise<DataArrayResponse>>handleRequest(routes.rotation.siteIds, Method.GET, {}, request),
        blockIds: (request: BlockIdsRequest) => <Promise<DataArrayResponse>>handleRequest(routes.rotation.blockIds, Method.GET, {}, request)
    })

    return {
        rotationEmulator
    }
}
