<template>
  <div>
    <label v-if="label" :for="parameter" class="mb-3 block font-medium text-black dark:text-white">
      {{ label }}
    </label>
    <div class="relative">
      <span v-if="slots.default" class="absolute left-4.5 top-4">
        <slot/>
      </span>
      <span v-if="isPassword && eyeIcon" @click="inputVisible = !inputVisible" class="absolute right-4.5 top-3 cursor-pointer">
        <EyeOpenedIcon v-if="inputVisible" class="hover:fill-black fill-stroke transition"/>
        <EyeClosedIcon v-else class="hover:fill-black fill-stroke transition"/>
      </span>
      <input
          v-model="inputModel"
          :class="{ 'pl-11.5 pr-4.5': slots.default, 'px-4.5': !slots.default, 'border-red': errors && errors.length, 'border-stroke': errors === undefined || !errors.length }"
          :disabled="disabled"
          class="w-full rounded border disabled:bg-gray py-3 font-medium text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          :type="isPassword && !inputVisible ? 'password' : 'text'"
          :name="parameter"
          :id="parameter"
          :placeholder="placeholder"
          @keypress="numbersOnly && isNumber($event)"
      />
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import {type Ref, ref, useSlots} from 'vue'
import EyeClosedIcon from "@/icons/EyeClosedIcon.vue";
import EyeOpenedIcon from "@/icons/EyeOpenedIcon.vue";
import type {AlphaNumerical} from "@/types/props";

function isNumber(event: KeyboardEvent) {
  if (!/\d/.test(event.key)) return event.preventDefault();
}

defineProps<{
  parameter: string
  label?: string
  placeholder?: string
  disabled?: boolean
  isPassword?: boolean
  numbersOnly?: boolean
  eyeIcon?: boolean
  errors?: string[]
}>()

const inputModel: Ref<AlphaNumerical> = defineModel({ required: true })

const slots = useSlots()
const inputVisible = ref(false)
</script>
