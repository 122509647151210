<template>
  <div>
    <label class="flex cursor-pointer select-none items-center" :class="{ '!cursor-not-allowed': disabled }">
      <div class="relative">
        <input v-if="isCheckedModel !== undefined" v-model="isCheckedModel" :value="isChecked" type="checkbox"
          class="sr-only" :disabled="disabled" />
        <input v-if="isCheckedModel === undefined" :value="isChecked" type="checkbox" class="sr-only"
          :disabled="disabled" />

        <div :class="{ 'border-primary dark:bg-transparent': isCheckedModel || isChecked, '!border-black': disabled }"
          class="mr-2 flex h-5 w-5 items-center justify-center rounded border bg-gray">
          <span :class="{ '!opacity-100': isCheckedModel || isChecked }" class="opacity-0 relative ">
            <CheckmarkIcon v-if="!fillBox" width="11" height="8" class="fill-primary"
              :class="{ '!fill-black': disabled }" />
            <StopIcon v-if="fillBox" width="15" height="15" class="fill-primary" :class="{ '!fill-black': disabled }" />
          </span>
        </div>
      </div>
      <span v-if="label" class="text-black text-sm" :class="{ '!text-body': disabled }">{{ label }}</span>
    </label>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import CheckmarkIcon from "@/icons/CheckmarkIcon.vue";
import StopIcon from "@/icons/StopIcon.vue";

defineProps<{
  label?: string
  disabled?: boolean
  isChecked?: boolean
  fillBox?: boolean
}>()

const isCheckedModel: Ref<boolean | undefined> = defineModel({ required: false })

</script>
