<template>
  <div>
    <label class="mb-2.5 block font-medium text-black dark:text-white">{{ label }}</label>
    <div class="relative">
      <input
          v-model="inputModel"
          :type="type"
          :placeholder="placeholder || label"
          :disabled="disabled"
          class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          :class="{ 'border-red': errors && errors.length }"
      />
      <span v-if="slots.default" class="absolute right-4 top-4">
        <slot/>
      </span>
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import {type Ref, useSlots} from 'vue'
import type {AlphaNumerical} from "@/types/props";

defineProps<{
  label: string
  modelValue: AlphaNumerical
  type: 'email' | 'text' | 'password'
  placeholder: string
  disabled?: boolean
  errors?: string[]
}>();

const inputModel: Ref<AlphaNumerical> = defineModel({ required: true })

const slots = useSlots()

</script>
