<template>
  <StandardModal v-model="visible" @close:self="closeSelf" title="Добавление ротации">
    <div class="flex flex-col gap-4">
      <TextInput
        v-model="form.name"
        parameter="name"
        label="Название"
        placeholder="Название"
        :errors="errors.name"
      />

      <SelectInput
          v-model="form.type"
          label="Тип ротации"
          :options="rotationTypeOptions"
          parameter="rotation_type"
          placeholder="Выберите тип ротации"
          :errors="errors.type"
      />

      <StandardButton @click="submit" class="w-40">Добавить</StandardButton>
    </div>
  </StandardModal>
</template>

<script setup lang="ts">
import StandardModal from "@/shared/Modals/StandardModal.vue";
import {reactive, type Ref, toRaw} from "vue";
import type {SelectOption} from "@/types/props";
import TextInput from "@/shared/Inputs/TextInput.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import {useApi} from "@/stores/api";
import Validator from "validatorjs";
import {useAlerts} from "@/stores/alert";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import type {StoreDomainRotationRequest} from "@/types/requests";

const visible: Ref<boolean> = defineModel({ required: true })

const api = useApi()
const alerts = useAlerts()
const emit = defineEmits(['form:submit'])

const form = reactive({
  name: '',
  type: ''
})

const errors: Record<string, string[]> = reactive({
  name: [],
  type: []
})

const rotationTypeOptions: SelectOption[] = [
  { key: 'preland', value: 'Преленд' },
  { key: 'land', value: 'Ленд' }
]

function closeSelf() {
  visible.value = false
}

function submit() {
  clearErrors()
  if (!validateForm()) return

  const request = <StoreDomainRotationRequest>toRaw(form)

  api.domains.storeRotation(request)
    .then(response => {
      alerts.flash('success', 'Ротация успешно создана')
      closeSelf()
      clearForm()
      emit('form:submit')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

function validateForm(): boolean {
  const validator = new Validator(toRaw(form), {
    name: 'required|string|max:255',
    type: 'required|string'
  })

  const passes = Boolean(validator.passes())

  if (!passes) {
    errors.name = validator.errors.get('name')
  }

  return passes
}

function clearForm() {
  form.name = ''
  form.type = ''
}

function clearErrors() {
  errors.name = []
  errors.type = []
}

function populateErrors(err: UserServiceValidationError): void {
  for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
    if (errors.hasOwnProperty(field)) {
      errors[field] = validationErrors
    }
  }
}

</script>
