<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible" class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-[625px] rounded-lg bg-white dark:bg-boxdark max-h-full overflow-y-auto">

          <div class="flex w-full justify-between border-b border-stroke p-8.5">
            <h3 class="font-inter font-bold text-2xl text-black">Добавление пользователя</h3>
            <CloseButton @click="closeSelf" />
          </div>

          <div class="p-8.5 !pb-6">
            <UserSelector
                v-model="selectedUserIds"
                :options="userOptions"
                :errors="errors"
                :need_confirm="true"
                :userList="userList"
            />

            <div class="flex justify-end gap-3 mt-8.5">
              <ClearButton @click="closeSelf" class="!py-[13px] text-primary">Отмена</ClearButton>
              <StandardButton @click="submit" class="!py-[13px]">Сохранить</StandardButton>
            </div>
          </div>

        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {ref, type Ref, watch} from "vue";
import CloseButton from "@/shared/Buttons/CloseButton.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import type {SelectOption} from "@/types/props";
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import UserSelector from "@/shared/Inputs/UserSelector.vue";
import {useUserStore} from "@/stores/user";
import type {UserList} from "@/types/entities";

const props = defineProps<{
  roleId: number | null
}>()

const api = useApi()
const alerts = useAlerts()
const userStore = useUserStore()

const visible: Ref<boolean> = defineModel({ required: true })
const emit = defineEmits(['refresh:table'])

const selectedUserIds: Ref<number[]> = ref([])
const userOptions: Ref<SelectOption[]> = ref([])
const errors: Ref<string[]> = ref([])
const userList: Ref<UserList> = ref([])

const employees: SelectOption[] = []
api.user.employees()
    .then(response => {
      for (const user of response.employees) {
        employees.push({key: user.id, value: user.name})
      }
    })


getUsers(props.roleId)

watch(() => props.roleId, roleId => getUsers(roleId))

function getUsers(roleId: number | null) {
  if (roleId === null) return
  api.user.users()
    .then(response => {
      const options: SelectOption[] = []
      userList.value = response.users
      for (const user of response.users) {
        if (user.role_id === 1) continue
        if (user.role_id === roleId || user.id === 1) continue
        if (user.role_id === 2 && !userStore.isSuperAdmin()) continue
        options.push({ key: user.id, value: user.name })
      }

      userOptions.value = []
      userOptions.value.push(...employees)
      userOptions.value.push(...options)
    })
}

function closeSelf(): void {
  selectedUserIds.value = []
  errors.value = []
  visible.value = false
}

function submit() {
  errors.value = []
  if (props.roleId === null || !validate()) return

  api.user.addRoleUsers(props.roleId, { users: selectedUserIds.value })
      .then(response => {
        alerts.flash('success', 'Пользователи успешно добавлены')
        closeSelf()
        emit('refresh:table')
      })
      .catch(err => {
        if (err instanceof UserServiceValidationError && err.validationErrors.hasOwnProperty('users')) {
          errors.value = err.validationErrors.users
        }
      })
}

function validate(): boolean {
  if (!selectedUserIds.value.length) {
    errors.value.push('Чтобы сохранить, выберите хотя бы одного пользователя')
  }

  return !errors.value.length
}

</script>
