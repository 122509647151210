<template>
  <Card no-padding>
    <StandardTable
        v-if="data"
        @refresh:rows="getData"
        :headers="headers"
        :column-order="['domain', 'rotation', 'registrar', 'dns_provider', 'status', 'created_at']"
        :rows="data"
        :per-page="20"
        :row-count="rowsCount"
        serverside-pagination
        serverside-sort
        title="Общий список доменов"
    >
      <StandardButton @click="isDomainModalVisible = true">Добавить домены</StandardButton>
    </StandardTable>
  </Card>

  <DomainAddModal
      v-model="isDomainModalVisible"
      @form:submit="getData"
  />
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import type { AlphaNumerical, TableHeader } from "@/types/props";
import {ref, watch} from "vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import DomainAddModal from "@/shared/Modals/DomainAddModal.vue";
import {useRoute} from "vue-router";
import type {ListRequest} from "@/types/requests";
import {useApi} from "@/stores/api";
import moment from "moment";
import domainStatus from "@/dictionaries/domainStatus";

const data = ref<Record<string, AlphaNumerical>[]>([])
const rowsCount = ref(0)
const route = useRoute()
const api = useApi()

const headers: TableHeader[] = [
  { name: 'Домен', sortKey: 'name' },
  { name: 'Ротация', sortKey: 'rotation_id' },
  { name: 'Namecheap account', sortKey: 'registrar_id' },
  { name: 'Cloudflare account', sortKey: 'dns_provider_id' },
  { name: 'Статус', sortKey: 'status' },
  { name: 'Добавлен', sortKey: 'created_at' }
]

const isDomainModalVisible = ref(false)

watch(() => route.fullPath, getData)

getData()

function getData() {
  const { sort, order, page } = route.query
  const rows: Record<string, AlphaNumerical>[] = []

  const request: ListRequest = {
    page: page ? Number(page) : 1,
    sort: sort ? String(sort) : undefined,
    sort_type: order ? String(order) : undefined
  }

  api.domains.list(request).then(({domains, count}) => {
    domains.forEach(domain => rows.push({
      domain: domain.name,
      rotation: domain.rotation.name,
      registrar: domain.registrar.name,
      dns_provider: domain.dns_provider.name,
      status: domainStatus[domain.status] ?? 'UNKNOWN',
      created_at: moment(domain.created_at).format('DD.MM.YYYY')
    }))
    data.value = rows
    rowsCount.value = count
  })
}
</script>
