<template>
  <draggable
      v-model="teasers"
      class="flex flex-col w-full border border-stroke rounded border-b-0"
      item-key="id"
      ghost-class="cursor-grabbing"
  >
    <template #item="{ element }">
      <ManualTopTeaserListItem
          @teaser:remove="removeTeaserFromList"
          :index="teasers.indexOf(element)"
          :teaser="element"
      />
    </template>
  </draggable>
</template>

<script setup lang="ts">
import draggable from "vuedraggable";

import type {Ref} from "vue";
import type {ManualTopTeaser} from "@/types/props";
import ManualTopTeaserListItem from "@/shared/Lists/ManualTopTeaserListItem.vue";

const teasers: Ref<ManualTopTeaser[]> = defineModel({ required: true })

function removeTeaserFromList(index: number) {
  if (teasers.value.hasOwnProperty(index)) {
    teasers.value.splice(index, 1)
  }
}
</script>
