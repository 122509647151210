<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_701_1264)">
      <path fill-rule="evenodd" d="M8.97168 5.17505C10.1529 5.17505 11.0811 4.2188 11.0811 3.06567C11.0811 1.91255 10.1248 0.956299 8.97168 0.956299C7.81855 0.956299 6.8623 1.91255 6.8623 3.06567C6.8623 4.2188 7.79043 5.17505 8.97168 5.17505ZM8.97168 2.1938C9.4498 2.1938 9.81543 2.58755 9.81543 3.03755C9.81543 3.48755 9.42168 3.8813 8.97168 3.8813C8.52168 3.8813 8.12793 3.48755 8.12793 3.03755C8.12793 2.58755 8.49355 2.1938 8.97168 2.1938Z"/>
      <path fill-rule="evenodd" d="M6.63746 8.01572C7.22809 7.36885 8.09996 6.9751 8.99996 6.9751C9.89996 6.9751 10.7718 7.34072 11.3625 8.01572C11.475 8.15635 11.6437 8.2126 11.8406 8.2126C11.9812 8.2126 12.15 8.15635 12.2625 8.04385C12.5156 7.81885 12.5437 7.39697 12.2906 7.14385C11.4468 6.24385 10.2375 5.70947 8.99996 5.70947C7.76246 5.70947 6.55308 6.24385 5.70933 7.14385C5.48433 7.39697 5.48433 7.79072 5.73746 8.04385C5.99058 8.29697 6.38434 8.26885 6.63746 8.01572Z"/>
      <path fill-rule="evenodd" d="M3.76867 14.0907C4.94992 14.0907 5.87805 13.1344 5.87805 11.9813C5.87805 10.8001 4.9218 9.87195 3.76867 9.87195C2.61555 9.87195 1.6593 10.8282 1.6593 11.9813C1.63117 13.1626 2.58742 14.0907 3.76867 14.0907ZM3.76867 11.1094C4.2468 11.1094 4.61242 11.5032 4.61242 11.9532C4.61242 12.4313 4.21867 12.7969 3.76867 12.7969C3.31867 12.7969 2.92492 12.4032 2.92492 11.9532C2.8968 11.5032 3.29055 11.1094 3.76867 11.1094Z"/>
      <path fill-rule="evenodd" d="M3.68429 14.5688C2.44679 14.5688 1.23741 15.1032 0.393661 16.0032C0.168661 16.2563 0.168661 16.6501 0.421786 16.9032C0.674911 17.1563 1.06866 17.1282 1.32179 16.8751C1.91241 16.2282 2.78429 15.8345 3.68429 15.8345C4.58429 15.8345 5.45616 16.2001 6.04679 16.8751C6.15929 17.0157 6.32804 17.072 6.52491 17.072C6.66554 17.072 6.83429 17.0157 6.94679 16.9032C7.19991 16.6782 7.22804 16.2563 6.97491 16.0032C6.13116 15.1032 4.92179 14.5688 3.68429 14.5688Z"/>
      <path fill-rule="evenodd" d="M14.1748 14.0907C15.3561 14.0907 16.2842 13.1344 16.2842 11.9813C16.2842 10.8001 15.3279 9.87195 14.1748 9.87195C12.9936 9.87195 12.0654 10.8282 12.0654 11.9813C12.0654 13.1626 13.0217 14.0907 14.1748 14.0907ZM14.1748 11.1094C14.6529 11.1094 15.0186 11.5032 15.0186 11.9532C15.0186 12.4313 14.6248 12.7969 14.1748 12.7969C13.6967 12.7969 13.3311 12.4032 13.3311 11.9532C13.3311 11.5032 13.7248 11.1094 14.1748 11.1094Z"/>
      <path fill-rule="evenodd" d="M17.6343 16.0313C16.7906 15.1313 15.5812 14.5969 14.3437 14.5969C13.1062 14.5969 11.8968 15.1313 11.0531 16.0313C10.8281 16.2844 10.8281 16.6782 11.0812 16.9313C11.3343 17.1563 11.7281 17.1563 11.9812 16.9032C12.5718 16.2563 13.4437 15.8625 14.3437 15.8625C15.2437 15.8625 16.1156 16.2282 16.7062 16.9032C16.8187 17.0438 16.9875 17.1 17.1843 17.1C17.325 17.1 17.4937 17.0438 17.6062 16.9313C17.8312 16.6782 17.8593 16.2844 17.6343 16.0313Z"/>
      <path fill-rule="evenodd" d="M11.0249 11.5031C11.1655 11.1938 11.0249 10.8 10.7155 10.6594L9.61865 10.1531V9.00002C9.61865 8.66252 9.3374 8.35315 8.9999 8.35315C8.6624 8.35315 8.35303 8.6344 8.35303 9.00002V10.125L7.22803 10.6594C6.91865 10.8 6.77803 11.1938 6.91865 11.5031C7.05928 11.8125 7.45303 11.9531 7.7624 11.8125L8.94365 11.25L10.2093 11.8125C10.2937 11.8406 10.378 11.8688 10.4624 11.8688C10.6874 11.8688 10.9124 11.7281 11.0249 11.5031Z"/>
    </g>
    <defs>
      <clipPath id="clip0_701_1264">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
