<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data" :empty-top="true"
        :row-count="itemsCount" :column-order="[
          'ad_id',
          'img',
          'title',
          'description',
          'url',
          'shows',
          'ctr',
          'avg_cpc',
          'max_cpc',
          'min_cpc',
          'avg_cpm'
        ]" :per-page="50" :serversidePagination="true">

        <div>

          <div
            class="flex justify-between sm:flex-row-reverse sm:items-center sm:justify-between gap-4 pt-8 px-7.5 flex-col-reverse flex sm:gap-x-4 sm:gap-y-0 after:hidden">
            <SearchMultiSelect v-model="filtersForm.os" :options="osOptions" placeholder="ОС" parameter="os"
              class="lg:w-[33%] w-full">
            </SearchMultiSelect>
            <SearchMultiSelect v-model="filtersForm.geos" :options="geoOptions" placeholder="Гео" parameter="geo"
              class="lg:w-[33%] w-full">
            </SearchMultiSelect>
            <VueTailwindDatepicker v-if="showDatePicker" :options="dateInputOptions" i18n="ru" class="sm:max-w-70"
              placeholder="Период" :formatter="dateInputFormatter" v-model="filtersForm.created_at"
              :disable-date="disableDates"
              input-classes="text-black relative z-20 w-[100%] appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary disabled:bg-gray">
              <template #inputIcon="{ value }">
                {{ "" }}
              </template>
            </VueTailwindDatepicker>
          </div>

          <div class="flex justify-between datatable-top">
            <SearchMultiSelect v-model="filtersForm.browsers" :options="browserOptions" placeholder="Браузер"
              parameter="browser" class="lg:w-[33%] w-full">
            </SearchMultiSelect>
            <SearchMultiSelect v-model="filtersForm.iab" :options="iabOptions" placeholder="IAB cat/subcat"
              parameter="iab" class="lg:w-[33%] w-full">
            </SearchMultiSelect>
            <SearchMultiSelect v-model="filtersForm.devices" :options="deviceOptions" placeholder="Девайс"
              parameter="device" class="lg:w-[33%] w-full">
            </SearchMultiSelect>

          </div>
        </div>

      </StandardTable>
    </Card>
  </div>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import { reactive, ref, toRaw, watch, type Ref } from "vue";
import type {
  AlphaNumerical,
  AlphaNumericalRecord,
  SelectOption,
  TableHeader,
} from "@/types/props";
import { useApi } from "@/stores/api";
import { useRoute } from "vue-router";
import type { SpyListRequest } from "@/types/requests";
import debounce from "@/helpers/debounce";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import moment from "moment";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";

const geoOptions: Ref<AlphaNumericalRecord> = ref({})
const osOptions: Ref<AlphaNumericalRecord> = ref({})
const deviceOptions: Ref<AlphaNumericalRecord> = ref({})
const browserOptions: Ref<AlphaNumericalRecord> = ref({})
const iabOptions: Ref<AlphaNumericalRecord> = ref({})
const itemsCount = ref<number>(1)

const route = useRoute()

const initialDate = {
  start: moment(new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 6)).format('DD/MM/YYYY'),
  end: moment(new Date()).format('DD/MM/YYYY')
}

const filtersFormInitialState = {
  created_at: initialDate,
  geos: [],
  os: [],
  devices: [],
  browsers: [],
  iab: [],
}

let filtersForm = reactive({ ...filtersFormInitialState })

const data = ref<Record<string, AlphaNumerical>[]>([]);
const api = useApi();


let showDatePicker = ref(true)

const dateInputFormatter = ref({
  date: 'DD/MM/YYYY',
  month: 'MMM',
})

const dateInputOptions = ref({
  shortcuts: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    past: (period: number) => `${period} дней`,
    currentMonth: 'Этот месяц',
    pastMonth: 'Прошлый месяц',
  },
  footer: {
    apply: 'Применить',
    cancel: 'Отмена',
  },
})

function disableDates(date: Date) {
  return date > new Date()
}

watch(
  () => route.fullPath,
  () => getData()
);

watch(filtersForm, () => {
  debounce(getData)()
}, { deep: true }
)

function getOptions() {
  api.spy.options().then(({ geos, os, devices, browsers, iab }) => {
    geoOptions.value = geos
    osOptions.value = os
    deviceOptions.value = devices
    browserOptions.value = browsers
    iabOptions.value = iab
  })
}

function getData() {
  const { sort, order, page } = route.query
  const rows: Record<string, AlphaNumerical>[] = [];
  const fields = toRaw(filtersForm)

  const requestData: SpyListRequest = {
    page: page && Number(page) > 0 ? Number(page) : 1,
    order_by: sort ? String(sort) : undefined,
    order_direction: order ? String(order) : undefined,
    date_from: fields.created_at.start.length ? moment(fields.created_at.start, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss') : undefined,
    date_to: fields.created_at.end.length ? moment(fields.created_at.end, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss') : undefined,
    geos: fields.geos.length ? fields.geos : undefined,
    os: fields.os.length ? fields.os : undefined,
    devices: fields.devices.length ? fields.devices : undefined,
    browsers: fields.browsers.length ? fields.browsers : undefined,
    iab: fields.iab.length ? fields.iab : undefined,
  }

  api.spy.list(requestData).then(({ creatives, count }) => {
    creatives.forEach((item) => rows.push({
      ad_id: item.id,
      img: `<img src="${item.img}" class="w-20 h-20">`,
      title: `<div class="w-50 text-truncate">${item.title}</div>`,
      description: item.description,
      url: item.url,
      shows: item.shows,
      ctr: item.ctr,
      avg_cpc: item.avg_cpc,
      max_cpc: item.max_cpc,
      min_cpc: item.min_cpc,
      avg_cpm: item.avg_cpm
    }))
    data.value = rows
    itemsCount.value = count
  })
}

getOptions()

getData()

const headers: TableHeader[] = [
  { name: "Ad_id", sortKey: "ad_id" },
  { name: "Изображение", },
  { name: "Заголовок", sortKey: "title" },
  { name: "Описание", sortKey: "description" },
  { name: "Ссылка", sortKey: "url" },
  { name: "Показы", sortKey: "shows" },
  { name: "CTR", sortKey: "ctr" },
  { name: "Средний CPC", sortKey: "avg_cpc" },
  { name: "Max CPC", sortKey: "max_cpc" },
  { name: "Min CPC", sortKey: "min_cpc" },
  { name: "Средний CPM", sortKey: "avg_cpm" },
];


</script>
