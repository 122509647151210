<template>
  <div class="relative">
    <label v-if="label" class="mb-3 block text-sm font-medium text-black dark:text-white">
      {{ label }}
    </label>
    <div @click="openDropdownIfNotDisabled"
      :class="{ 'border-primary': isDropdownOpened, 'border-red': errors && errors.length && !isDropdownOpened, 'border-stroke': !isDropdownOpened && (errors === undefined || !errors.length), 'bg-gray': disabled }"
      class="relative z-20 w-full rounded border p-1.5 pr-8 font-medium outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input min-h-[50px] cursor-pointer">
      <div class="flex items-center gap-2 h-full">
        <span v-if="slots.default" class="pt-1 px-2.5 font-normal">
          <slot />
        </span>
        <span v-if="!selectedItem" class="pt-2 px-2.5 font-normal">{{ placeholder }}</span>
        <span v-else class="pt-2 px-2.5 font-normal">{{ itemValue(selectedItem) }}</span>
      </div>
      <span class="absolute top-1/2 right-4 z-10 -translate-y-1/2">
        <CaretInputIcon />
      </span>
    </div>
    <div v-click-outside="closeDropdown"
      :class="{ 'flex border-primary': isDropdownOpened, 'hidden border-stroke': !isDropdownOpened }"
      class="absolute max-h-[180px] overflow-y-scroll w-full flex-col border border-t-0 -mt-[3px] bg-white z-99999 rounded-b">
      <TextInput v-model="search" @input="filterOptions" :parameter="parameter" class="p-2" />
      <p v-for="(option, key) in visibleOptions" @click="selectOption(key)" :id="`option-${parameter}-${key}`"
        class="hover:bg-primary hover:text-white p-2 cursor-pointer"
        :class="{ 'bg-primary text-white': isSelected(key, <AlphaNumerical>option) }">
        {{ option }}
      </p>
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import CaretInputIcon from "@/icons/CaretInputIcon.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import { type Ref, ref, watch, useSlots } from "vue";
import vClickOutside from "@/directives/clickOutside";
import useDropdownToggle from "@/composables/useDropdownToggle";
import type { AlphaNumerical, AlphaNumericalRecord, MultiSelectOptions } from "@/types/props";

const props = defineProps<{
  parameter: string
  options: MultiSelectOptions
  placeholder: string
  label?: string
  disabled?: boolean
  errors?: string[]
}>()
const slots = useSlots()

const selectedItem: Ref<AlphaNumerical> = defineModel({ required: true })

const search = ref('')

const {
  isDropdownOpened,
  openDropdown,
  closeDropdown
} = useDropdownToggle(props.parameter)

function openDropdownIfNotDisabled() {
  if (props.disabled) return
  openDropdown()
}

const visibleOptions = ref(Object.assign({}, props.options))

function filterOptions() {
  const allOptions = Object.assign({}, props.options)
  const visible: AlphaNumericalRecord = {};

  Object.entries(allOptions).forEach(entry => {
    const [key, value] = entry;
    if (value.toString().toLowerCase().indexOf(search.value.toLowerCase()) !== -1) {
      visible[key] = value;
    }
  })

  visibleOptions.value = visible
}

function selectOption(key: keyof MultiSelectOptions) {
  selectedItem.value = Array.isArray(props.options) ? <AlphaNumerical>props.options[key] : key
  closeDropdown()
}

function itemValue(item: AlphaNumerical) {
  return Array.isArray(props.options) ? item : props.options[item]
}

function isSelected(key: AlphaNumerical, option: AlphaNumerical): boolean {
  const needle = Array.isArray(props.options) ? option : key
  return selectedItem.value === needle
}

watch(() => props.options, () => filterOptions())

</script>
