<template>
  <div class="w-full sm:w-2/3">
    <StreamCard v-model="form" :errors="errors" :link-options="linkOptions" :theme-options="themeOptions"
      :geo-options="geoOptions" :links="links" button-text="Сохранить" @form:submit="submit" />
  </div>
</template>

<script setup lang="ts">
import StreamCard from "@/shared/Cards/StreamCard.vue";
import { reactive, ref, toRaw, watch, type Ref } from "vue";
import type { AlphaNumericalRecord, MultiSelectOptions, StreamForm } from "@/types/props";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { useRouter } from "vue-router";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import Validator from "validatorjs";
import { useUserStore } from "@/stores/user";
import type { Stream } from "@/types/entities";



const userStore = useUserStore()

const alertStore = useAlerts()
const router = useRouter()
const api = useApi();

const props = defineProps<{
  stream: Stream
}>()

const form: StreamForm = reactive({
  name: '',
  geo: '',
  theme_id: 0,
  links: []
})





const errors: Record<string, string[]> = reactive({
  name: [],
  geo: [],
  theme_id: [],
  links: []
})


const linkGeo: Ref<string> = ref('')

const geoOptions: Ref<AlphaNumericalRecord> = ref({})

const themeOptions: Ref<MultiSelectOptions> = ref([])

const linkOptions: Ref<MultiSelectOptions> = ref({})
const links: Ref<Record<number, string>> = ref({})


async function getOptions() {
  let linkRecords: Record<number, string> = {}
  let linkOptionsRecords: MultiSelectOptions = {}
  let geoRecords: AlphaNumericalRecord = {}
  let themeRecords: AlphaNumericalRecord = {}
  linkGeo.value = props.stream.geo
  await api.products.streamsOptions().then(({ geo, themes }) => {
    geo.forEach((item) => item.type == 'option' ? geoRecords[item.code] = item.label : item.items?.forEach((option) => geoRecords[option.code] = option.label))
    themes.forEach((item) => themeRecords[item.id] = item.name)
    themeOptions.value = themeRecords
    geoOptions.value = geoRecords
  })
  await api.products.links({ geo: [props.stream.geo] }).then(({ links_by_tag }) => {
    links_by_tag.forEach((group) => group.links.forEach((link) => {
      linkOptionsRecords[link.id] = link.label
      linkRecords[link.id] = link.href
    }))
    links.value = linkRecords
    linkOptions.value = linkOptionsRecords
  })
  set()
}

function set() {
  form.name = props.stream.name
  form.geo = props.stream.geo
  form.theme_id = props.stream.theme_id
  form.links = props.stream.links?.map((item) => ({ id: item.id, probability: item.probability })) || []
}

watch(form, () => {
  let linkRecords: Record<number, string> = {}
  let linkOptionsRecords: MultiSelectOptions = {}
  if (form.geo !== linkGeo.value) {
    linkGeo.value = form.geo
    api.products.links({ geo: [form.geo] }).then(({ links_by_tag }) => {
      links_by_tag.forEach((group) => group.links.forEach((link) => {
        linkOptionsRecords[link.id] = link.label
        linkRecords[link.id] = link.href
      }))
      form.links = form.links.filter((item) => Object.keys(linkRecords).includes(item.id.toString()))

      links.value = linkRecords
      linkOptions.value = linkOptionsRecords
    })
  }
}, { deep: true }
)

getOptions()

function populateErrors(err: UserServiceValidationError): void {
  for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
    if (errors.hasOwnProperty(field)) {
      errors[field] = validationErrors
    }
  }
}

function validateForm(): boolean {

  form.links = form.links.filter((item) => item.id !== 0)

  const validator = new Validator(toRaw(form), {
    name: 'required',
    geo: 'required',
    theme_id: 'not_in:0',
    links: 'required|array|nnv:probability|sum_of_is100:probability'
  })

  const passes = Boolean(validator.passes())

  if (!passes) {
    errors.name = validator.errors.get('name')
    errors.geo = validator.errors.get('geo')
    errors.theme_id = validator.errors.get('theme_id')
    errors.links = validator.errors.get('links')
  }

  return passes
}


function clearErrors() {
  errors.name = []
  errors.geo = []
  errors.theme_id = []
  errors.links = []
}

function submit() {
  clearErrors()
  if (!validateForm() || !userStore.user) return
  api.products.streamUpdate(props.stream.id, form).then(response => {
    alertStore.flash('success', 'Поток успешно изменен')
    router.push('/streams')
  })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
