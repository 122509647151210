<template>
  <div v-if="queue.isLoading" class="fixed left-0 top-0 z-999999 flex h-screen w-screen items-center justify-center bg-semiwhite dark:bg-semidark">
    <div class="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
  </div>
</template>

<script setup lang="ts">
import {useLoadingQueue} from "@/stores/loadingQueue";

const queue = useLoadingQueue()
</script>
