import useRequestHandler from "@/composables/api/useRequestHandler";
import sourcesClient from "@/api/sourcesClient";
import {ref} from "vue";
import type {
    getAccountOptionsResponse,
    getAccountResponse, getAccountsResponse,
    getSourceMapResponse,
    getSourceResponse,
    getSourcesResponse,
    getTemplateResponse,
    getTemplatesResponse
} from "@/types/responses";
import type {AccountRequest, AccountsRequest, SourceRequest, TemplateRequest} from "@/types/requests";
import routes from "@/api/routes";
import {Method} from "@/enums/Method";

export default function useSourceApi() {
    const { handleRequest } = useRequestHandler(sourcesClient, 'sources')

    const sources = ref({
        //sources
        source: (id: number) => <Promise<getSourceResponse>>handleRequest(`${routes.sources.sources}/${id}`),
        sources: () => <Promise<getSourcesResponse>>handleRequest(routes.sources.sources),
        sourceMap: () => <Promise<getSourceMapResponse>>handleRequest(`${routes.sources.sources}/source_map`),
        sourceCreate: (request: SourceRequest) => handleRequest(routes.sources.sources, Method.POST, request),
        sourceUpdate: (id: number, request: SourceRequest) => handleRequest(`${routes.sources.sources}/${id}`, Method.PUT, request),
        sourceDelete: (id: number) => handleRequest(`${routes.sources.sources}/${id}`, Method.DELETE),
        //templates
        template: (id: number) => <Promise<getTemplateResponse>>handleRequest(`${routes.sources.templates}/${id}`),
        templates: () => <Promise<getTemplatesResponse>>handleRequest(routes.sources.templates),
        templateCreate: (request: TemplateRequest) => handleRequest(routes.sources.templates, Method.POST, request),
        templateUpdate: (id: number, request: TemplateRequest) => handleRequest(`${routes.sources.templates}/${id}`, Method.PUT, request),
        templateDelete: (id: number) => handleRequest(`${routes.sources.templates}/${id}`, Method.DELETE),
        //accounts
        account: (id: number) => <Promise<getAccountResponse>>handleRequest(`${routes.sources.accounts}/${id}`),
        accounts: (request: AccountsRequest) => <Promise<getAccountsResponse>>handleRequest(routes.sources.accounts, Method.GET, {}, request),
        accountCreate: (request: AccountRequest) => handleRequest(routes.sources.accounts, Method.POST, request),
        accountUpdate: (id: number, request: AccountRequest) => handleRequest(`${routes.sources.accounts}/${id}`, Method.PUT, request),
        accountDelete: (id: number) => handleRequest(`${routes.sources.accounts}/${id}`, Method.DELETE),
        accountOptions: () => <Promise<getAccountOptionsResponse>>handleRequest(`${routes.sources.options}`)
    })

    return {
        sources
    }
}
