import type {PermissionType} from "@/types/props";

const permissionTypes: Record<PermissionType, string> = {
    create: 'Создание',
    read: 'Просмотр',
    edit: 'Редактирование',
    delete: 'Удаление',
    personnel_delete: 'Удаление своих',
    personnel_edit: 'Редактирование своих',
    personnel_read: 'Просмотр своих'
}

export default permissionTypes
