<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ current: 'Пользователи' }">
        Пользователи
      </PageTitle>

      <UsersTable />
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import UsersTable from "@/shared/Tables/UsersTable.vue";
import {useUserStore} from "@/stores/user";
import {onBeforeMount} from "vue";

const userStore = useUserStore()
onBeforeMount(() => userStore.guardMany([
  {section: 'users', permission: 'read'},
  {section: 'users', permission: 'personnel_read'}
]))


</script>
