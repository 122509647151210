<template>
  <div>
    <Card class="w-full sm:w-2/3">
      <template #title>Базовые настройки</template>

      <div class="mb-5.5 flex flex-col gap-5.5 w-3/4">
        <TextInput v-model="form.utm_source" label="utm_source" placeholder="utm_source" parameter="utm_source"
          class="w-full" :errors="errors.utm_source">
        </TextInput>

        <SelectInput v-model="form.source_id" :options="sourcesOptions" parameter="source_id"
          placeholder="Выберите из списка" label="Источник" :errors="errors.source_id" />

        <SelectInput v-model="form.campaign_settings_template_id" :options="templatesOptions"
          parameter="campaign_settings_template_id" placeholder="Выберите из списка" label="Шаблон настроек кампаний"
          :errors="errors.campaign_settings_template_id" />

        <TextInput v-model="form.login" label="Логин" placeholder="Логин" parameter="login" class="w-full"
          :errors="errors.login">
        </TextInput>
        <TextInput v-model="form.password" label="Пароль" placeholder="Пароль" parameter="password" class="w-full"
          :errors="errors.password">
        </TextInput>
        <div>
          <label class="mb-3 block font-medium text-black dark:text-white">
            Тип аккаунта
          </label>
          <div class="flex w-full mt-5 gap-4">
            <RadioButton v-model="form.is_shared" :label="'Общий'" :value="true" />
            <RadioButton v-model="form.is_shared" :label="'Персональный'" :value="false"
              @change="form.users.length > 1 && clearUsers" />
          </div>
        </div>
      </div>
    </Card>


    <Card class="w-full sm:w-2/3 mt-10">
      <template #title>Пользователи</template>

      <div class="mb-5.5 flex flex-col gap-5.5 w-3/4">
        <UserSelector v-model="form.users" :hide_add="form.users.length > 0 && !form.is_shared" :options="userOptions"
          :errors="errors.users" class="mt-5" />
      </div>

      <div class="flex gap-4.5">
        <RouterLink to="/sources">
          <ClearButton>Отмена</ClearButton>
        </RouterLink>

        <StandardButton @click="submit">Сохранить</StandardButton>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { type Ref, ref, toRaw } from "vue";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import type { SelectOption } from "@/types/props";
import RadioButton from "../Inputs/RadioButton.vue";
import UserSelector from "@/shared/Inputs/UserSelector.vue";
import SelectInput from "../Inputs/SelectInput.vue";
import useAccountForm from "@/composables/useAccountForm";
import idNameRecordsToSelectOptions from "@/helpers/idNameRecordsToSelectOptions";
const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()
const sourcesOptions: Ref<SelectOption[]> = ref([])
const templatesOptions: Ref<SelectOption[]> = ref([])
const userOptions: Ref<SelectOption[]> = ref([])

function clearUsers() {
  form.users = []
}

api.sources.accountOptions()
  .then(response => {
    let uOptions: SelectOption[] = []
    for (const id in response.users) {
      uOptions.push({ key: id, value: response.users[id].name })
    }
    userOptions.value = uOptions
    sourcesOptions.value = idNameRecordsToSelectOptions(response.newsSources)
    templatesOptions.value = idNameRecordsToSelectOptions(response.campaignSettingsTemplates)
  })


const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors,
} = useAccountForm()

function submit() {
  clearErrors()

  if (!validateForm() || !userStore.user) return

  api.sources.accountCreate(toRaw(form))
    .then(response => {
      alertStore.flash('success', 'Аккаунт в источнике успешно создан')
      router.push('/accounts')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
