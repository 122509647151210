import {reactive, toRaw} from "vue";
import type { SourceForm, SourceData } from "@/types/props";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import type { Template } from "@/types/entities";
import Validator from "validatorjs";

export default function useSourceForm() {

    const form = reactive<SourceForm>({
        name: '',
        campaign_settings_templates: [],
        url: '',
    })

    const errors = reactive<Record<string, string[]>>({
        name: [],
        url: [],
        campaign_settings_templates: [],
    })

    function validateForm(): boolean {
        const validator = new Validator(toRaw(form), {
            name: 'required',
            url: 'required|url'
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.name = validator.errors.get('name')
            errors.url = validator.errors.get('url')
        }

        return passes
    }


    function clearErrors() {
        errors.name = []
        errors.url = []
        errors.campaign_settings_templates = []
    }

    function clear() {
        form.name = ''
        form.campaign_settings_templates = []
        form.url = ''
        clearErrors()
    }

    function set(sourceData: SourceData) {
        form.name = sourceData.name
        form.url = sourceData.url
        form.campaign_settings_templates = sourceData.campaign_settings_templates.map((template: Template) => template.id)
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    return {
        form,
        errors,
        validateForm,
        clearErrors,
        set,
        clear,
        populateErrors
    }
}
