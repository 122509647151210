<template>
  <aside
      :class="sidebarToggle ? 'translate-x-0 lg:static' : '-translate-x-full'"
      class="absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark"
  >
    <SidebarHeader/>
    <SidebarMenu/>
  </aside>
</template>

<script setup lang="ts">
import {inject} from "vue";
import SidebarHeader from "@/shared/Sidebar/SidebarHeader.vue";
import SidebarMenu from "@/shared/Sidebar/SidebarMenu.vue";
import type {Ref} from "vue";

const sidebarToggle = inject<Ref<boolean>>('sidebarToggle')

</script>
