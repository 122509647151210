import {defineStore} from "pinia";
import {reactive, ref, watch} from "vue";
import type {PermissionForm, PermissionSection, RoleForm, SelectOption} from "@/types/props";
import {useApi} from "@/stores/api";
import type {RoleUpdateRequest} from "@/types/requests";
import type {getRoleResponse} from "@/types/responses";

export const useRoleForm = defineStore('roleForm', () => {
    const api = useApi()

    const form = reactive<RoleForm>({
        name: '',
        parent_id: null,
        general: {
            read: false,
            personnel_read: false,
            create: false,
            edit: false,
            personnel_edit: false,
            delete: false,
            personnel_delete: false
        },
        sections: []
    })

    const errors = reactive<Record<string, string[]>>({
        name: [],
        parent_id: [],
        permissions: []
    })

    const sectionOptions = ref<SelectOption[]>([])

    const areAccessSettingsVisible = ref(false)
    const areSectionSettingsVisible = ref(false)

    watch(areAccessSettingsVisible, () => {
        if (areAccessSettingsVisible.value) {
            areSectionSettingsVisible.value = false
        } else {
            clearGeneralAccess()
        }
    })

    watch(areSectionSettingsVisible, () => {
        if (areSectionSettingsVisible.value) {
            areAccessSettingsVisible.value = false
        }
    })

    function loadSections() {
        if (sectionOptions.value.length) return

        api.user.permissionGroups()
            .then(response => {
                const options = []

                for (const group of response.permission_groups) {
                    if (group.id !== 1) {
                        options.push({ key: group.code, value: group.name })
                    }
                }

                sectionOptions.value = options
            })
    }

    function clearGeneralAccess(): void {
        form.general = emptyPermissionForm()
    }

    function validate(): boolean {
        if (!form.name.length) {
            errors.name.push('Поле обязательно к заполнению')
        }

        const request = toRequest()
        if (!request.permissions.length) {
            errors.permissions.push('Доступы обязательны к заполнению')
        }

        let hasGeneralPermissions = false
        for (const value of Object.values(form.general)) {
            if (value) {
                hasGeneralPermissions = true
                break
            }
        }

        if (!hasGeneralPermissions && !form.sections.length && areSectionSettingsVisible.value) {
            errors.permissions.push('Добавьте хотя бы один раздел, чтобы сохранить роль')
        }

        return !errors.name.length && !errors.permissions.length
    }

    function toRequest(): RoleUpdateRequest {
        const request: RoleUpdateRequest = {
            name: form.name,
            parent_id: form.parent_id,
            permissions: []
        }

        // general permissions override section permissions
        for (const [permission, value] of Object.entries(form.general)) {
            if (value) {
                request.permissions.push(`general-${permission}`)
            }
        }

        if (!request.permissions.length) {
            for (const section of form.sections) {
                for (const [permission, value] of Object.entries(section.permissions)) {
                    if (value) {
                        request.permissions.push(`${section.key}-${permission}`)
                    }
                }
            }
        }

        return request
    }

    function clearErrors() {
        errors.name = []
        errors.parent_id = []
        errors.permissions = []
    }

    function clear() {
        form.name = ''
        form.parent_id = null
        form.sections = []
        clearGeneralAccess()
        clearErrors()
    }

    function clearAndClose() {
        clear()
        areAccessSettingsVisible.value = false
        areSectionSettingsVisible.value = false
    }

    function set(roleData: getRoleResponse) {
        form.name = roleData.role.name
        form.parent_id = roleData.role.parent_id
        clearGeneralAccess()
        form.sections = []

        for (const permissionGroup of Object.values(roleData.permissions)) {
            const section: PermissionSection = {
                key: permissionGroup.code,
                name: permissionGroup.name,
                permissions: emptyPermissionForm()
            }

            let toPush = true;

            for (const permission of permissionGroup.permissions) {
                const permissionCode = permission.code.replace(`${permissionGroup.code}-`, '')

                if (permissionGroup.code === 'general') {
                    form.general[permissionCode as keyof PermissionForm] = true
                    toPush = false
                } else {
                    section.permissions[permissionCode as keyof PermissionForm] = true
                }
            }

            if (toPush) {
                form.sections.push(section)
                areSectionSettingsVisible.value = true
            } else {
                areAccessSettingsVisible.value = true
            }
        }
    }

    function emptyPermissionForm(): PermissionForm {
        return {
            read: false,
            personnel_read: false,
            create: false,
            edit: false,
            personnel_edit: false,
            delete: false,
            personnel_delete: false
        }
    }

    return {
        form,
        errors,
        sectionOptions,
        areAccessSettingsVisible,
        areSectionSettingsVisible,
        validate,
        toRequest,
        clearErrors,
        clearAndClose,
        set,
        emptyPermissionForm,
        loadSections,
        clear
    }
})
