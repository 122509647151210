<template>
  <div class="flex items-center gap-4">
    <span class="hidden text-right lg:block" v-if="user">
      <span class="block text-sm font-medium text-black dark:text-white">{{ user.name }}</span>
      <span class="block text-xs font-medium">{{ user.role_name }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

const userStore = useUserStore()

const { user } = storeToRefs(userStore)

</script>
