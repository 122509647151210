<template>
  <Card>
    <template #title>Персональные данные</template>

    <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="form.name" label="Имя Фамилия" parameter="name" class="w-full sm:w-1/2" :errors="errors.name">
        <UserInputIcon/>
      </TextInput>

      <TextInput v-model="form.telegram" label="Telegram" parameter="telegram" class="w-full sm:w-1/2" :errors="errors.telegram"/>
    </div>

    <div class="mb-6.5 flex flex-col gap-5.5 sm:flex-row">
      <TextInput v-model="form.email" label="Рабочий email" parameter="email" class="w-full sm:w-1/2" :errors="errors.email">
        <MailInputIcon/>
      </TextInput>

      <TextInput
          v-model="form.password"
          label="Пароль"
          placeholder="Новый пароль"
          parameter="new-password"
          class="w-full sm:w-1/2"
          is-password eye-icon
          :errors="errors.password"
      />
    </div>

    <div class="mb-14">
      <SelectInput
          v-model="form.role_id"
          :options="roleOptions"
          parameter="role_id" placeholder="Выберите роль"
          label="Должность (роль)"
          :errors="errors.role_id"
          :disabled="isRoleChangeDisabled()"
      />
    </div>

    <div class="flex justify-end gap-4.5">
      <RouterLink to="/users">
        <ClearButton>Отмена</ClearButton>
      </RouterLink>

      <StandardButton @click="updateUser">Сохранить</StandardButton>
    </div>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import UserInputIcon from "@/icons/UserInputIcon.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import MailInputIcon from "@/icons/MailInputIcon.vue";
import {ref, type Ref, toRaw} from "vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import {useRoleStore} from "@/stores/role";
import type {User} from "@/types/entities";
import type {UserUpdateRequest} from "@/types/requests";
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import useUserForm from "@/composables/useUserForm";
import {useUserStore} from "@/stores/user";
import type {SelectOption} from "@/types/props";

const props = defineProps<{
  user: User
}>()

const api = useApi()
const alertStore = useAlerts()
const userStore = useUserStore()
const roleStore = useRoleStore()

const roleOptions: Ref<SelectOption[]> = ref([])
roleStore.loadRoles()
  .then(() => {
    let roles = roleStore.rolesToSelectOptions()
    // only admin users can see admin role in select
    if(!userStore.isAdmin()) {
      roles = roles.filter(role => role.key !== 2)
    }
    roleOptions.value = roles
  })

const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors
} = useUserForm(props.user)

function updateUser() {
  clearErrors()
  if (!validateForm()) return

  const updateRequest: UserUpdateRequest = toRaw(form)
  if (!updateRequest.password) delete updateRequest.password
  api.user.update(props.user.id, updateRequest)
      .then(response => {
        alertStore.flash('success', 'Данные успешно обновлены')
      })
      .catch(err => {
        if (err instanceof UserServiceValidationError) {
          populateErrors(err)
        }
      })
}

function isRoleChangeDisabled(): boolean {
  return props.user.id === 1 ||  // super admin can't be demoted
      (props.user.role_id === 2 && !userStore.isSuperAdmin()) || // appointed admin can only be demoted by super admin
      !userStore.can('roles', 'edit')
}

</script>
