<template>
  <div>
    <label class="flex cursor-pointer select-none items-center">
      <div class="relative">
        <input v-model="selected" type="radio" :value="value"  class="sr-only" />
        <div :class="{ 'border-primary': selected === value }"
          class="mr-2 flex h-5 w-5 items-center justify-center rounded-full border">
          <span :class="{ '!bg-primary': selected === value }" class="h-2.5 w-2.5 rounded-full bg-transparent">
          </span>
        </div>
      </div>
      <span v-if="label" class="text-black text-sm">{{ label }}</span>
    </label>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";

defineProps<{
  label?: string
  value?: string | boolean
}>()

const selected: Ref<string> = defineModel({ required: true })

</script>
