<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible" class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-[625px] rounded-lg bg-white dark:bg-boxdark max-h-full overflow-y-auto">

          <div class="flex w-full justify-between border-b border-stroke p-8.5">
            <h3 class="font-inter font-bold text-2xl text-black">Добавление роли</h3>
            <CloseButton @click="closeSelf"/>
          </div>

          <RoleForm
              @form:submit="submit"
              @form:cancel="closeSelf"
              :hide-settings-on-startup="true"
              class="py-6 px-8.5"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {type Ref} from "vue";
import CloseButton from "@/shared/Buttons/CloseButton.vue";
import RoleForm from "@/shared/Forms/RoleForm.vue"
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {useRoleForm} from "@/stores/roleForm";

const visible: Ref<boolean> = defineModel({ required: true })
const emit = defineEmits(['refresh:table'])

const alerts = useAlerts()
const api = useApi()
const roleForm = useRoleForm()
roleForm.clear()

function closeSelf(): void {
  roleForm.clearAndClose()
  visible.value = false
}

function submit(): void {
  roleForm.clearErrors()
  if (!roleForm.validate()) return
  const request = roleForm.toRequest()

  api.user.createRole(request)
      .then(response => {
        alerts.flash('success', 'Роль успешно сохранена')
        closeSelf()
        emit('refresh:table')
      })
      .catch(err => {
        if (err instanceof UserServiceValidationError) {
          for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (roleForm.errors.hasOwnProperty(field)) {
              roleForm.errors[field] = validationErrors
            }
          }
        }
      })
}

</script>
