<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable
          v-if="data"
          @refresh:rows="getData"
          :headers="headers"
          :rows="data"
          :column-order="['id', 'name', 'email']"
          :search-fields="['id', 'name', 'email']"
          :actions="actions"
          :per-page="10"
          :hide-actions-title="true"
          title="Пользователи"
      >
        <StandardButton
            @click="isAddModalVisible = true" class="items-center"
            v-if="isAvailableForUserChange()"
        >
          Добавить
        </StandardButton>
      </StandardTable>
    </Card>
  </div>

  <RoleAddModal v-model="isAddModalVisible" @refresh:table="getData" :role-id="roleId" />
  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    flashText="Пользователь успешно удален" @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>
  <Teleport to="#hiddenContainer">
    <UserMinusIcon id="trashIcon" class="fill-current transition hover:fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableHeader } from "@/types/props";
import { ref, onMounted } from "vue";
import RoleAddModal from "@/shared/Modals/RoleAddModal.vue";
import { useUserStore } from "@/stores/user";
import DeleteModal from "@/shared/Modals/DeleteModal.vue"
import UserMinusIcon from "@/icons/UserMinusIcon.vue";

const props = defineProps<{
  roleId: number
  roleName?: string
  canEdit: boolean
}>()

const api = useApi()
const userStore = useUserStore()
const data = ref<Record<string, AlphaNumerical>[]>()
const user_ids = ref<number[]>()
const isAddModalVisible = ref(false)
getData()

function getData() {
  const rows: Record<string, AlphaNumerical>[] = []
  const ids: number[] = []
  api.user.role(props.roleId)
    .then(response => {
      if (response.role.users) {
        for (const user of response.role.users) {
          ids.push(user.id)
          rows.push({
            id: `<span class="text-body">${user.id}</span>`,
            rawId: user.id,
            name: `<span class="text-primary">${user.name}</span>`,
            email: user.email,
            placeholder: user.name
          })
        }
      }
      data.value = rows
      user_ids.value = ids
    })
}

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'rawId' },
  { name: 'Фамилия и имя', sortKey: 'name' },
  { name: 'Email', sortKey: 'email' },
]
const actions = ref<TableAction[]>();

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.user.removeRoleUsers(props.roleId, { users: [id] }).then((res) => getData())
}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        const row = data.value?.find((row) => row.id == id);
        deleteModalContent.value = {
          title: 'Удаление пользователя2',
          modalText: `Вы действительно хотите удалить у пользователя <b>${row?.placeholder}</b> текущую роль: <b>${props.roleName}</b>?<br><br>Пользователю будет назначена роль <b>Сотрудник</b> с доступом только в разделы с персональными данными.`,
        }
      },
      visible: isAvailableForUserChange,
      parameter: "rawId",
      title: 'Удаление пользователя2',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});

function isAvailableForUserChange(id: number = 0): boolean {
  if (id === 1) return false
  if (props.roleId === 2) return userStore.isSuperAdmin()
  return props.canEdit
}

</script>
