<template>
  <StandardModal v-model="visible" @close:self="closeSelf" title="Редактирование тематики">
    <div class="flex flex-col gap-4">
      <TextInput v-model="form.name" parameter="name" label="Название" placeholder="Название" :errors="errors.name" />

      <StandardButton @click="submit" class="w-40">Сохранить</StandardButton>
    </div>
  </StandardModal>
</template>

<script setup lang="ts">
import StandardModal from "@/shared/Modals/StandardModal.vue";
import { reactive, type Ref, toRaw } from "vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import Validator from "validatorjs";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import type { Theme } from "@/types/entities";

const props = defineProps<{
  theme: Theme
}>()

const alertStore = useAlerts()
const api = useApi()
const visible: Ref<boolean> = defineModel({ required: true })

const form = reactive({
  name: props.theme.name,
})

const errors: Record<string, string[]> = reactive({
  name: [],
})

function closeSelf() {
  visible.value = false
  form.name = ''
  errors.name = []
}

function validateForm(): boolean {
  errors.name = []
  const validator = new Validator(toRaw(form), {
    name: 'required',
  })
  const passes = Boolean(validator.passes())
  if (!passes) {
    errors.name = validator.errors.get('name')
  }
  return passes
}

function populateErrors(err: UserServiceValidationError): void {
  for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
    if (errors.hasOwnProperty(field)) {
      errors[field] = validationErrors
    }
  }
}

function submit() {
  if (!validateForm()) return
  api.products.themeUpdate(props.theme.id, {
    name: form.name,
    status: props.theme.status
  }).then(response => {
    alertStore.flash('success', 'Тематика успешно изменена')
    closeSelf()
  })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
