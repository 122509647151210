<template>
  <label :for="parameter" class="flex cursor-pointer select-none items-center w-[46px] h-[24px]">
    <div class="relative">
      <input v-model="toggle" type="checkbox" :id="parameter" class="sr-only"/>
      <div class="block h-6 w-11.5 rounded-full bg-meta-9 dark:bg-[#5A616B]"></div>
      <div
          :class="{ '!translate-x-[22px] !bg-primary dark:!bg-white': toggle }"
          class="absolute left-0.5 top-0.5 h-5 w-5 rounded-full bg-white transition"
      ></div>
    </div>
  </label>
</template>

<script setup lang="ts">

import type {Ref} from "vue";

defineProps<{
  parameter: string
}>()

const toggle: Ref<boolean> = defineModel( { required: true })

</script>
