<template>
  <div class="data-table-common data-table-one max-w-full overflow-x-auto">
    <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
      <div v-if="slots.default || searchFields">
        <slot />
        <div class="w-full flex-row justify-between items-center sm:flex">
          <h2 v-if="title" class="text-title-md mb-5 font-bold text-black dark:text-white sm:mb-0">
            {{ title }}
          </h2>
        </div>
      </div>

      <div class="datable-container !max-height-[55vh] overflow-auto">
        <table class="table w-full table-auto datatable-table" :class="align || 'middle'">
          <thead>
            <tr>
              <TableHeaderCell v-if="selectableRows" name="" :active="false" order="" class="!text-right" />
              <TableHeaderCell name="" :active="false" order="" class="!text-right" />
              <TableHeaderCell @click.prevent="setSort(header?.sortKey)" v-for="header in headers"
                :sort-key="header.sortKey" :name="header.name" :active="form.sort === header.sortKey"
                :order="form.order" />
              <TableHeaderCell v-if="actions" :name="!hideActionsTitle ? 'Действия' : ''" :active="false" order=""
                class="!text-right" />
            </tr>
          </thead>
          <tbody>
            <template v-for="row in slicedRows">
              <tr class="row-hover">
                <td v-if="selectableRows">
                  <Checkbox v-model="row['isSelected']" />
                </td>
                <td class="cursor-pointer"
                  @click="expandedRowsIds.includes(row['id']) ? collapseRow(row['id']) : expandRow(row['id'])">
                  <div v-if="row.is_expandable === 1"
                    class="flex h-10.5 w-10.5 items-center justify-center rounded-md bg-[#F3F5FC] dark:bg-meta-4">
                    <ExpandIcon
                      :class="expandedRowsIds.includes(row['id']) ? 'fill-primary rotate-180' : 'fill-primary'" />
                  </div>
                </td>
                <td v-for="col in columnOrder">
                  <div class="flex gap-3">
                    <span v-html="row[col]"></span>
                    <CopyIcon v-if="copiedColumns && copiedColumns[col]"
                      class="fill-body showOnHover hover:fill-primary cursor-pointer" />
                  </div>
                </td>
                <td v-if="actions">
                  <div class="flex flex-wrap justify-end gap-2" :class="{ showOnHover: actionsOnHover }">
                    <a href="#" style="width: 30%;" v-if="actions"
                      v-for="action in actions.filter((item) => row.is_stopped ? item.title !== 'Остановить' : item.title !== 'Запустить')"
                      v-show="action.visible(row[action.parameter])"
                      @click.prevent="action.action(row[action.parameter])" :title="action.title"
                      v-html="action.icon" />
                  </div>
                </td>
              </tr>
              <td v-if="expandedRowsIds.includes(row['id'])" :colspan="headers.length + 3">
                <ExternalSourcesSubTable v-if="subTableType === 'news' && externalTeasersRows"
                  :sub-rows="externalTeasersRows[Number(row['id'])]" />
                <ProductsSplitSubtable v-if="subTableType === 'products' && productSplitRows"
                  :sub-rows="productSplitRows[Number(row['id'])]" />
              </td>
            </template>
          </tbody>
        </table>
      </div>

      <TablePaginator v-if="perPage !== 0" @set:page="setPage" :current-page="form.page" :max-page="maxPage"
        :item-count="rowCount" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ExpandableTableProps } from "@/types/props";
import TableHeaderCell from "@/shared/Tables/TableHeaderCell.vue";
import TablePaginator from "@/shared/Tables/TablePaginator.vue";
import useExpandableTable from "@/composables/useExpandableTable";
import { useSlots } from "vue";
import CopyIcon from "@/icons/CopyIcon.vue";
import Checkbox from "@/shared/Inputs/Checkbox.vue";
import ExpandIcon from "@/icons/ExpandIcon.vue";
import ExternalSourcesSubTable from "@/shared/Tables/ExternalSourcesSubTable.vue";
import ProductsSplitSubtable from "@/shared/Tables/ProductsSplitSubTable.vue"

const props = defineProps<ExpandableTableProps>()
const emit = defineEmits(['refresh:rows', 'change:page'])
const slots = useSlots()

const {
  form,
  slicedRows,
  maxPage,
  rowCount,
  expandedRowsIds,
  expandRow,
  collapseRow,
  setSort,
  setPage,
} = useExpandableTable(props, emit)

</script>