<template>
  <StandardModal v-model="visible" @close:self="closeSelf" title="Добавление доменов">
    <div class="flex flex-col gap-4">
      <TextArea
          v-model="form.domains"
          label="Домены"
          parameter="domains"
          class="w-full"
          :row-count="3"
          :resizable="false"
          :errors="errors.domains"
      />

      <SelectInput
          v-model="form.rotation_id"
          label="Ротация доменов"
          :options="rotationOptions"
          parameter="rotation_id"
          placeholder="Выберите ротацию"
          :errors="errors.rotation_id"
          type="number"
      />

      <SelectInput
          v-model="form.registrar_id"
          label="Аккаунт у регистратора"
          :options="registrarOptions"
          parameter="registrar_account"
          placeholder="Выберите аккаунт регистратора"
          :errors="errors.registrar_id"
          type="number"
      />

      <SelectInput
          v-model="form.dns_provider_id"
          label="Аккаунт DNS провайдера"
          :options="dnsOptions"
          parameter="dns_account"
          placeholder="Выберите аккаунт DNS провайдера"
          :errors="errors.dns_provider_id"
          type="number"
      />

      <StandardButton @click="submit" class="w-40">Добавить</StandardButton>
    </div>
  </StandardModal>
</template>

<script setup lang="ts">
import {reactive, ref, type Ref, toRaw} from "vue";
import StandardModal from "@/shared/Modals/StandardModal.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import TextArea from "@/shared/Inputs/TextArea.vue";
import type {SelectOption} from "@/types/props";
import {useApi} from "@/stores/api";
import Validator from "validatorjs";
import type {StoreDomainRequest} from "@/types/requests";
import {useAlerts} from "@/stores/alert";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";

const api = useApi()
const alerts = useAlerts()

const visible: Ref<boolean> = defineModel({ required: true })

const emit = defineEmits(['form:submit'])

const form = reactive({
  domains: '',
  rotation_id: 0,
  registrar_id: 0,
  dns_provider_id: 0
})

const errors: Record<string, string[]> = reactive({
  domains: [],
  rotation_id: [],
  registrar_id: [],
  dns_provider_id: []
})

const rotationOptions: Ref<SelectOption[]> = ref([])
const registrarOptions: Ref<SelectOption[]> = ref([])
const dnsOptions: Ref<SelectOption[]> = ref([])

function getOptions() {
  api.domains.options().then(({dns_providers, registrars, domain_rotations}) => {
    dnsOptions.value = dns_providers.map(provider => <SelectOption>{ key: provider.id, value: provider.name })
    registrarOptions.value = registrars.map(registrar => <SelectOption>{ key: registrar.id, value: registrar.name })
    rotationOptions.value = domain_rotations.map(rotation => <SelectOption>{ key: rotation.id, value: rotation.name })
  })
}

getOptions()

function submit() {
  clearErrors()
  if (!validateForm()) return

  const request: StoreDomainRequest = {
    domains: form.domains.split(/[, ]/),
    rotation_id: form.rotation_id,
    registrar_id: form.registrar_id,
    dns_provider_id: form.dns_provider_id
  }

  api.domains.store(request)
    .then(response => {
      alerts.flash('success', 'Домены успешно добавлены')
      emit('form:submit')
      closeSelf()
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      } else {
        alerts.flash('danger', err.message)
        closeSelf()
      }
    })
}

function validateForm(): boolean {
  const validator = new Validator(toRaw(form), {
    domains: 'required|string',
    rotation_id: 'required|integer',
    registrar_id: 'required|integer',
    dns_provider_id: 'required|integer'
  })

  const passes = Boolean(validator.passes())

  if (!passes) {
    errors.domains = validator.errors.get('domains')
    errors.rotation_id = validator.errors.get('rotation_id')
    errors.registrar_id = validator.errors.get('registrar_id')
    errors.dns_provider_id = validator.errors.get('dns_provider_id')
  }

  return passes
}

function clearForm(): void {
  form.domains = ''
  form.rotation_id = 0
  form.registrar_id = 0
  form.dns_provider_id = 0
}

function clearErrors(): void {
  errors.domains = []
  errors.rotation_id = []
  errors.registrar_id = []
  errors.dns_provider_id = []
}

function closeSelf(): void {
  visible.value = false
  clearForm()
}

function populateErrors(err: UserServiceValidationError): void {
  for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
    if (errors.hasOwnProperty(field)) {
      errors[field] = validationErrors
    }
  }
}

</script>
