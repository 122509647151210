<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_966_26803)">
      <path fill-rule="evenodd" d="M15.9187 3.71289L12.9656 0.787891C12.7687 0.591016 12.4874 0.478516 12.2062 0.478516H7.08743C6.2718 0.478516 5.5968 1.15352 5.5968 1.96914V12.2348C5.5968 13.0504 6.2718 13.7254 7.08743 13.7254H14.7374C15.5531 13.7254 16.2281 13.0504 16.2281 12.2348V4.47227C16.2281 4.19102 16.1156 3.90977 15.9187 3.71289ZM13.0218 2.64414L14.0906 3.71289H13.0218V2.64414ZM14.7374 12.4598H7.08743C6.97493 12.4598 6.86243 12.3473 6.86243 12.2348V1.96914C6.86243 1.85664 6.97493 1.74414 7.08743 1.74414H11.7562V4.33164C11.7562 4.66914 12.0374 4.97852 12.4031 4.97852H14.9624V12.2629C14.9624 12.3754 14.8499 12.4598 14.7374 12.4598Z"/>
      <path fill-rule="evenodd" d="M12.4031 6.32861H8.69058C8.35308 6.32861 8.0437 6.60986 8.0437 6.97549C8.0437 7.34111 8.32495 7.62236 8.69058 7.62236H12.4031C12.7406 7.62236 13.05 7.34111 13.05 6.97549C13.05 6.60986 12.7406 6.32861 12.4031 6.32861Z"/>
      <path fill-rule="evenodd" d="M12.4031 8.69092H8.69058C8.35308 8.69092 8.0437 8.97217 8.0437 9.33779C8.0437 9.67529 8.32495 9.98467 8.69058 9.98467H12.4031C12.7406 9.98467 13.05 9.70342 13.05 9.33779C13.0218 8.97217 12.7406 8.69092 12.4031 8.69092Z"/>
      <path fill-rule="evenodd" d="M11.7562 14.6255C11.4187 14.6255 11.1094 14.9067 11.1094 15.2724V16.0317C11.1094 16.1442 10.9969 16.2567 10.8844 16.2567H3.26248C3.14998 16.2567 3.03748 16.1442 3.03748 16.0317V5.73799C3.03748 5.62549 3.14998 5.51299 3.26248 5.51299H4.21873C4.55623 5.51299 4.8656 5.23174 4.8656 4.86611C4.8656 4.50049 4.58435 4.21924 4.21873 4.21924H3.26248C2.44685 4.21924 1.77185 4.89424 1.77185 5.70986V16.0317C1.77185 16.8474 2.44685 17.5224 3.26248 17.5224H10.9125C11.7281 17.5224 12.4031 16.8474 12.4031 16.0317V15.2724C12.4031 14.9067 12.1219 14.6255 11.7562 14.6255Z"/>
    </g>
    <defs>
      <clipPath id="clip0_966_26803">
        <rect width="18" height="18" fill-rule="evenodd" transform="translate(0 0.000488281)"/>
      </clipPath>
    </defs>
  </svg>
</template>
