<template>
  <Card no-padding>
    <template #header>
      <div class="w-full flex sm:justify-between sm:items-center py-4 flex-col sm:flex-row">
        <h3 class="text-title-md mb-5 font-bold text-black dark:text-white sm:mb-0">
          Ротации доменов
        </h3>
        <StandardButton @click="isRotationAddModalVisible = true">Добавить ротацию</StandardButton>
      </div>
    </template>
    <div class="w-full p-4 pb-8 sm:grid sm:auto-rows-auto sm:grid-cols-auto-fit-min-400 gap-4 flex flex-col">
      <DomainRotationTable
          @next:submit="openNextConfirmationModal"
          v-for="rotation in data"
          :rotation="rotation"
          class="border border-stroke rounded"
      />
    </div>
  </Card>

  <DomainRotationAddModal
      v-model="isRotationAddModalVisible"
      @form:submit="getData"
  />
  <ConfirmModal
      v-model="isConfirmationModalVisible"
      :modal-text="confirmationText"
      @submit="submitNextDomain"
      @reject="closeNextConfirmationModal"
  />
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import Card from "@/shared/Card.vue";
import DomainRotationAddModal from "@/shared/Modals/DomainRotationAddModal.vue";
import {type Ref, ref} from "vue";
import type {DomainProps, DomainRotationProps} from "@/types/props";
import DomainRotationTable from "@/shared/Tables/DomainRotationTable.vue";
import ConfirmModal from "@/shared/Modals/ConfirmModal.vue";
import {useAlerts} from "@/stores/alert";
import {useApi} from "@/stores/api";
import moment from "moment";
import domainStatus from "@/dictionaries/domainStatus";

const api = useApi()

const isRotationAddModalVisible = ref(false)
const isConfirmationModalVisible = ref(false)
const confirmationId = ref(0)
const confirmationText = ref('')
const data: Ref<DomainRotationProps[]> = ref([])
const alerts = useAlerts()

getData()

function getData() {
  const rows: DomainRotationProps[] = []

  api.domains.rotations().then(response => {
    response.domainRotations.forEach(rotation => {
      const domains: DomainProps[] = rotation.domains.map(domain => <DomainProps>{
        id: domain.id,
        name: domain.name,
        status: domainStatus[domain.status] ?? 'UNKNOWN',
        created_at: moment(domain.created_at).format('DD.MM.YYYY')
      })

      rows.push({
        id: rotation.id,
        name: rotation.name,
        newDomainsCount: rotation.new_domains_count,
        domains: domains
      })
    })

    data.value = rows
  })
}

function openNextConfirmationModal(rotation: { id: number, name: string }): void {
  confirmationId.value = rotation.id
  confirmationText.value = `Вы уверены, что хотите включить следующий домен в ротации ${rotation.name}`
  isConfirmationModalVisible.value = true
}

function closeNextConfirmationModal() {
  confirmationId.value = 0
  confirmationText.value = ''
  isConfirmationModalVisible.value = false
}

function submitNextDomain() {
  api.domains.nextRotation(confirmationId.value)
    .then(response => {
      alerts.flash('success', 'Следующий домен успешно запущен')
      getData()
      closeNextConfirmationModal()
    })
    .catch(err => {
      alerts.flash('danger', err.message)
    })
}

</script>
