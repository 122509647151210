<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['id', 'is_shared', 'utm_source', 'source_id', 'users', 'url', 'login', 'password']"
        :copied-columns="copiedColumns" :actions="actions" :per-page="20" :hide-actions-title="true" :row-count="itemsCount"
        :actions-on-hover="true">
        <RouterLink to="/accounts/add">
          <StandardButton class="items-center">
            <PlusIcon class="fill-current mr-2" />Добавить аккаунт
          </StandardButton>
        </RouterLink>
        <SelectInput v-model="filtersForm.source" parameter="source" :options="sourcesOptions" placeholder="Источник"
          class="lg:w-[30%] w-full">
          <FilterIcon class="mt-1" />
        </SelectInput>

        <SearchMultiSelect v-model="filtersForm.users" parameter="block_ids" :options="userOptions"
          placeholder="Пользователь" class="lg:w-[30%] w-full">
          <FilterIcon class="mt-1" />
        </SearchMultiSelect>
        <TableSearchInput v-model="filtersForm.searchValue" placeholder="Поиск" class="w-full" />

      </StandardTable>
    </Card>
  </div>

  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <PencilWithoutPaperIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
    <span id="warningIcon" class="ml-2" title="Аккаунт не безопасен. Необходимо сменить пароль">
      <WarningShieldIcon class="fill-red" />
    </span>
  </Teleport>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import { onMounted, reactive, ref, toRaw, watch, type Ref } from "vue";
import type {
  AlphaNumerical,
  TableAction,
  TableHeader,
  SelectOption,
  AlphaNumericalRecord
} from "@/types/props";
import PencilWithoutPaperIcon from "@/icons/PencilWithoutPaperIcon.vue";
import WarningShieldIcon from "@/icons/WarningShieldIcon.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue"
import router from "@/router";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import SelectInput from "../Inputs/SelectInput.vue";
import FilterIcon from "@/icons/FilterIcon.vue"
import TableSearchInput from "../Inputs/TableSearchInput.vue";
import type { Source, User } from "@/types/entities";
import PlusIcon from "@/icons/PlusIcon.vue";
import { useRoute } from "vue-router";
import type { AccountsRequest } from "@/types/requests";
import debounce from "@/helpers/debounce";
import idNameRecordsToSelectOptions from "@/helpers/idNameRecordsToSelectOptions";

const route = useRoute()

const itemsCount = ref<number>(100)

const { sort, order, page } = route.query

const filtersFormInitialState = {
  source: '',
  users: [] as string[],
  searchValue: ''
}

let filtersForm = reactive({ ...filtersFormInitialState })

const sourcesOptions: Ref<SelectOption[]> = ref([])
const userOptions: Ref<AlphaNumericalRecord> = ref({})

const sources: Ref<Source[]> = ref([])
const usersList: Ref<User[]> = ref([])



const alerts = useAlerts();


const actions = ref<TableAction[]>();
const data = ref<Record<string, AlphaNumerical>[]>();
const api = useApi();



api.sources.accountOptions()
  .then(response => {

    let sOptions: SelectOption[] = []
    let uOptions: AlphaNumericalRecord = {}

    sources.value = response.newsSources
    usersList.value = response.users;

    sOptions = idNameRecordsToSelectOptions(response.newsSources)

    for (const id in response.users) {
      uOptions[id] = response.users[id].name;
    }

    userOptions.value = uOptions
    sourcesOptions.value = sOptions
  })

watch(
  () => route.fullPath,
  () => getData()
);

watch(filtersForm, () => {
   debounce(getData)()
}, { deep: true }
)


getData();

function getData() {
  const rows: Record<string, AlphaNumerical>[] = [];
  const fields = toRaw(filtersForm)

  const requestData: AccountsRequest = {
    page: page ? Number(page) : 1,
    itemsPerPage: 20,
    orderBy: sort ? String(sort) : undefined,
    orderDirection: order ? String(order) : undefined,
    query: fields.searchValue.length ? fields.searchValue : undefined,
    news_source: Number(fields.source) ? Number(fields.source) : undefined,
    users: fields.users.length ? fields.users : undefined
  }
  api.sources.accounts(requestData)
    .then(response => {
      for (let account of response.accounts) {
        rows.push({
          id: account.id,
          is_shared:
            '<span class="text-black">' +
            (account.is_shared ? "Общий" : "Персональный") +
            "</span>",
          utm_source: `<span class="flex text-primary cursor-pointer">${account.utm_source}` +
            (!account.is_safe
              ? document.getElementById("warningIcon")?.outerHTML
              : "") +
            "</span>",
          source_id: sources.value.find((source) => source.id == account.source_id)?.name || '',
          users: `<span class="flex text-primary cursor-pointer">${account.is_shared ? account.users_count : account.user?.name} </span>`,
          url: `<a href="${sources.value.find((source) => source.id == account.source_id)?.url || ''}" class="text-primary" target="_blank">${sources.value.find((source) => source.id == account.source_id)?.url || ''}</a>`,
          login: account.login,
          password: '********',
          passwordClear: account.password
        })
      }
      data.value = rows
      itemsCount.value = response.count
    })


}

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.sources.accountDelete(id)
    .then((res) => getData())
    .catch((err) => {
      alerts.flash("danger", "Ошибка при удалении аккаунта");
    });

}

const headers: TableHeader[] = [
  { name: "ID", sortKey: "id" },
  { name: "Тип",  },
  { name: "utm_source", sortKey: "utm_source" },
  { name: "Источник", sortKey: "source_id" },
  { name: "Пользователи", sortKey: "users" },
  { name: "Ссылка в личный кабинет" },
  { name: "Логин" },
  { name: "Пароль" },
];

const copiedColumns: Record<string, string> = {
  login: "login",
  password: "passwordClear",
};

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        const route = router.resolve(`/accounts/${id}`)
        window.open(route.href, '_blank')
      },
      visible: () => true,
      parameter: "id",
      title: "Редактировать аккаунт",
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        deleteModalContent.value = {
          title: 'Удаление аккаунта',
          modalText: `Вы дейсвительно хотите удалить аккаунт?`,
        }
      },
      visible: () => true,
      parameter: "id",
      title: 'Удаление аккаунта',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});
</script>
