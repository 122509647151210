import axios from 'axios'
import { VITE_API_PRODUCTS_BASE_URL } from '@/application.config'

const productsClient = axios.create({
    baseURL: VITE_API_PRODUCTS_BASE_URL,
})

productsClient.interceptors.request.use(
    config => {
        if (localStorage.getItem('casAccessToken')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('casAccessToken')}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
export default productsClient