<template>
  <Card no-padding>
    <StandardTable v-if="data" @refresh:rows="getData" :headers="headers"
      :column-order="['name', 'geo', 'theme_id', 'params']" :rows="data" :per-page="20" :actions="actions"
      default-sort="theme_id" hide-actions-title :serverside-pagination="true" :row-count="rowsCount">
      <div class="w-full flex justify-between flex-col md:flex-row gap-4">
        <div class="flex gap-4 flex-col md:flex-row">
          <TableSearchInput v-model="filters.search" placeholder="Поиск по названиям" />
          <SearchMultiSelect v-model="filters.geo" parameter="geo" :options="geoOptions" placeholder="GEO"
            class="w-full md:w-80" />
          <SelectInput v-model="filters.theme_id" parameter="theme_id" :options="themeOptions" placeholder="Тематика"
            type="number" class="w-full md:w-50" />
        </div>

        <RouterLink to="/streams/add">
          <StandardButton>Добавить поток</StandardButton>
        </RouterLink>
      </div>

    </StandardTable>
  </Card>

  <DeleteModal v-model="isDeleteModalVisible" :id="deleteModalId" @refresh:table="getData" :submit="deleteAction">
    <template #title>
      Удаление потока
    </template>
    <template #text>
      <p>Вы уверен, что хотите удалить поток?</p>
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
    <PencilIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
  </Teleport>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import {
  type TableAction,
  type AlphaNumerical,
  type TableHeader,
  type SelectOption,
  type MultiSelectOptions,
  type AlphaNumericalRecord
} from "@/types/props";
import { onMounted, reactive, ref, toRaw, watch, type Ref } from "vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import PencilIcon from "@/icons/PencilIcon.vue";
import { useRoute, useRouter } from "vue-router";
import DeleteModal from "@/shared/Modals/DeleteModal.vue";
import TableSearchInput from "@/shared/Inputs/TableSearchInput.vue";
import SelectInput from "@/shared/Inputs/SelectInput.vue";
import SearchMultiSelect from "@/shared/Inputs/SearchMultiSelect.vue";
import { useApi } from "@/stores/api";
import type { StreamsListRequest } from "@/types/requests";
import { useAlerts } from "@/stores/alert";
import debounce from "@/helpers/debounce";

const alerts = useAlerts()
const route = useRoute()
const api = useApi()
const headers: TableHeader[] = [
  { name: 'Название', sortKey: 'name' },
  { name: 'GEO', sortKey: 'geo' },
  { name: 'Тематика', sortKey: 'theme_id' },
  { name: 'Параметры', }
]

const router = useRouter()
const data = ref<Record<string, AlphaNumerical>[]>([])
const actions = ref<TableAction[]>([])
const rowsCount = ref<number>(1)
const isDeleteModalVisible = ref(false)
const deleteModalId = ref(0)

const filters = reactive({
  search: route.query.search ? String(route.query.search) : '',
  geo: route.query.geo && route.query.geo.length ? String(route.query.geo).split(',') : [],
  theme_id: route.query.theme_id ? Number(route.query.theme_id) : 0
})

const geoOptions: Ref<AlphaNumericalRecord> = ref({})

const themeOptions: Ref<SelectOption[]> = ref([])

function getOptions() {
  let geoRecords: AlphaNumericalRecord = {}
  api.products.streamsOptions().then(({ geo, themes }) => {
    geo.forEach((item) => item.type == 'option' ? geoRecords[item.code] = item.label : item.items?.forEach((option) => geoRecords[option.code] = option.label))
    themeOptions.value = themes.map((item) => ({ key: item.id, value: item.name }))
    geoOptions.value = geoRecords
  })
    .then(() => getData())
}

function getData() {
  const { sort, order, page, geo, theme_id, search } = route.query
  const rows: Record<string, AlphaNumerical>[] = []

  const requestData: StreamsListRequest = {
    page: page ? Number(page) : 1,
    sort_field: sort ? String(sort) : undefined,
    sort_direction: order ? String(order) : undefined,
    per_page: 20,
    geo: geo && geo.length > 0 ? String(geo).split(',') : undefined,
    theme_id: theme_id && Number(theme_id) > 0 ? Number(theme_id) : undefined,
    name: search && String(search).length > 0 ? String(search) : undefined,
  }
  api.products.streamsList(requestData).then(({ streams, pagination }) => {
    streams.forEach((stream) => rows.push({
      id: stream.id,
      name: stream.name,
      geo: `<div class="w-30">${stream.geo.split(',').join(', ')}</div>`,
      theme_id: themeOptions.value.find((item) => item.key === stream.theme_id)?.value || '',
      params: `<div class="w-80">${stream.links?.map((item) => (`<a href="${item.href}" target="_blank" class="text-primary">${item.tag && item.offer ? item.label + '-' + item.offer + '-' + item.tag : item.href}</a> - ${item.probability}%`)).join('<br>') || ''}</div>`
    }))
    data.value = rows
    rowsCount.value = pagination.total
  })

}


watch(
  () => route.fullPath,
  () => debounce(getData)()
);

watch(filters, () => {
  debounce(() =>
    router.push({ query: toRaw({ ...route.query, ...filters }) }), 1000
  )()
}, { deep: true }
)

getOptions()


onMounted(() => {
  actions.value = [
    {
      action: (id: number) => router.push(`/streams/${id}`),
      visible: () => true,
      parameter: 'id',
      title: 'Редактировать поток',
      icon: document.getElementById('pencilIcon')?.outerHTML ?? ''
    },
    {
      action: (id: number) => {
        deleteModalId.value = id
        isDeleteModalVisible.value = true
      },
      visible: () => true,
      parameter: 'id',
      title: 'Удалить поток',
      icon: document.getElementById('trashIcon')?.outerHTML ?? ''
    }
  ]
})

function deleteAction(id: number) {
  isDeleteModalVisible.value = false
  api.products.streamDelete(id)
    .then((res) => getData())
    .catch((err) => {
      alerts.flash("danger", "Ошибка при удалении товарного тизера");
    });
}

</script>
