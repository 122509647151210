<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible" class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-[625px] rounded-lg bg-white dark:bg-boxdark max-h-full overflow-y-auto">
          <div class="flex w-full justify-between border-b border-stroke p-8.5">
            <h3 class="font-inter font-bold text-2xl text-black">{{ title }}</h3>
            <CloseButton @click="closeSelf" />
          </div>

          <div class="p-8.5 !pb-6">
            <slot/>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import CloseButton from "@/shared/Buttons/CloseButton.vue";
import type {Ref} from "vue";

const visible: Ref<boolean> = defineModel({ required: true })

defineProps<{
  title: string
}>()

const emit = defineEmits(['close:self'])

function closeSelf() {
  emit('close:self')
}

</script>
