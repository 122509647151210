<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <GroupedTable v-if="data" @refresh:rows="getData" :headers="headers" :empty-top="true" :groups="data"
        :row-count="itemsCount" :copied-columns="copiedColumns" :column-order="[
          'id',
          'name',
          'geo',
          'affiliate',
          'href',
          'preland',
          'teasers'
        ]" :per-page="50" :disable-pagination="true" :serversidePagination="true" :column-count="8" :actions="actions" :hide-actions-title="true">

        <div>

          <div
            class="flex justify-between sm:flex-row-reverse sm:items-center sm:justify-between gap-4 pt-8 pb-8 px-7.5 flex-col-reverse flex sm:gap-x-4 sm:gap-y-0 after:hidden">
            <RouterLink to="/links/add">
              <StandardButton class="items-center">
                <PlusIcon class="fill-current mr-2" />Добавить ссылку
              </StandardButton>
            </RouterLink>
            <SearchMultiSelect v-model="filtersForm.tags" :options="tagOptions" placeholder="Теги" parameter="tags"
              class="w-full">
            </SearchMultiSelect>
            <SearchMultiSelect v-model="filtersForm.geos" :options="geoOptions" placeholder="Гео" parameter="geo"
              class="w-full">
            </SearchMultiSelect>
          </div>
        </div>

      </GroupedTable>
    </Card>
  </div>

  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <PencilIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import { onMounted, reactive, ref, toRaw, watch, type Ref } from "vue";
import type {
  AlphaNumerical,
  AlphaNumericalRecord,
  TableAction,
  TableHeader, TableRowGroup
} from "@/types/props";
import { useApi } from "@/stores/api";
import { useRoute, useRouter } from "vue-router";
import type { LinkListRequest } from "@/types/requests";
import debounce from "@/helpers/debounce";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import GroupedTable from "./GroupedTable.vue";
import StandardButton from "../Buttons/StandardButton.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue"
import PencilIcon from "@/icons/PencilIcon.vue";
import { useAlerts } from "@/stores/alert";

const geoOptions: Ref<AlphaNumericalRecord> = ref({})
const tagOptions: Ref<AlphaNumericalRecord> = ref({})
const itemsCount = ref<number>(1)

const route = useRoute()
const router = useRouter()

const filtersFormInitialState = {
  geos: [],
  tags: [],
}

let filtersForm = reactive({ ...filtersFormInitialState })

const data = ref<TableRowGroup[]>();
const api = useApi();


watch(filtersForm, () => {
  debounce(getData)()
}, { deep: true }
)

function getOptions() {
  let tagRecords: AlphaNumericalRecord = {}
  let geoRecords: AlphaNumericalRecord = {}
  api.products.linksOptions().then(({ geo, tags }) => {
    tags.forEach(item => tagRecords[item] = item)
    geo.forEach((item) => item.type == 'option' ? geoRecords[item.code] = item.label : item.items?.forEach((option) => geoRecords[option.code] = option.label))
    tagOptions.value = tagRecords
    geoOptions.value = geoRecords
  })
}

getOptions()

function getData() {
  const fields = toRaw(filtersForm)

  const requestData: LinkListRequest = {
    geo: fields.geos.length ? fields.geos : undefined,
    tag: fields.tags.length ? fields.tags : undefined,
  }

  api.products.links(requestData).then(({ links_by_tag, links_count }) => {
    data.value = links_by_tag.map((group) => ({
      label: group.tag,
      rows: group.links.map((link) => ({
        id: link.id,
        name: `<div class="text-black font-bold text-truncate">${link.label}</div>Оффер: ${link.offer}`,
        geo:  `<div class="w-30">${link.geo.split(',').join(', ')}</div>`,
        affiliate: link.affiliate?.name || '',
        href: `<a class="text-primary" target="_blank" href="${link.href}">${link.href}</a>`,
        preland: link.preland?.name || '',
        teasers: `${link.active_streams_count}/${link.total_streams_count}`,
        isActive: link.total_streams_count > 0 ? 1 : 0,
        nameCopy: link.label
      }))
    }))
    itemsCount.value = links_count
  })

}


getData()

const copiedColumns: Record<string, string> = {
  name: "nameCopy",
};

const headers: TableHeader[] = [
  { name: "ID", sortKey: "id" },
  { name: "Метки", sortKey: "name" },
  { name: "Гео", sortKey: "geo" },
  { name: "ПП", sortKey: "affiliate" },
  { name: 'Ссылка', sortKey: 'href' },
  { name: 'Прокла', sortKey: 'preland' },
  { name: 'Тизеры', sortKey: 'teasers' }
];

const alerts = useAlerts();


const actions = ref<TableAction[]>();

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.products.linkDelete(id)
    .then((res) => getData())
    .catch((err) => {
      alerts.flash("danger", "Ошибка при удалении ссылки");
    });

}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        const route = router.resolve(`/links/${id}`)
        window.open(route.href, '_blank')
      }, 
      visible: () => true,
      parameter: "id",
      title: "Редактировать ссылку",
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        deleteModalContent.value = {
          title: 'Удаление ссылки',
          modalText: `Вы дейсвительно хотите удалить ссылку?`,
        }
      },
      visible: () => true,
      parameter: "id",
      title: 'Удаление ссылки',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});
</script>
