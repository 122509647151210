import { reactive, ref, toRaw, watch } from "vue";
import type { AccordionItem, LinkForm, SelectOption, SelectOptionGroup } from "@/types/props";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import type { Affiliate, Link } from "@/types/entities";
import Validator from "validatorjs";
import { useApi } from "@/stores/api";

export default function useSourceForm() {
    const api = useApi()
    const prelandOptions = ref<SelectOptionGroup[]>([])
    const affiliateOptions = ref<SelectOption[]>([])
    const affiliateValues = ref<Affiliate[]>([])
    const link_tail = ref<string>('')
    const geoOptions = ref<AccordionItem[]>([])


    const form = reactive<LinkForm>({
        href: '',
        geo: [],
        tag: '',
        preland_id: '',
        affiliate_id: '',
        label: '',
        offer: '',
        enabled: 1
    })

    const errors = reactive<Record<string, string[]>>({
        href: [],
        geo: [],
        tag: [],
        affiliate_id: [],
        label: [],
        offer: []
    })

    function validateForm(): boolean {
        const validator = new Validator(toRaw(form), {
            href: 'required|url|max:256',
            geo: 'required|array',
            tag: 'required|max:32',
            affiliate_id: 'required',
            label: 'required|max:32',
            offer: 'required|max:32',
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.href = validator.errors.get('href')
            errors.geo = validator.errors.get('geo')
            errors.tag = validator.errors.get('tag')
            errors.affiliate_id = validator.errors.get('affiliate_id')
            errors.label = validator.errors.get('label')
            errors.offer = validator.errors.get('offer')
        }

        return passes
    }


    function clearErrors() {
        errors.href = []
        errors.geo = []
        errors.label = []
        errors.tag = []
        errors.offer = []
        errors.affiliate_id = []
    }

    function clear() {
        form.href = ''
        form.geo = []
        form.label = ''
        form.tag = ''
        form.offer = ''
        form.preland_id = ''
        form.affiliate_id = ''
        clearErrors()
    }

    function set(link: Link) {
        form.href = link.href
        form.geo = link.geo.split(',')
        form.label = link.label
        form.tag = link.tag
        form.enabled = link.enabled
        form.offer = link.offer
        form.preland_id = link.preland_id.toString()
        form.affiliate_id = link.affiliate_id.toString()
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    function prepareToRequest() {
        return {
            ...form,
            geo: form.geo.join(','),
            affiliate_id: Number(form.affiliate_id),
            preland_id: Number(form.preland_id)
        }
    }

    function getOptions() {
        api.products.linksOptions().then(({ geo, affiliates, prelands }) => {
            geoOptions.value = geo
            affiliateValues.value = affiliates
            affiliateOptions.value = affiliates.map((i) => ({ key: i.id, value: i.name }))
            prelandOptions.value = prelands.map((i) => ({
                label: i.label,
                options: i.items.map((k) => ({ key: k.id, value: k.label }))
            }))
        })
    }

    watch(form, () => {
        const affiliate = affiliateValues.value.find((item) => item.id.toString() === form.affiliate_id.toString())

        if ((affiliate?.link_tail || '') === link_tail.value) {
            return
        }

        if (link_tail.value.length > 0) {
            form.href = form.href.replace(link_tail.value, affiliate?.link_tail || '')
        }
        else {
            form.href = form.href + affiliate?.link_tail || ''
        }

        link_tail.value = affiliate?.link_tail || ''

    }, { deep: true }
    )

    return {
        form,
        errors,
        geoOptions,
        affiliateOptions,
        prelandOptions,
        getOptions,
        validateForm,
        clearErrors,
        set,
        clear,
        populateErrors,
        prepareToRequest
    }
}
