<template>
  <div>
    <label v-if="label" class="mb-3 block text-sm font-medium text-black dark:text-white" :for="parameter">
      {{ label }}
    </label>

    <div class="relative z-20 bg-transparent dark:bg-form-input">
      <span v-if="slots.default" class="absolute top-3 left-3 z-30   font-normal">
        <slot />
      </span>
      <select v-model="selectModel" :disabled="disabled"
        class="text-black relative font-medium z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary disabled:bg-gray"
        :class="{ 'border-red': errors && errors.length, 'pl-12': slots.default }"
        :id="parameter">
        <option :value="defaultVal">{{ placeholder }}</option>
        <optgroup v-for="group in options" :label="group.label">
            <option v-for="option in group.options" :value="option.key">{{ option.value }}</option>
        </optgroup>
      </select>
      <span class="absolute top-1/2 right-4 z-30 -translate-y-1/2 pointer-events-none">
        <CaretInputIcon />
      </span>
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import { useSlots, type Ref } from "vue";
import type { AlphaNumerical, SelectOption, SelectOptionGroup } from "@/types/props";
import CaretInputIcon from "@/icons/CaretInputIcon.vue";

const slots = useSlots()


const props = defineProps<{
  parameter: string
  placeholder: string
  options: SelectOptionGroup[]
  label?: string
  disabled?: boolean
  errors?: string[]
  type?: 'number' | 'string'
}>()

const selectModel: Ref<AlphaNumerical> = defineModel({ required: true })

const defaultVal = props.type && props.type === 'number' ? 0 : ''
</script>
