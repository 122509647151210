<template>
  <div>
    <TextInput v-model="roleForm.form.name" parameter="name" label="Название роли" :errors="roleForm.errors.name"/>

    <CustomSelectInput
        v-model="roleForm.form.parent_id"
        parameter="parent_id"
        :options="roleStore.rolesToSelectOptions()"
        placeholder="Выберите из списка"
        label="Родительская роль"
        :errors="roleForm.errors.role_id"
        class="mt-6 max-w-[332px]"
    />

    <a v-if="!areSettingsVisible" href="" class="mt-6 text-primary block" @click.prevent="areSettingsVisible = true">
      Добавление прав доступа
    </a>

    <Transition name="scale">
      <div v-if="areSettingsVisible" class="transition origin-top">
        <ToggleSectionContainer v-model="roleForm.areAccessSettingsVisible" parameter="access-all" class="mt-6">
          <template #label>Общий доступ</template>
          <template #description>Настройте общий доступ ко всей системе</template>

          <div class="transition origin-top flex flex-col gap-2 mt-2">
            <Checkbox
                v-model="roleForm.form.general.read"
                :label="translatePermissionType('read')"
                :disabled="roleForm.form.general.edit || roleForm.form.general.delete"
            />
            <Checkbox
                v-model="roleForm.form.general.personnel_read"
                :label="translatePermissionType('personnel_read')"
                :disabled="roleForm.form.general.create || roleForm.form.general.personnel_edit || roleForm.form.general.personnel_delete"
            />
            <Checkbox v-model="roleForm.form.general.create" :label="translatePermissionType('create')" @change="checkReadProperty"/>
            <Checkbox v-model="roleForm.form.general.edit" :label="translatePermissionType('edit')" @change="checkReadProperty"/>
            <Checkbox v-model="roleForm.form.general.personnel_edit" :label="translatePermissionType('personnel_edit')" @change="checkReadProperty"/>
            <Checkbox v-model="roleForm.form.general.delete" :label="translatePermissionType('delete')" @change="checkReadProperty"/>
            <Checkbox v-model="roleForm.form.general.personnel_delete" :label="translatePermissionType('personnel_delete')" @change="checkReadProperty"/>
            <p v-if="roleForm.errors.permissions.length" class="mt-2 text-red" v-html="roleForm.errors.permissions.join('<br>')"></p>
          </div>
        </ToggleSectionContainer>

        <ToggleSectionContainer v-model="roleForm.areSectionSettingsVisible" parameter="section-permissions" class="mt-6">
          <template #label>Доступ по разделам</template>
          <template #description>Выберите раздел и настройте права доступа для данной роли к выбранному разделу</template>

          <PermissionSectionForm
              v-if="roleForm.areSectionSettingsVisible"
              v-model="roleForm.form.sections"
              :section-options="roleForm.sectionOptions"
              :errors="roleForm.errors.permissions"
          />
        </ToggleSectionContainer>
      </div>
    </Transition>

    <div class="flex justify-end gap-3 mt-8.5">
      <ClearButton @click="cancel" class="!py-[13px] text-primary">Отмена</ClearButton>
      <StandardButton @click="submit" class="!py-[13px]">Сохранить</StandardButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import translatePermissionType from "@/helpers/translatePermissionType";
import PermissionSectionForm from "@/shared/Forms/PermissionSectionForm.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import CustomSelectInput from "@/shared/Inputs/CustomSelectInput.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import ToggleSectionContainer from "@/shared/Containers/ToggleSectionContainer.vue";
import Checkbox from "@/shared/Inputs/Checkbox.vue";
import {useRoleStore} from "@/stores/role";
import {useRoleForm} from "@/stores/roleForm";
import {ref} from "vue";

const props = defineProps<{
  hideSettingsOnStartup?: boolean
}>()

const roleStore = useRoleStore()
const roleForm = useRoleForm()

const emit = defineEmits(['form:submit', 'form:cancel'])

const areSettingsVisible = ref(true)
if (props.hideSettingsOnStartup) {
  areSettingsVisible.value = false
}

function cancel() {
  emit('form:cancel')
}

function submit() {
  emit('form:submit')
}

function checkReadProperty() {
  if (roleForm.form.general.edit || roleForm.form.general.delete) {
    roleForm.form.general.read = true

  } else if (roleForm.form.general.create || roleForm.form.general.personnel_edit || roleForm.form.general.personnel_delete) {
    roleForm.form.general.personnel_read = true
  }
}

</script>
