<template>
  <Transition name="fade">
    <SuccessAlert v-if="alerts.success" v-bind="alerts.success"/>
  </Transition>
  <Transition name="fade">
    <DangerAlert v-if="alerts.danger" v-bind="alerts.danger"/>
  </Transition>
</template>

<script setup lang="ts">

import {useAlerts} from "@/stores/alert";
import SuccessAlert from "@/shared/Alerts/SuccessAlert.vue";
import DangerAlert from "@/shared/Alerts/DangerAlert.vue";

const alertStore = useAlerts()
const alerts = alertStore.alerts

</script>
