<template>
  <div class="height-50 border-b border-stroke flex w-full p-4 gap-4 cursor-grab" :class="{ 'bg-gray': !teaser.status }">
    <div>
      {{ index + 1 }}
    </div>
    <img :src="imgUrl" :alt="teaser.id.toString()" class="max-h-[100px]"/>
    <div class="flex flex-col max-w-90">
      <span :class="{ 'text-black': teaser.status === 1 }">ID: {{ teaser.id }}</span>
      <span>{{ teaser.title }}</span>
      <a :href="teaser.link" target="_blank" class="block text-primary font-light hover:underline">{{ teaser.link }}</a>
    </div>
    <div v-if="teaser.rpm" class="whitespace-nowrap ml-auto" :class="{ 'text-black': teaser.status === 1 }">RPM: {{teaser.rpm}}</div>
    <TrashIcon @click="removeTeaser" class="fill-danger w-10 cursor-pointer ml-auto"/>
  </div>
</template>

<script setup lang="ts">
import type {ManualTopTeaser} from "@/types/props";
import TrashIcon from "@/icons/TrashIcon.vue";
import { VITE_API_IMG_PROXY_BASE_URL } from "@/application.config";

const props = defineProps<{
  teaser: ManualTopTeaser,
  index: number
}>()

const imgUrl = VITE_API_IMG_PROXY_BASE_URL + props.teaser.image

const emit = defineEmits(['teaser:remove'])

function removeTeaser() {
  emit('teaser:remove', props.index)
}
</script>
