<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['id', 'img', 'title', 'localization', 'scheduled_at', 'is_delivered', 'is_opened', 'is_closed']"
        :actions="actions" :per-page="100">
        <StandardButton class="items-center">
          <PlusIcon class="fill-current mr-2" />Добавить тизеры
        </StandardButton>
      </StandardTable>

    </Card>
  </div>
  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    flashText="Уведомление удалено из очереди" @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";

import type { AlphaNumerical, TableAction, TableHeader } from "@/types/props";
import { ref, onMounted } from "vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue";
const api = useApi()
const data = ref<Record<string, AlphaNumerical>[]>()
getData()

function getData() {
  const rows: Record<string, AlphaNumerical>[] = []

  const notification = {
    id: 8230,
    img: '<img src="https://upload.wikimedia.org/wikipedia/commons/6/65/Carpincho_en_el_Parque_Nacional_El_Palmar.jpg" width="200">',
    title: '<div class="w-50 text-truncate">Заголовок: Title<br>Описание: Description </div>',
    localization: 'ru',
    status: 'active',
    scheduled_at: '2023-11-23 00:45:00',
    is_delivered: '(not set)',
    is_opened: '(not set)',
    is_closed: '(not set)'
  }
  for (let i = 0; i < 300; i++) {
    rows.push(notification)
  }
  data.value = rows
}


const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Картинка' },
  { name: 'Тизер', sortKey: 'title' },
  { name: 'Гео', sortKey: 'localization' },
  { name: 'Отправить в', sortKey: 'scheduled_at' },
  { name: 'Доставлено', sortKey: 'is_delivered' },
  { name: 'Открыто', sortKey: 'is_opened' },
  { name: 'Закрыто', sortKey: 'is_closed' },
]
const actions = ref<TableAction[]>();

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  getData()
  return true;
}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        deleteModalContent.value = {
          title: 'Удалить из очереди',
          modalText: 'Уверены, что хотите удалить уведомление из очереди?',
        }
      },
      visible: () => true,
      parameter: "id",
      title: 'Удалить из очереди',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});
</script>
