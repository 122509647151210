<template>
  <div class="flex items-center justify-between lg:justify-center gap-2 px-6 py-5.5 lg:py-6.5">
    <RouterLink to="/" class="bg-white p-3 rounded">
      <Logo style="width: 150px"/>
    </RouterLink>
    <ButtonArrowLeft
        class="block lg:ml-5.5"
        @click.stop="sidebarToggle = !sidebarToggle"
    />
  </div>
</template>

<script setup lang="ts">
import Logo from "@/icons/Logo.vue";
import ButtonArrowLeft from "@/shared/Buttons/ButtonArrowLeft.vue";
import {inject} from "vue";
import type {Ref} from "vue";

const sidebarToggle = inject<Ref<boolean>>('sidebarToggle')
</script>
