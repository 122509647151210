<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <ExpandableTable v-if="data"  @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['id', 'img', 'user', 'created_at', 'title', 'status', 'locale', 'geo', 'manual_top_key', 'rating', 'stream']"
        :actions="actions" :per-page="100" :actions-on-hover="true" :product-split-rows="productSplitRows"
        :row-count="itemsCount" :sub-table-type="'products'">

        <div>
          <div
            class="flex justify-between sm:flex-row-reverse sm:items-center sm:justify-between gap-4 pt-8 px-7.5 flex-col-reverse flex sm:gap-x-4 sm:gap-y-0 after:hidden">
            <RouterLink to="/products/add">
              <StandardButton class="items-center">
                <PlusIcon class="fill-current mr-2" />Добавить товарный тизер
              </StandardButton>
            </RouterLink>
            <VueTailwindDatepicker v-if="showDatePicker" :options="dateInputOptions" i18n="ru" class="sm:max-w-70"
              placeholder="Создан" :formatter="dateInputFormatter" v-model="filtersForm.created_at"
              :disable-date="disableDates"
              input-classes="text-black relative z-20 w-[100%] appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary disabled:bg-gray">
              <template #inputIcon="{ value }">
                {{ "" }}
              </template>
            </VueTailwindDatepicker>
            <TextInput v-model="filtersForm.manual_top_key" parameter="manual_top_key" placeholder="Ключ ротации"
              class="w-full" />
            <TextInput v-model="filtersForm.title" parameter="title" placeholder="Заголовок" class="w-full" />
            <TextInput v-model="filtersForm.id" parameter="id" @change="getData()" placeholder="ID" class="w-full" />
          </div>

          <div class="flex justify-between datatable-top">
            <SearchMultiSelect v-model="filtersForm.selectedGeos" parameter="block_ids" :options="geoOptions"
              placeholder="GEO" class="lg:w-[30%] w-full">
              <GeoIcon />
            </SearchMultiSelect>
            <SelectInput v-model="filtersForm.user" parameter="user" class="sm:w-75" :options="userOptions"
              placeholder="Пользователь" />
            <SelectInput v-model="filtersForm.status" parameter="status" class="sm:w-75" :options="statusOptions"
              placeholder="Статус" />
            <SelectInput v-model="filtersForm.locale" parameter="locale" class="sm:w-75" :options="localeOptions"
              placeholder="Локаль" />
          </div>
        </div>

      </ExpandableTable>
    </Card>
  </div>

  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <CopyIcon id="copyIcon" class="fill-current" />
    <StopIcon id="stopIcon" class="fill-red" />
    <PencilIcon id="pencilIcon" class="fill-current" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
    <PlayIcon id="playIcon" class="fill-[#0c3]" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import ExpandableTable from "@/shared/Tables/ExpandableTable.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableHeader } from "@/types/props";
import { ref, onMounted, reactive, nextTick, toRaw, watch } from "vue";
import CopyIcon from "@/icons/CopyIcon.vue";
import StopIcon from "@/icons/StopIcon.vue";
import TextInput from "../Inputs/TextInput.vue";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import GeoIcon from "@/icons/GeoIcon.vue";
import SelectInput from "../Inputs/SelectInput.vue";
import type { ProductSplit, User } from "@/types/entities";
import { type SelectOption } from "@/types/props";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import PencilIcon from "@/icons/PencilIcon.vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import PlayIcon from "@/icons/PlayIcon.vue";
import moment from 'moment';
import type { ProductTeasersRequest } from "@/types/requests";
import debounce from "@/helpers/debounce";
import DeleteModal from "../Modals/DeleteModal.vue";
import { useAlerts } from "@/stores/alert";
import { VITE_API_IMG_PROXY_BASE_URL } from "@/application.config";

const route = useRoute()

const itemsCount = ref<number>(1)
const router = useRouter()

const api = useApi()

const geoOptions = ref<string[]>([])

const userOptions = ref<SelectOption[]>([])

const statusOptions = ref<SelectOption[]>([
  { key: 1, value: 'Ротация' },
  { key: 2, value: 'Остановлен' },
])

const localeOptions = ref<SelectOption[]>([])

const alerts = useAlerts();

const data = ref<Record<string, AlphaNumerical>[]>([])

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.products.productDelete(id)
    .then((res) => getData())
    .catch((err) => {
      alerts.flash("danger", "Ошибка при удалении товарного тизера");
    });

}

const usersList = ref<User[]>([])

const productSplitRows = ref<Record<number, ProductSplit>[]>([])

let showDatePicker = ref(true)

const dateInputFormatter = ref({
  date: 'DD/MM/YYYY',
  month: 'MMM',
})

const dateInputOptions = ref({
  shortcuts: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    past: (period: number) => `${period} дней`,
    currentMonth: 'Этот месяц',
    pastMonth: 'Прошлый месяц',
  },
  footer: {
    apply: 'Применить',
    cancel: 'Отмена',
  },
})

function disableDates(date: Date) {
  return date > new Date()
}

const filtersFormInitialState = {
  selectedGeos: [] as string[],
  created_at: { start: '', end: '' },
  user: 0,
  status: 0,
  locale: "0",
  title: '',
  id: '',
  manual_top_key: ''
}

let filtersForm = reactive({ ...filtersFormInitialState })

watch(
  () => route.fullPath,
  () => debounce(getData)()
);

watch(filtersForm, () => {
  debounce(getData)()
}, { deep: true }
)

getData()
getOptions()

function getOptions() {
  api.products.productsTableOptions().then(({ geos, locales }) => {
    geoOptions.value = geos;
    localeOptions.value = locales.map((item) => { return { key: item, value: item } })
  })
}

function getData() {
  const { sort, order, page } = route.query

  const rows: Record<string, AlphaNumerical>[] = []
  const subRows: Record<number, ProductSplit>[] = []
  const options: SelectOption[] = []

  const fields = toRaw(filtersForm)

  const requestData: ProductTeasersRequest = {
    page: page ? Number(page) : 1,
    order_by: sort ? String(sort) : undefined,
    order_direction: order ? String(order) : undefined,
    author_ids: Number(fields.user) ? [Number(fields.user)] : undefined,
    geos: fields.selectedGeos.length ? fields.selectedGeos : undefined,
    manual_top_key: fields.manual_top_key.length ? fields.manual_top_key : undefined,
    id: fields.id.length ? Number(fields.id) : undefined,
    title: fields.title.length ? fields.title : undefined,
    created_at_from: fields.created_at.start.length ? moment(fields.created_at.start, 'DD/MM/YYYY').toISOString() : undefined,
    created_at_to: fields.created_at.end.length ? moment(fields.created_at.end, 'DD/MM/YYYY').toISOString() : undefined,
    active: Number(fields.status) ? Number(fields.status) === 1 : undefined,
    locales: fields.locale !== "0" ? [fields.locale] : undefined,
  }

  api.user.users().then(({ users }) => {
    for (const user of users) {
      options.push({ key: user.id, value: user.name })
    }
    userOptions.value = options
    usersList.value = users
  })

  api.products.products(requestData)
    .then(({ creatives, count }) => {
      creatives.forEach(creative => {
        let image = creative.images.find((img) => img.type === 1)
        rows.push({
          id: creative.id,
          img: `<img src="${VITE_API_IMG_PROXY_BASE_URL + image?.path_square || ''}" class="w-20 h-20">`,
          user: usersList.value.find((u) => u.id == creative.author_id)?.name || '',
          title: `<div class="w-50 text-truncate">${creative.title}</div>`,
          created_at: `<div class="w-25 text-truncate">${moment(creative.created_at).format('DD.MM.YYYY HH:m:s')}</div>`,
          user_id: creative.author_id,
          geo: creative.stream.geo,
          locale: creative.locale,
          status: creative.active ? 'Ротация' : 'Остановлен',
          manual_top_key: '',
          rating: creative.rating,
          stream: creative.stream_id,
          is_expandable: creative.stream?.links?.length ? 1 : 0,
          is_stopped: !creative.active ? 1 : 0
        })
        if (creative.stream?.links) {
          let links: ProductSplit[] = []
          creative.stream?.links.forEach(link => {
            links.push({
              id: link.id,
              preland: link.href,
              offer: link.offer,
              label: link.label,
              tag: link.tag,
              partner_program: link.affiliate?.name || ''
            })
          });
          subRows[creative.id] = links
        }

      })
      itemsCount.value = count
      data.value = rows
      productSplitRows.value = subRows
    })
}

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Изображение' },
  { name: 'Пользователь' },
  { name: 'Создан', sortKey: 'created_at' },
  { name: 'Заголовок' },
  { name: 'Статус', sortKey: 'status' },
  { name: 'Локаль' },
  { name: 'Гео' },
  { name: 'Ключ ручного топа' },
  { name: '🍆🍑😱', sortKey: 'rating' },
  { name: 'Поток' },

]
const actions = ref<TableAction[]>();

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        const route = router.resolve(`/products/${id}`)
        window.open(route.href, '_blank')
      },
      visible: () => true,
      parameter: "id",
      title: 'Редактировать',
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        const route = router.resolve(`/products/${id}/copy`)
        window.open(route.href, '_blank')
      },
      visible: () => true,
      parameter: "id",
      title: 'Копировать',
      icon: document.getElementById("copyIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        api.products.productDeactivate(id)
          .then((response) => {
            getData()
          })
      },
      visible: () => true,
      parameter: "id",
      title: 'Остановить',
      icon: document.getElementById("stopIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        api.products.productActivate(id)
          .then((response) => {
            getData()
          })
      },
      visible: () => true,
      parameter: "id",
      title: 'Запустить',
      icon: document.getElementById("playIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        deleteModalContent.value = {
          title: 'Удаление товарного тизера',
          modalText: `Вы дейсвительно хотите удалить товарный тизер?`,
        }
      },
      visible: () => true,
      parameter: "id",
      title: 'Удалить',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});
</script>
