<template>
  <div>
    <div class="flex justify-between w-full">
      <label v-if="slots.label" :for="parameter" class="text-title-sm2 font-bold text-black">
        <slot name="label"/>
      </label>

      <ToggleInput v-model="isVisible" :parameter="parameter"/>
    </div>

    <p v-if="slots.description" class="mt-2 text-sm md:text-base">
      <slot name="description"/>
    </p>

    <Transition name="scale">
      <template v-if="isVisible">
        <slot/>
      </template>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ToggleInput from "@/shared/Inputs/ToggleInput.vue";
import {type Ref, useSlots} from "vue";

defineProps<{
  parameter: string
}>()

const isVisible: Ref<boolean> = defineModel({ required: true })

const slots = useSlots()
</script>
