import type {User} from "@/types/entities";
import {reactive, toRaw} from "vue";
import type {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import type {UserUpdateRequest} from "@/types/requests";
import Validator from "validatorjs";

export default function useUserForm(user: User | null = null) {
    const form = reactive<UserUpdateRequest>({
        name: user ? user.name : '',
        telegram: user ? user.telegram : '',
        email: user ? user.email : '',
        password: '',
        role_id: user && user.role_id ? user.role_id : 0
    })

    const errors = reactive<Record<string, string[]>>({
        name: [],
        telegram: [],
        email: [],
        password: [],
        role_id: []
    })
    function validateForm(): boolean {
        let rules: Record<string, string>

        // create user form
        if (user === null) {
            rules = {
                name: 'required',
                role_id: 'required',
                email: 'required|email',
                password: 'required|min:8'
            }

        // update user form
        } else {
            rules = {
                name: 'required',
                role_id: 'required',
                email: 'required|email',
                password: 'min:8'
            }
        }

        const validator = new Validator(toRaw(form), rules)
        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.name = validator.errors.get('name')
            errors.role_id = validator.errors.get('role_id')
            errors.email = validator.errors.get('email')
            errors.password = validator.errors.get('password')
        }

        return passes
    }

    function clearErrors(): void {
        for (const key of Object.keys(errors)) {
            errors[key] = []
        }
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    return {
        form,
        errors,
        validateForm,
        clearErrors,
        populateErrors
    }
}
