<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['name', 'role', 'accounts', 'telegram', 'email']"
        :search-fields="['name', 'role', 'accounts', 'telegram', 'email']" :actions="actions" :per-page="5">
        <RouterLink to="/users/create" v-if="userStore.can('users', 'create')">
          <StandardButton class="items-center">
            <PlusIcon class="fill-current mr-2" />Добавить пользователя
          </StandardButton>
        </RouterLink>
      </StandardTable>
    </Card>
  </div>
  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    flashText="Пользователь успешно удален" @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
    <PencilIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
    <EyeIcon id="eyeIcon" class="fill-current transition hover:fill-primary" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import PlusIcon from "@/icons/PlusIcon.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableHeader, TableRow } from "@/types/props";
import { ref, onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue";
import EyeIcon from "@/icons/EyeIcon.vue";
import PencilIcon from "@/icons/PencilIcon.vue";
import router from "@/router";
import type {UserListItem} from "@/types/entities";

const api = useApi()
const userStore = useUserStore()
const data = ref<Record<string, AlphaNumerical>[]>()
const userMap = ref<Map<number, UserListItem>>(new Map())
getData()

function getData() {
  const rows: TableRow[] = []
  api.user.users()
    .then(response => {
      userMap.value.clear()

      for (const user of response.users) {
        userMap.value.set(user.id, user)

        // const accounts = user.accounts.map(account => account.sources).join(', ')
        const accounts = ''

        rows.push({
          id: user.id,
          name: `${user.name}<br><span class="text-sm text-body">${user.id}</span>`,
          role: user.role?.name,
          role_id: user.role_id,
          accounts: accounts ? accounts : '-',
          telegram: user.telegram,
          email: user.email
        })
      }

      data.value = rows
    })
}


const headers: TableHeader[] = [
  { name: 'ФИО / ID', sortKey: 'id' },
  { name: 'Роль', sortKey: 'role_id' },
  { name: 'Аккаунты в источниках', sortKey: 'accounts' },
  { name: 'Telegram', sortKey: 'telegram' },
  { name: 'Email', sortKey: 'email' }
]
const actions = ref<TableAction[]>();

const isDeleteModalVisible = ref(false);

const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);

const currentRowId = ref<number | null>(null);

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;
  api.user.delete(id).then((res) => getData())
}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => router.push(`/users/${id}`),
      visible: () => true,
      parameter: 'id',
      title: 'Страница пользователя',
      icon: document.getElementById("eyeIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        const route = router.resolve(`/users/${id}/edit`)
        window.open(route.href, '_blank')
      },
      visible: (id: number) => {
        const user = <UserListItem>userMap.value.get(id)

        if (user.id === 1) return userStore.isSuperAdmin()
        if (user.role_id === 2) return userStore.isSuperAdmin() || userStore.user?.id === user.id

        return user.can_edit
      },
      parameter: 'id',
      title: 'Редактировать пользователя',
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id
        isDeleteModalVisible.value = true
        deleteModalContent.value = {
          title: 'Удалить пользователя',
          modalText: 'Уверены, что хотите удалить пользователя?',
        }
      },
      visible: (id: number) => {
        const user = <UserListItem>userMap.value.get(id)

        return user.can_delete &&
            userStore?.user?.id !== id && // user can't delete himself
            id !== 1 // superadmin is immortal :)
      },
      parameter: 'id',
      title: 'Удалить пользователя',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});

</script>
