<template>
  <div class="flex flex-col gap-5 md:gap-7 2xl:gap-10">
    <Card no-padding>
      <StandardTable v-if="data" @refresh:rows="getData" :headers="headers" :rows="data"
        :column-order="['id', 'name', 'streamsCount']" :actions="actions" :per-page="20" :hide-actions-title="true"
        :serverside-pagination="true" :row-count="itemsCount">
        <StandardButton @click="isCreateModalVisible = true">
          Добавить тематику
        </StandardButton>
        <TextInput v-model="filtersForm.name" parameter="name" placeholder="Название" class="lg:w-[30%] w-full" />
      </StandardTable>
    </Card>
  </div>
  <ThemeAddModal v-model="isCreateModalVisible" />
  <ThemeEditModal v-if="editModalTheme" v-model="isEditModalVisible" :theme="editModalTheme" />
  <DeleteModal v-if="isDeleteModalVisible && deleteModalContent" v-model="isDeleteModalVisible" :id="currentRowId"
    @refresh:table="getData" :submit="deleteAction">
    <template v-slot:title>
      {{ deleteModalContent.title }}
    </template>
    <template v-slot:text>
      <p v-html="deleteModalContent.modalText" />
    </template>
  </DeleteModal>

  <Teleport to="#hiddenContainer">
    <PencilIcon id="pencilIcon" class="fill-current transition hover:fill-primary" />
    <TrashIcon id="trashIcon" class="fill-current transition hover:fill-red" />
  </Teleport>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import StandardTable from "@/shared/Tables/StandardTable.vue";
import Card from "@/shared/Card.vue";
import { useApi } from "@/stores/api";
import type { AlphaNumerical, TableAction, TableHeader } from "@/types/props";
import { onMounted, reactive, type Ref, ref, toRaw, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import TrashIcon from "@/icons/TrashIcon.vue";
import DeleteModal from "@/shared/Modals/DeleteModal.vue"
import PencilIcon from "@/icons/PencilIcon.vue";
import ThemeAddModal from "../Modals/ThemeAddModal.vue";
import ThemeEditModal from "../Modals/ThemeEditModal.vue";
import type { Theme } from "@/types/entities";
import type { ThemesListRequest } from "@/types/requests";
import TextInput from "../Inputs/TextInput.vue";
import debounce from "@/helpers/debounce";


const route = useRoute()
const router = useRouter()
const api = useApi()
const data = ref<Record<string, AlphaNumerical>[]>()
const isCreateModalVisible = ref(false)
const isEditModalVisible = ref(false)
const editModalTheme: Ref<Theme | null> = ref(null)
const actions = ref<TableAction[]>();
const isDeleteModalVisible = ref(false);
const deleteModalContent = ref<Record<string, AlphaNumerical> | null>(null);
const currentRowId = ref<number | null>(null);
const itemsCount = ref<number>(1)

const filtersFormInitialState = {
  name: route.query.name? String(route.query.name):''
}

let filtersForm = reactive({ ...filtersFormInitialState })


function getData() {
  const { sort, order, page, name } = route.query

  const rows: Record<string, AlphaNumerical>[] = []

  const requestData: ThemesListRequest = {
    page: page ? Number(page) : 1,
    order_by: sort ? String(sort) : undefined,
    order_direction: order ? String(order) : undefined,
    name: name ? String(name) : undefined,
  }

  api.products.themes(requestData).then(({ themes, total }) => {
    themes.forEach((theme) => {
      rows.push({
        id: theme.id,
        name: theme.name,
        streamsCount: theme.streams_count,
        status: theme.status
      })
    })
    itemsCount.value = total
    data.value = rows
  })

}

watch(
  () => route.fullPath,
  () => debounce(getData)()
);

watch(filtersForm, () => {
  debounce(() =>
    router.push({ query: toRaw({ ...route.query, ...filtersForm }) }), 1000
  )()
}, { deep: true }
)

getData()

watch(isEditModalVisible, isVisible => {
  if (!isVisible) {
    editModalTheme.value = null
    getData()
  }
})

watch(isCreateModalVisible, isVisible => {
  if (!isVisible) getData()
})

const headers: TableHeader[] = [
  { name: 'ID', sortKey: 'id' },
  { name: 'Название', sortKey: 'name' },
  { name: 'Количество потоков', sortKey: 'streamsCount' },
]

function deleteAction(id: number) {
  isDeleteModalVisible.value = false;

  api.products.themeDelete(id).then((res) => {
    getData()
  })

}

onMounted(() => {
  actions.value = [
    {
      action: (id: number) => {
        let currentRow = data.value?.find((row) => row.id === id)
        if (currentRow) {
          isEditModalVisible.value = true
          editModalTheme.value = {
            id: Number(currentRow.id),
            name: String(currentRow.name),
            streams_count: Number(currentRow.streamsCount),
            status: Number(currentRow.status)
          }
        }
      },
      visible: () => true,
      parameter: "id",
      title: "Редактировать тематику",
      icon: document.getElementById("pencilIcon")?.outerHTML ?? "",
    },
    {
      action: (id: number) => {
        currentRowId.value = id;
        isDeleteModalVisible.value = true;
        deleteModalContent.value = {
          title: 'Удаление тематики',
          modalText: `Вы дейсвительно хотите удалить тематику?`,
        }
      },
      visible: (id: number) => Number(data.value?.find((row) => row.id === id)?.streamsCount) === 0 || false,
      parameter: "id",
      title: 'Удаление тематики',
      icon: document.getElementById("trashIcon")?.outerHTML ?? "",
    },
  ];
});

</script>
