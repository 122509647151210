<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible"
        class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-142.5 rounded-lg bg-white py-12 px-8 text-center dark:bg-boxdark md:py-15 md:px-17.5">
          <span class="mx-auto inline-block"> 
            <WarningIconBlue />
          </span>
          <h3 class="text-xl font-bold text-black dark:text-white sm:text-2xl mb-4 mt-4">
            Внимание!
          </h3>
          <p class="text-sm text-darkgray dark:text-white sm:text-sm mb-6" v-html="modalText"></p>
          <div class="-mx-3 flex flex-wrap justify-center gap-y-4">
            <div class="w-full px-3 2xsm:w-1/3">
              <button @click="emit('submit')"
                class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90">
                Да 
              </button>
            </div>
            <div class="w-full px-3 2xsm:w-1/3">
              <button @click="emit('reject')"
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1">
                Нет
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import WarningIconBlue from "@/icons/WarningIconBlue.vue";
import { type Ref } from "vue";

const props = defineProps<{
  modalText: string
}>()

const emit = defineEmits(['submit', 'reject'])

const visible: Ref<boolean> = defineModel({ required: true })



</script>
