import {reactive, ref, toRaw, unref, watch} from "vue";
import type {RotationRequest} from "@/types/requests";
import type {RotationEmulatorOptions} from "@/types/props";
import devicesToSelectOptions from "@/helpers/devicesToSelectOptions";
import {useApi} from "@/stores/api";
import idNameRecordsToSelectOptions from "@/helpers/idNameRecordsToSelectOptions";
import geoResponseToSelectOptions from "@/helpers/geoResponseToSelectOptions";
import arrayToSelectOptions from "@/helpers/arrayToSelectOptions";
import type {RotationPrediction} from "@/types/responses";
import {RotationServiceValidationError} from "@/errors/RotationServiceValidationError";
import {defineStore} from "pinia";

export const useRotationEmulator = defineStore('rotation-emulator', () => {
    const form = ref<RotationRequest>({
        ssp: '',
        country: '',
        city: '',
        site_id: '',
        block_ids: [],
        rating: '',
        browser: '',
        device: '',
        news_category: '',
        os: '',
        us: '',
        ucm: '',
        um: '',
        uct: '',
        ut: '',
        cpc: 2
    })

    const errors = ref<Record<string, string[]>>({
        ssp: [],
        country: [],
        city: [],
        site_id: [],
        block_ids: [],
        rating: [],
        browser: [],
        device: [],
        news_category: [],
        os: [],
        us: [],
        ucm: [],
        um: [],
        uct: [],
        ut: [],
        cpc: []
    })

    const options = ref<RotationEmulatorOptions>({
        ssps: [],
        geos: [],
        cities: [],
        siteIds: [],
        blockIds: [],
        browsers: [],
        os: [],
        newsCategories: [],
        utmSources: [],
        utmCampaigns: [],
        utmMediums: [],
        utmContents: [],
        utmTerms: [],
        devices: devicesToSelectOptions()
    })

    const api = useApi()

    api.rotationEmulator.options().then(response => {
        options.value.ssps = idNameRecordsToSelectOptions(response.data.ssps)
        options.value.geos = geoResponseToSelectOptions(response.data.geos)
        options.value.browsers = arrayToSelectOptions(response.data.browsers)
        options.value.os = arrayToSelectOptions(response.data.os)
        options.value.newsCategories = idNameRecordsToSelectOptions(response.data.news_categories)
        options.value.utmSources = arrayToSelectOptions(response.data.utm_sources)
        options.value.utmCampaigns = arrayToSelectOptions(response.data.utm_campaigns)
        options.value.utmMediums = arrayToSelectOptions(response.data.utm_medium)
        options.value.utmContents = arrayToSelectOptions(response.data.utm_content)
        options.value.utmTerms = arrayToSelectOptions(response.data.utm_term)
    })

    const predictions = ref<RotationPrediction[]>([])

    function refreshCities() {
        if (!form.value.country) return

        form.value.city = ''

        api.rotationEmulator.cities({ country: form.value.country })
            .then(response => {
                options.value.cities = idNameRecordsToSelectOptions(response.data)
            })
    }

    function refreshSiteIds() {
        if (!form.value.ssp) return

        form.value.site_id = ''

        api.rotationEmulator.siteIds({ ssp: Number(form.value.ssp) })
            .then(response => {
                options.value.siteIds = arrayToSelectOptions(response.data)
            })
    }

    function refreshBlockIds() {
        if (!form.value.site_id) return

        form.value.block_ids = []

        api.rotationEmulator.blockIds({ site_id: form.value.site_id })
            .then(response => {
                options.value.blockIds = response.data
            })
    }

    watch(() => form.value.country, () => refreshCities())
    watch(() => form.value.ssp, () => refreshSiteIds())
    watch(() => form.value.site_id, () => refreshBlockIds())

    const requiredFields = [
        'ssp', 'site_id', 'block_ids', 'os', 'browser', 'device', 'country', 'news_category', 'cpc'
    ]

    function validateForm(requiredFields: (keyof RotationRequest)[]): boolean {
        let isValidated = true

        for (const field of requiredFields) {
            if (!form.value[field]) {
                isValidated = false
                errors.value[field].push('Поле обязательно')
            }
        }

        return isValidated
    }

    function clearErrors(): void {
        for (const key of Object.keys(errors.value)) {
            errors.value[key] = []
        }
    }

    function submit() {
        clearErrors()
        if (!validateForm(requiredFields as (keyof RotationRequest)[])) return

        api.rotationEmulator.predict(unref(form))
            .then(response => {
                predictions.value = response.data
            })
            .catch(err => {
                if (err instanceof RotationServiceValidationError) {
                    for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
                        if (errors.value.hasOwnProperty(field)) {
                            errors.value[field] = validationErrors
                        }
                    }
                }
            })
    }

    return {
        form,
        errors,
        options,
        predictions,
        submit
    }
})
