<template>
  <div class="datatable-bottom">
    <div class="datatable-info">Страница {{ currentPage }} из {{ maxPage }}, всего {{ itemCount }} записей</div>
    <nav class="datatable-pagination">
      <ul v-if="!disablePagination" class="datatable-pagination-list">
        <TablePageButton @click="setPage(currentPage - 1)" :disabled="currentPage === 1">
          &lt;
        </TablePageButton>

        <TablePageButton v-for="page in pages" @click="setPage(page)" :active="page === currentPage" :disabled="page === '...'">
          {{ page }}
        </TablePageButton>

        <TablePageButton @click="setPage(currentPage + 1)" :disabled="currentPage === maxPage">
          >
        </TablePageButton>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import TablePageButton from "@/shared/Buttons/TablePageButton.vue";
import {ref, watchEffect} from "vue";

const props = defineProps<{
  maxPage: number,
  currentPage: number,
  itemCount: number,
  disablePagination?:boolean
}>()

const emit = defineEmits(['set:page'])

const pages = ref<(number | '...')[]>([])

function setPage(page: number | '...') {
  if (page === '...' || page < 1 || page > props.maxPage) return
  emit('set:page', page)
}

function updatePages() {
  const tempPages: (number | '...')[] = [];

  if (props.currentPage < 5) {
    const limit = props.maxPage > 6 ? 6 : props.maxPage;

    for (let i = 1; i <= limit; i++) {
      tempPages.push(i);
    }

    if (props.maxPage > limit) {
      tempPages.push('...');
      tempPages.push(props.maxPage);
    }

  } else if (props.currentPage >= props.maxPage - 5) {
    const start = props.maxPage - 7;

    if (start > 2) {
      tempPages.push(1)
      tempPages.push('...');
    }

    for (let i = props.maxPage - 7; i <= props.maxPage; i++) {
      if (i > 0) tempPages.push(i);
    }
  } else if (props.currentPage >= 5) {
    tempPages.push(1)
    tempPages.push('...');

    for (let i = props.currentPage - 2; i < props.currentPage + 3; i++) {
      tempPages.push(i);
    }

    tempPages.push('...');
    tempPages.push(props.maxPage);
  }

  pages.value = tempPages
}

watchEffect(() => {
  if (props.maxPage && props.currentPage) {
    updatePages()
  }
})
</script>