import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ProfileView from "@/views/user/ProfileView.vue";
import ProfileEditView from "@/views/user/ProfileEditView.vue";
import LoginView from "@/views/LoginView.vue";
import UsersView from "@/views/user/UsersView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import RotationEmulatorView from "@/views/rotation/RotationEmulatorView.vue";
import NotificationsView from "@/views/notifications/NotificationsView.vue";
import NotificationAddView from "@/views/notifications/NotificationAddView.vue";
import ProfileCreateView from "@/views/user/ProfileCreateView.vue";
import RolesListView from "@/views/roles/RolesListView.vue";
import RoleView from "@/views/roles/RoleView.vue";
import ForbiddenView from "@/views/ForbiddenView.vue";
import NewsTeasersView from '@/views/teasers/NewsTeasersView.vue';
import SourcesListView from '@/views/sources/SourcesListView.vue';
import SourceCreateView from '@/views/sources/SourceCreateView.vue';
import TemplatesListView from '@/views/templates/TemplatesListView.vue';
import TemplateCreateView from '@/views/templates/TemplateCreateView.vue';
import SourceEditView from '@/views/sources/SourceEditView.vue';
import TemplateEditView from '@/views/templates/TemplateEditView.vue';
import AccountsListView from '@/views/accounts/AccountsListView.vue';
import AccountCreateView from '@/views/accounts/AccountCreateView.vue';
import AccountEditView from '@/views/accounts/AccountEditView.vue';
import ProductTeasersView from '@/views/productTeasers/ProductTeasersView.vue'
import ProductTeaserAddView from '@/views/productTeasers/ProductTeaserAddView.vue'
import ProductTeaserEditView from '@/views/productTeasers/ProductTeaserEditView.vue'
import ProductTeaserCopyView from '@/views/productTeasers/ProductTeaserCopyView.vue'
import ManualTopCreateView from "@/views/manualTops/ManualTopCreateView.vue";
import ManualTopsListView from "@/views/manualTops/ManualTopsListView.vue";
import ManualTopUpdateView from "@/views/manualTops/ManualTopUpdateView.vue";
import ManualTopCopyView from "@/views/manualTops/ManualTopCopyView.vue";
import Spy from "@/views/spy/SpyView.vue";
import DomainsListView from "@/views/domains/DomainsListView.vue";
import LinksListView from '@/views/links/LinksListView.vue';
import LinkCreateView from '@/views/links/LinkCreateView.vue';
import LinkEditView from '@/views/links/LinkEditView.vue';
import ThemesListView from '@/views/themes/ThemesListView.vue';
import StreamsListView from "@/views/streams/StreamsListView.vue";
import StreamCreateView from "@/views/streams/StreamCreateView.vue";
import StreamUpdateView from "@/views/streams/StreamUpdateView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Главная',
      component: HomeView
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/users/',
      children: [
        {
          path: '',
          name: 'Пользователи',
          component: UsersView
        },
        {
          path: 'create',
          name: 'Новый пользователь',
          component: ProfileCreateView
        },
        {
          path: ':id',
          name: 'Профиль пользователя',
          component: ProfileView
        },
        {
          path: ':id/edit',
          name: 'Редактирование пользователя',
          component: ProfileEditView
        }
      ]
    },
    {
      path: '/notifications/',
      children: [
        {
          path: 'add',
          name: 'Добавить в очередь',
          component: NotificationAddView,
        },
        {
          path: 'list',
          name: 'Push уведомления',
          component: NotificationsView,
        },
      ],
    },
    {
      path: '/teasers/',
      children: [
        {
          path: 'all',
          name: 'Все тизеры',
          component: NewsTeasersView,
        },
      ],
    },
    {
      path: '/roles/',
      children: [
        {
          path: ':id',
          name: 'Карточка роли',
          component: RoleView
        },
        {
          path: '',
          name: 'Роли',
          component: RolesListView,
        },
      ],
    },
    {
      path: '/sources/',
      children: [
        {
          path: ':id',
          name: 'Редактирование источника',
          component: SourceEditView
        },
        {
          path: '',
          name: 'Источники',
          component: SourcesListView,
        },
        {
          path: 'add',
          name: 'Добавление источника',
          component: SourceCreateView,
        }
      ],
    },
    {
      path: '/links/',
      children: [
        {
          path: ':id',
          name: 'Редактирование ссылки',
          component: LinkEditView
        },
        {
          path: '',
          name: 'Ссылки',
          component: LinksListView,
        },
        {
          path: 'add',
          name: 'Добавление ссылки',
          component: LinkCreateView,
        }
      ],
    },
    {
      path: '/templates/',
      children: [
        {
          path: '',
          name: 'Шаблоны кампаний',
          component: TemplatesListView,
        },
        {
          path: ':id',
          name: 'Редактирование шаблона',
          component: TemplateEditView,
        },
        {
          path: 'add',
          name: 'Добавление шаблона',
          component: TemplateCreateView,
        }
      ],
    },
    {
      path: '/spy/',
      children: [
        {
          path: '',
          name: 'SPY',
          component: Spy,
        }
      ],
    },
    {
      path: '/themes/',
      children: [
        {
          path: '',
          name: 'Тематики',
          component: ThemesListView,
        }
      ],
    },
    {
      path: '/products/',
      children: [
        {
          path: '',
          name: 'Товарные тизеры',
          component: ProductTeasersView,
        },
        {
          path: 'add',
          name: 'Добавление товарного тизера',
          component: ProductTeaserAddView,
        },
        {
          path: ':id',
          name: 'Редактирование товарного тизера',
          component: ProductTeaserEditView,
        },
        {
          path: ':id/copy',
          name: 'Копирование товарного тизера',
          component: ProductTeaserCopyView,
        },
      ],
    },

    {
      path: '/accounts/',
      children: [
        {
          path: '',
          name: 'Аккаунты в источниках',
          component: AccountsListView,
        },
        {
          path: ':id',
          name: 'Редактирование аккаунта',
          component: AccountEditView,
        },
        {
          path: 'add',
          name: 'Добавление аккаунта',
          component: AccountCreateView,
        }
      ],
    },
    {
      path: '/manual-tops/',
      children: [
        {
          path: '',
          name: 'Ручные топы',
          component: ManualTopsListView
        },
        {
          path: 'add',
          name: 'Создание ручного топа',
          component: ManualTopCreateView
        },
        {
          path: 'copy/:id',
          name: 'Копирование ручного топа',
          component: ManualTopCopyView
        },
        {
          path: ':id',
          name: 'Редактирование ручного топа',
          component: ManualTopUpdateView
        }
      ]
    },
    {
      path: '/domains/',
      children: [
        {
          path: '',
          name: 'Домены',
          component: DomainsListView
        }
      ]
    },
    {
      path: '/streams/',
      children: [
        {
          path: '',
          name: 'Потоки',
          component: StreamsListView
        },
        {
          path: 'add',
          name: 'Создание потока',
          component: StreamCreateView
        },
        {
          path: ':id',
          name: 'Редактирование потока',
          component: StreamUpdateView
        }
      ]
    },
    {
      path: '/rotation-emulator',
      name: 'Эмулятор ротации',
      component: RotationEmulatorView
    },
    {
      path: '/403',
      name: 'Доступ запрещен',
      component: ForbiddenView
    },
    {
      path: '/404',
      name: 'Страница не найдена',
      component: NotFoundView
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = `iAdvert - ${<string>to.name}`
  next()
})

export default router
