<template>
  <li>
    <a class="group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
      :class="{ 'bg-graydark dark:bg-meta-4': active }" @click.prevent="active = !active" href="#">
      <slot name="groupName" />
      <CaretIcon class="absolute right-4 top-1/2 -translate-y-1/2 fill-current" :class="{ 'rotate-180': active }" />
    </a>
    <Transition name="scale">
      <div v-if="active" class="transition origin-top overflow-hidden">
        <ul class="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
          <slot />
        </ul>
      </div>
    </Transition>

  </li>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CaretIcon from "@/icons/CaretIcon.vue";

const active = ref<boolean>(false)
</script>
