import {ref} from "vue";

export default function useDropdownToggle(focusedElementId: string = '') {
    const isDropdownOpened = ref(false)
    let preventClose = false

    function openDropdown() {
        preventClose = true
        isDropdownOpened.value = !isDropdownOpened.value

        setTimeout(() => {
            if (focusedElementId) document.getElementById(focusedElementId)?.focus()
            preventClose = false
        }, 50)
    }
    function closeDropdown() {
        if (!preventClose) isDropdownOpened.value = false
    }

    return {
        isDropdownOpened,
        openDropdown,
        closeDropdown
    }
}