<template>
  <div class="flex h-screen overflow-hidden" v-if="isUserLoaded">
    <Sidebar />
    <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
      <HeaderComponent />
      <main>
        <AlertBox />
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import Sidebar from "@/shared/Sidebar/Sidebar.vue";
import { onBeforeMount, provide, ref, watch } from "vue";
import HeaderComponent from "@/shared/Header/HeaderComponent.vue";
import AlertBox from "@/shared/Alerts/AlertBox.vue";
import { useUserStore } from "@/stores/user";

const isSidebarVisibile = localStorage.getItem('isSidebarVisible')

const sidebarToggle = ref<boolean>(isSidebarVisibile === 'true')

const isUserLoaded = ref<boolean>(false)

provide('sidebarToggle', sidebarToggle)

watch(sidebarToggle, isVisible => localStorage.setItem('isSidebarVisible', String(isVisible)))

const userStore = useUserStore()

onBeforeMount(() => {
  isUserLoaded.value =  userStore.loadUser()
})

</script>
