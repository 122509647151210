<template>
  <div class="datatable-search flex">
    <button
        @click="update"
        class="flex justify-center pl-4 font-medium items-center">
      <SearchIcon class="fill-graydark"/>
    </button>
    <input v-model="modelValue" class="datatable-input" :placeholder="placeholder" type="search" @input="onInput" ref="inputEl">
  </div>
</template>

<script setup lang="ts">
import SearchIcon from "@/icons/SearchIcon.vue";
import debounce from "@/helpers/debounce";
import {ref, type Ref} from "vue";

defineProps<{
  placeholder: string
}>()

const modelValue: Ref<string> = defineModel({ required: true })


const emit = defineEmits(['set:search']);

const inputEl = ref<HTMLInputElement>()

const onInput = debounce(update, 300)

function update() {
  emit('set:search', inputEl.value?.value)
}

</script>
