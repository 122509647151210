<template>
  <GuestLayout>
    <PageTitle :breadcrumbs="{ url: '/', prev: 'Dashboard', current: 'Sign Up'}">
      Авторизация
    </PageTitle>
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div class="flex flex-wrap items-center">
        <div class="hidden w-full xl:block xl:w-1/2 xl:border-r-2 border-stroke dark:border-strokedark">
          <div class="py-17.5 px-26 text-center">
            <RouterLink to="/" class="mb-5.5 inline-block">
              <Logo style="width: 150px"/>
            </RouterLink>
            <p class="font-medium 2xl:px-20">Для дальнейшей работы Вам необходимо авторизоваться</p>
            <span class="mt-15 inline-block">
              <LoginImage/>
            </span>
          </div>
        </div>

        <div class="w-full xl:w-1/2">
          <div class="w-full p-4 sm:p-12.5 xl:p-17.5">
            <form @submit.prevent="login">
              <h2 class="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">Авторизуйтесь</h2>

              <LoginTextInput v-model="form.email" label="Логин" placeholder="Введите свой логин" type="email" class="mb-4" :errors="errors.email">
                <UserIcon class="fill-current opacity-50"/>
              </LoginTextInput>

              <LoginTextInput v-model="form.password" label="Пароль" placeholder="Введите свой пароль" type="password" class="mb-6" :errors="errors.password">
                <LockIcon class="fill-current"/>
              </LoginTextInput>

              <StandardButton type="submit" class="w-full py-4 px-4">Войти</StandardButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  </GuestLayout>
</template>

<script setup lang="ts">
import GuestLayout from "@/layouts/GuestLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import Logo from "@/icons/Logo.vue";
import LoginImage from "@/shared/Images/LoginImage.vue";
import LoginTextInput from "@/shared/Inputs/LoginTextInput.vue";
import {reactive, toRaw} from "vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import LockIcon from "@/icons/LockIcon.vue";
import UserIcon from "@/icons/UserIcon.vue";
import {useUserStore} from "@/stores/user";
import type {LoginCredentials} from "@/types/requests";
import {useRouter} from "vue-router";

const form = reactive<LoginCredentials>({
  email: '',
  password: ''
})

const errors = reactive<Record<string, string[]>>({
  email: [],
  password: []
})

const userStore = useUserStore()
const router = useRouter()

if (userStore.hasToken()) {
  router.push('/')
}

async function login() {
  clearErrors()

  const isLogged = await userStore.login(toRaw(form))
  if (isLogged) router.push('/')

  errors.email.push('Логин не найден')
  errors.password.push('Введен неверный пароль. Повторите попытку')
}

function clearErrors() {
  errors.email = []
  errors.password = []
}
</script>
