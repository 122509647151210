<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ url: '/products', prev: 'Товарные тизеры', current: 'Копирование товарного тизера' }">
        Копирование товарного тизера
      </PageTitle>
      <ProductTeaserCopyCard v-if="data" :creative="data"/>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import ProductTeaserCopyCard from "@/shared/Cards/ProductTeaserCopyCard.vue";



import { useRoute, useRouter } from "vue-router";
import { useApi } from "@/stores/api";
import { ref } from "vue";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";
import type { ProductTeaserData  } from "@/types/props";

const route = useRoute()
const router = useRouter()
const api = useApi()

const data = ref<ProductTeaserData>()
getData()

function getData() {
  api.products.product(Number(route.params.id))
    .then(response => {
      data.value = response.creative
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}
</script>
