<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
      <PageTitle :breadcrumbs="{ url: '/templates', prev: 'Шаблоны кампаний', current: 'Редактирование шаблона' }">
        Редактирование шаблона настроек кампаний
      </PageTitle>

      <TemplateEditCard v-if="data" :template="data" />
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import TemplateEditCard from "@/shared/Cards/TemplateEditCard.vue";
import { useRoute, useRouter } from "vue-router";
import { useApi } from "@/stores/api";
import { ref } from "vue";
import { UserServiceForbiddenError } from "@/errors/UserServiceForbiddenError";
import type { Template } from "@/types/entities";

const route = useRoute()
const router = useRouter()
const api = useApi()

const data = ref<Template>()
getData()

function getData() {
  api.sources.template(Number(route.params.id))
    .then(response => {
      data.value = response.campaignSettingsTemplate
    })
    .catch(err => {
      if (err instanceof UserServiceForbiddenError) router.push('/403')
    })
}

</script>
