import type { SortOrder, TableProps, TableRow } from "@/types/props";
import getQueryParam from "@/helpers/getQueryParam";
import { computed, reactive, ref, toRaw, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useAlerts } from "@/stores/alert";


export default function useStandardTable(props: TableProps, emit: (event: "refresh:rows", ...args: any[]) => void) {
    const router = useRouter()
    const alerts = useAlerts()

    const filteredRows = ref<TableRow[]>(props.rows)
    const sortedRows = ref<TableRow[]>(props.rows)
    const slicedRows = ref<TableRow[]>([])

    const rowCount = computed(() => props.rowCount ? props.rowCount :sortedRows.value.length)
    const maxPage = computed(() => props.rowCount ? Math.ceil(props.rowCount / props.perPage) : Math.ceil(sortedRows.value.length / props.perPage))
    const defaultSort = props.defaultSort ?? ''
    const defaultOrder = props.defaultSort ? 'desc' : ''

    const form = reactive({
        search: getQueryParam('search', ''),
        sort: getQueryParam('sort', defaultSort),
        order: <SortOrder>getQueryParam('order', defaultOrder),
        page: getPage()
    })

    // to replace out of bounds page numbers
    // router.replace({ query: toRaw(form) })

    const searchValue = ref(form.search)

    function setSort(column?: string): void {
        if (!column) return
        toggleSortOrder(column)
        form.sort = column

        router.push({ query: toRaw(form) })
    }

    function toggleSortOrder(column: string): void {
        if (column !== form.sort) {
            form.order = 'desc'
        } else {
            form.order = form.order === 'asc' ? 'desc' : 'asc'
        }
    }

    function setPage(page: number): void {
        form.page = page
        router.push({ query: toRaw(form) })
    }

    function setSearch(search: string): void {
        form.search = search
        form.page = 1
        router.push({ query: toRaw(form) })
    }

    function filter(): TableRow[] {
        return props.rows.filter(row => {
            if (props.searchFields === undefined) return true

            for (const field of props.searchFields) {
                const re = new RegExp(`${form.search.toLowerCase().trim().replace(/[её]/, '[её]')}`)

                if (
                    row[field] !== undefined &&
                    row[field] &&
                    row[field].toString().toLowerCase().search(re) !== -1
                ) {
                    return true
                }
            }

            return false
        })
    }

    function sort(): TableRow[] {
        const rows = form.search ? filteredRows.value : props.rows

        if (props.serversideSort) return rows

        rows.sort((item1, item2) => {
            if (item1[form.sort] < item2[form.sort]) return -1
            if (item1[form.sort] > item2[form.sort]) return 1
            return 0
        })

        return form.order === 'desc' ? rows.reverse() : rows
    }

    function paginateSortedRows(): TableRow[] {
        if(props.serversidePagination){
            return sortedRows.value
        }
        const offset = props.perPage * (form.page - 1)
        const end = offset + props.perPage > props.rows.length ? props.rows.length : offset + props.perPage
        return props.perPage === 0 ? sortedRows.value : sortedRows.value.slice(offset, end)
    }

    function getPage(): number {
        let page = getQueryParam('page', 1, true)
        if (page > maxPage.value && maxPage.value !== 0) return maxPage.value
        if (page < 1) return 1

        return page
    }

    watchEffect(() => {
        filteredRows.value = filter()
        sortedRows.value = sort()
        slicedRows.value = paginateSortedRows()
    })

    window.onpopstate = () => {
        const page = getQueryParam('page', 1, true)

        form.search = getQueryParam('search', '')
        searchValue.value = form.search

        form.sort = getQueryParam('sort', '')
        form.order = getQueryParam('order', '')
        form.page = page > maxPage.value ? maxPage.value : page
    }

    return {
        form,
        searchValue,
        slicedRows,
        maxPage,
        rowCount,
        setSearch,
        setSort,
        setPage,
    }
}
