import {reactive, ref, type Ref, watch} from "vue";
import type {SelectOption} from "@/types/props";
import geosToSelectOptions from "@/helpers/geosToSelectOptions";
import type {ManualTopCreativeRequest, ManualTopExistsRequest, ManualTopStoreRequest} from "@/types/requests";
import Validator from "validatorjs";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import {useApi} from "@/stores/api";

export default function useManualTopForm(id: number = 0) {
    const form = reactive({
        description: '',
        geo: '',
        source: '',
        utm_source: '',
        locale: '',
        enabled: false
    })

    const errors = reactive<Record<string, string[]>>({
        description: [],
        geo: [],
        source: [],
        utm_source: [],
        locale: [],
        creatives: []
    })

    const utmSourceOptions: Ref<SelectOption[]> = ref([])
    const teasers: Ref<any[]> = ref([])
    const sourceOptions: Ref<SelectOption[]> = ref([])
    const sourceToUtmSourceMap: Ref<Record<string, string[]>> = ref({})

    const api = useApi()

    const geoOptions = geosToSelectOptions(['BG', 'DE', 'ES', 'HU', 'ID', 'PL', 'PT', 'RO', 'RU', 'SI', 'TH'])
    const localeOptions = geosToSelectOptions(['DE', 'RU', 'HU', 'HU', 'UA', 'RO', 'ID', 'ES', 'PL', 'TH', 'BG', 'SI'])

    api.sources.sourceMap()
        .then(response => {
        sourceToUtmSourceMap.value = response.sources

        for(let source of Object.keys(response.sources)) {
            sourceOptions.value.push({ key: source, value: source })
        }

        if (id > 0) loadManualTop(id)
    })

    const sourceCount = ref(0)

    watch(() => form.source, source => {
        form.utm_source = ''

        if (source) {
            const utmSources = sourceToUtmSourceMap.value.hasOwnProperty(source) ? sourceToUtmSourceMap.value[source] : []
            utmSourceOptions.value = utmSources.map(source => <SelectOption>{ key: source, value: source })

            refreshSourceCount(source)

        } else {
            sourceCount.value = 0
        }
    })

    function formRequest(): ManualTopStoreRequest {
        const creativesRequest: ManualTopCreativeRequest[] = []

        for(let key in teasers.value) {
            creativesRequest.push({
                position: Number(key) + 1,
                id: teasers.value[key].id
            })
        }

        const request: ManualTopStoreRequest = {
            description: form.description,
            geo: form.geo,
            source: form.source,
            enabled: form.enabled,
            creatives: creativesRequest
        }

        if (form.locale) request.locale = form.locale
        if (form.utm_source) request.utm_source = form.utm_source

        return request
    }

    function existsRequest(): ManualTopExistsRequest {
        const request: ManualTopExistsRequest = {
            geo: form.geo,
            source: form.source
        }

        if (form.locale) request.locale = form.locale
        if (form.utm_source) request.utm_source = form.utm_source

        return request
    }

    function validateRequest(request: ManualTopStoreRequest): boolean {
        const validator = new Validator(request, {
            geo: 'required',
            source: 'required',
            description: 'max:255',
            creatives: 'required|array'
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.geo = validator.errors.get('geo')
            errors.source = validator.errors.get('source')
            errors.description = validator.errors.get('description')
            errors.creatives = validator.errors.get('creatives')
        }

        return passes
    }

    function loadManualTop(id: number) {
        api.products.manualTop(id)
            .then(response => {
                form.geo = response.manualTop.geo
                form.enabled = response.manualTop.enabled
                form.description = response.manualTop.description ?? ''
                form.source = response.manualTop.source
                form.locale = response.manualTop.locale ?? ''
                form.utm_source = response.manualTop.utm_source ?? ''

                api.products.creativesOrdered(response.manualTop.creatives)
                    .then(response => {
                        teasers.value = response.creatives
                    })
            })
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    function refreshSourceCount(source: string): void {
        api.products.manualTopCount({ source: source })
            .then(response => {
                sourceCount.value = response.count
            })
    }

    return {
        form,
        errors,
        utmSourceOptions,
        teasers,
        geoOptions,
        localeOptions,
        sourceOptions,
        sourceCount,
        formRequest,
        validateRequest,
        populateErrors,
        existsRequest
    }
}

