<template>
  <Card>
    <template #title>Последний вход</template>
    <div class="font-medium text-sm text-black">
      <p class="mb-4">{{ formatDate(lastLogin.logged_at) }}</p>
      <p class="mb-4">
        IP: {{ lastLogin.ip }}
        <span v-if="lastLogin.geo">Гео: {{ lastLogin.geo }}</span>
      </p>
      <p>Useragent: {{ lastLogin.user_agent }}</p>
    </div>

  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import type {LastLogin} from "@/types/entities";

defineProps<{
  lastLogin: LastLogin
}>()

function formatDate(dateString: string = '') {
  const date = dateString ? new Date(dateString) : new Date();
  return `${date.getDate().toString().padStart(2, '0')}` +
      `.${(date.getMonth() + 1).toString().padStart(2, '0')}` +
      `.${date.getFullYear().toString().padStart(2, '0')}`;
}
</script>
