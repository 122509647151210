<template>
  <div class="w-full flex flex-col gap-8 2xl:flex-row">
    <ManualTopCard
        v-model="form"
        :errors="errors"
        :geo-options="geoOptions"
        :locale-options="localeOptions"
        :source-options="sourceOptions"
        :utm-source-options="utmSourceOptions"
        :source-count="sourceCount"
        class="w-full 2xl:w-1/2"
        button-text="Создать"
        @form:submit="submit"
    />

    <ManualTopTeasersCard v-model="teasers" class="w-full 2xl:w-1/2 h-full"/>


  </div>
</template>

<script setup lang="ts">
import ManualTopTeasersCard from "@/shared/Cards/ManualTopTeasersCard.vue";
import ManualTopCard from "@/shared/Cards/ManualTopCard.vue";
import {useApi} from "@/stores/api";
import {useAlerts} from "@/stores/alert";
import {useRouter} from "vue-router";
import {UserServiceValidationError} from "@/errors/UserServiceValidationError";
import useManualTopForm from "@/composables/useManualTopForm";


const {
  form,
  errors,
  utmSourceOptions,
  teasers,
  geoOptions,
  localeOptions,
  sourceOptions,
  sourceCount,
  formRequest,
  validateRequest,
  populateErrors
} = useManualTopForm()

const alerts = useAlerts()
const api = useApi()
const router = useRouter()

function submit() {
  const request = formRequest()

  if (!validateRequest(request)) return

  api.products.manualTopCreate(request)
    .then(response => {
      alerts.flash('success', 'Ручной топ успешно создан')
      router.push('/manual-tops')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}
</script>
