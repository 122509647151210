import type { SortOrder, GroupedTableProps, TableRow, TableRowGroup } from "@/types/props";
import getQueryParam from "@/helpers/getQueryParam";
import { computed, reactive, ref, toRaw, watchEffect } from "vue";
import { useRouter } from "vue-router";


export default function useGroupedTable(props: GroupedTableProps, emit: (event: "refresh:rows", ...args: any[]) => void) {
    const router = useRouter()

    const groups = ref<TableRowGroup[]>(props.groups)

    const rowCount = computed(() => props.rowCount)
    const maxPage = computed(() => props.disablePagination ? 1 : Math.ceil(props.rowCount / props.perPage))
    const form = reactive({ 
        search: getQueryParam('search', ''),
        sort: getQueryParam('sort', ''),
        order: <SortOrder>getQueryParam('order', ''),
        page: getPage()
    })

    // to replace out of bounds page numbers
    router.replace({ query: toRaw(form) })

    const searchValue = ref(form.search)

    function setSort(column?: string): void {
        if (!column) return
        toggleSortOrder(column)
        form.sort = column

        router.push({ query: toRaw(form) })
    }

    function toggleSortOrder(column: string): void {
        if (column !== form.sort) {
            form.order = 'desc'
        } else {
            form.order = form.order === 'asc' ? 'desc' : 'asc'
        }
    }

    function setPage(page: number): void {
        form.page = page
        router.push({ query: toRaw(form) })
    }

    function setSearch(search: string): void {
        form.search = search
        form.page = 1
        router.push({ query: toRaw(form) })
    }

    function getPage(): number {
        let page = getQueryParam('page', 1, true)
        if (page > maxPage.value) return maxPage.value
        if (page < 1) return 1

        return page
    }

    function sort(): TableRowGroup[] {

        const sortedGroups = props.groups.map((item) => ({
            ...item,
            rows: item.rows.sort((item1, item2) => {
                if (item1[form.sort] < item2[form.sort]) return form.order === 'desc' ? 1 : -1
                if (item1[form.sort] > item2[form.sort]) return form.order === 'desc' ? -1 : 1
                return 0
            })
        }))

        return sortedGroups
    }

    watchEffect(() => {
        groups.value = sort()
    })

    window.onpopstate = () => {
        const page = getQueryParam('page', 1, true)

        form.search = getQueryParam('search', '')
        searchValue.value = form.search

        form.sort = getQueryParam('sort', '')
        form.order = getQueryParam('order', '')
        form.page = page > maxPage.value ? maxPage.value : page
    }

    return {
        form,
        searchValue,
        maxPage,
        rowCount,
        groups,
        setSearch,
        setSort,
        setPage,
    }
}
