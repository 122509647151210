<template>
  <div class="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear h-full">
    <nav class="mt-5 py-4 px-4 lg:mt-7 lg:px-6 flex flex-col justify-between h-full">
      <div>
        <SidebarMenuGroup title="Тизеры">
          <SidebarMenuSubGroup>
            <template #groupName>
              <ServerIcon class="fill-current" />Новостные
            </template>
            <SidebarMenuSubGroupLink to="/teasers/all">Все</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/teasers">Мои тизеры</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/teasers/active">Активные тизеры</SidebarMenuSubGroupLink>
          </SidebarMenuSubGroup>
          <SidebarMenuSubGroup>
            <template #groupName>
              <ServerIcon class="fill-current" />Товарные
            </template>
            <SidebarMenuSubGroupLink to="/products">Все</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/manual-tops">Ручные топы</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/themes">Тематики</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/streams">Потоки</SidebarMenuSubGroupLink>
          </SidebarMenuSubGroup>

          <SidebarMenuSubGroup>
            <template #groupName>
              <PauseIcon class="fill-current" />Остановленные
            </template>
            <SidebarMenuSubGroupLink to="/teasers/stopped">Все</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/teasers/stopped/rejected">Не прошли модерацию</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/teasers/rules">Правила</SidebarMenuSubGroupLink>
            <SidebarMenuSubGroupLink to="/teasers/binds">Привязки</SidebarMenuSubGroupLink>
          </SidebarMenuSubGroup>
        </SidebarMenuGroup>

        <SidebarMenuGroup title="Пользователи" v-if="userStore.canOneOf([
          { section: 'users', permission: 'read' },
          { section: 'users', permission: 'personnel_read' },
          { section: 'roles', permission: 'read' },
          { section: 'roles', permission: 'personnel_read' }
        ])">
          <SidebarMenuGroupLink to="/users/" v-if="userStore.canOneOf([
          { section: 'users', permission: 'read' },
          { section: 'users', permission: 'personnel_read' }
        ])">
            <FriendsIcon class="fill-current" />Пользователи
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/roles/" v-if="userStore.canOneOf([
          { section: 'roles', permission: 'read' },
          { section: 'roles', permission: 'personnel_read' }
        ])">
            <NetworkIcon class="fill-current" />Роли
          </SidebarMenuGroupLink>
        </SidebarMenuGroup>

        <SidebarMenuGroup title="Источники">
          <SidebarMenuGroupLink to="/sources">
            <UserKeyIcon class="fill-current" />Список источников
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/templates">
            <UserKeyIcon class="fill-current" />Шаблоны кампаний
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/links">
            <UserKeyIcon class="fill-current" />Ссылки
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/accounts">
            <UserKeyIcon class="fill-current" />Аккаунты в источниках
          </SidebarMenuGroupLink>
        </SidebarMenuGroup>

        <SidebarMenuGroup title="Инструменты">
          <SidebarMenuGroupLink to="/rotation-emulator">
            <LaptopPhoneIcon class="fill-current" />Эмулятор топа
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/spy">
            <EyeIcon class="fill-current" />SPY
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/domains">
            <ServerIcon class="fill-current"/>Домены
          </SidebarMenuGroupLink>
        </SidebarMenuGroup>

        <SidebarMenuGroup title="Push">
          <SidebarMenuGroupLink to="/notifications/list">
            <ListIcon class="fill-current" />Список
          </SidebarMenuGroupLink>
          <SidebarMenuGroupLink to="/notifications/add">
            <ListIcon class="fill-current" />Добавить в очередь
          </SidebarMenuGroupLink>
        </SidebarMenuGroup>
      </div>

      <RouterLink to="/login" @click="userStore.logout"
        class="group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4"
        active-class="bg-graydark dark:bg-meta-4">
        <EnterIcon class="fill-current" />Выход
      </RouterLink>
    </nav>
  </div>
</template>

<script setup lang="ts">
import SidebarMenuGroup from "@/shared/Sidebar/SidebarMenuGroup.vue";
import SidebarMenuSubGroup from "@/shared/Sidebar/SidebarMenuSubGroup.vue";
import SidebarMenuSubGroupLink from "@/shared/Sidebar/SidebarMenuSubGroupLink.vue";
import SidebarMenuGroupLink from "@/shared/Sidebar/SidebarMenuGroupLink.vue";
import ServerIcon from "@/icons/ServerIcon.vue";
import PauseIcon from "@/icons/PauseIcon.vue";
import FriendsIcon from "@/icons/FriendsIcon.vue";
import NetworkIcon from "@/icons/NetworkIcon.vue";
import ListIcon from "@/icons/ListIcon.vue";
import LaptopPhoneIcon from "@/icons/LaptopPhoneIcon.vue";
import EnterIcon from "@/icons/EnterIcon.vue";
import { useUserStore } from "@/stores/user";
import UserKeyIcon from "@/icons/UserKeyIcon.vue";
import SitemapIcon from "@/icons/SitemapIcon.vue";
import EyeIcon from "@/icons/EyeIcon.vue";
const userStore = useUserStore()
</script>
