<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
        <PageTitle :breadcrumbs="{ current: 'Роли' }">
          Роли
        </PageTitle>
        <RolesTable/>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import AuthLayout from "@/layouts/AuthLayout.vue";
import PageTitle from "@/shared/PageTitle.vue";
import RolesTable from "@/shared/Tables/RolesTable.vue";
import {useUserStore} from "@/stores/user";
import {onBeforeMount, onBeforeUnmount} from "vue";
import {useRoleForm} from "@/stores/roleForm";

const userStore = useUserStore()
onBeforeMount(() => userStore.guardMany([
  {section: 'roles', permission: 'read'},
  {section: 'roles', permission: 'personnel_read'}
]))

const roleForm = useRoleForm()
roleForm.loadSections()

onBeforeUnmount(roleForm.clear)

</script>
