import useRequestHandler from "@/composables/api/useRequestHandler";
import spyClient from "@/api/spyClient";
import {ref} from "vue";
import type {SpyListRequest} from "@/types/requests";
import type {getSpyListResponse, getSpyOptionsResponse} from "@/types/responses";
import routes from "@/api/routes";
import {Method} from "@/enums/Method";

export default function useSpyApi() {
    const { handleRequest } = useRequestHandler(spyClient, 'spy')

    const spy = ref({
        list: (request: SpyListRequest) => <Promise<getSpyListResponse>>handleRequest(routes.spy.list, Method.GET, {}, request),
        options: () => <Promise<getSpyOptionsResponse>>handleRequest(routes.spy.options)
    })

    return {
        spy
    }
}
