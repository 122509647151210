<template>
  <AuthLayout>
    <div class="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-8 2xl:pt-14 2xl:px-8">
        <PageTitle :breadcrumbs="{ url: '/users', prev: 'Пользователи', current: 'Редактирование' }">
          Редактирование пользователя
        </PageTitle>

        <div class="grid grid-cols-5 gap-8">
          <div class="col-span-5 xl:col-span-3">
            <UserEditCard v-if="user" :user="user"/>
          </div>

          <div class="col-span-5 xl:col-span-2 flex flex-col gap-4">
            <RoleInfoCard v-if="permissions" :permissions="permissions" :role-name="roleName" :id="roleId"/>
          </div>
        </div>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import PageTitle from "@/shared/PageTitle.vue";
import UserEditCard from "@/shared/Cards/UserEditCard.vue";
import RoleInfoCard from "@/shared/Cards/RoleInfoCard.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import useUserInfo from "@/composables/useUserInfo";
import {useUserStore} from "@/stores/user";
import {onBeforeMount, onMounted, watchEffect} from "vue";
import {useRouter} from "vue-router";

const {
  user,
  userId,
  roleId,
  roleName,
  permissions
} = useUserInfo()

const router = useRouter()

const userStore = useUserStore()
onBeforeMount(() => {
  if (userId === 1 && !userStore.isSuperAdmin()) router.push('/403')
  userStore.guardPersonal(userId, 'users', 'edit')
})

watchEffect(() => {
  if (roleId.value === 2 && (!userStore.isSuperAdmin() && userId !== userStore.user?.id)) router.push('/403')
})

</script>
