import {defineStore} from "pinia";
import {ref} from "vue";
import {useApi} from "@/stores/api";
import type {LoggedUser} from "@/types/entities";
import type {LoginCredentials} from "@/types/requests";
import {useRouter} from "vue-router";
import type {UserServiceRefreshJWT, UserServiceAccessJWT} from "@/types/responses";
import type {PermissionSectionKey, PermissionType} from "@/types/props";

export const useUserStore = defineStore('user', () => {
    const user = ref<LoggedUser | null>(null)
    const permissions = ref<string[] | null>(null)
    const childrenUserIds = ref<number[]>([])

    const api = useApi()

    const router = useRouter()

    async function login(credentials: LoginCredentials): Promise<boolean> {
        try {
            const data = await api.user.login(credentials)

            localStorage.setItem('casAccessToken', data.token)
            localStorage.setItem('casRefreshToken', data.refresh)
            restoreUserFromJwt(parseJwt<UserServiceAccessJWT>(data.token))

            return true
        } catch (e) {
            return false
        }
    }

    function logout() {
        user.value = null
        permissions.value = null
        localStorage.removeItem('casAccessToken')
        localStorage.removeItem('casRefreshToken')
        router.push('/login')
    }

    function loadUser(): boolean {
        if (user.value && permissions.value) return true

        const casJwt = localStorage.getItem('casAccessToken')
        let isRestored = false

        if (casJwt) {
            isRestored = restoreUserFromJwt(parseJwt<UserServiceAccessJWT>(casJwt))
        }

        if (!isRestored) refreshAuth()
        return isRestored
    }

    function hasToken(): boolean {
        return localStorage.getItem('casAccessToken') !== null
    }

    function parseJwt<TranslatedTokenType>(token: string): TranslatedTokenType {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    function restoreUserFromJwt(parsedJwt: UserServiceAccessJWT): boolean {
        const unixSecondsNow = Math.floor(Date.now() / 1000)
        if (unixSecondsNow > parsedJwt.exp) {
            return false
        }

        const jwtUser = parsedJwt.data.user
        user.value = {
            id: jwtUser.id,
            name: jwtUser.name,
            email: jwtUser.email,
            telegram: jwtUser.telegram,
            role_id: jwtUser.role_id,
            role_name: jwtUser.role_name,
            created_at: jwtUser.created_at,
            updated_at: jwtUser.updated_at,
            deleted_at: jwtUser.deleted_at
        }
        permissions.value = parsedJwt.data.permissions
        childrenUserIds.value = parsedJwt.data.subordinates

        return true
    }

    function refreshAuth(): void {
        const refreshToken = localStorage.getItem('casRefreshToken')
        if (refreshToken === null) {
            logout()
            return
        }

        const parsedToken = parseJwt<UserServiceRefreshJWT>(refreshToken)
        const unixSecondsNow = Math.floor(Date.now() / 1000)
        if (unixSecondsNow > parsedToken.exp) {
            logout()
            return
        }

        localStorage.removeItem('casAccessToken')
        api.user.refresh({ token: refreshToken })
            .then(response => {
                localStorage.setItem('casAccessToken', response.token)
                restoreUserFromJwt(parseJwt<UserServiceAccessJWT>(response.token))
                router.go(0)
            })
            .catch(e => {
                console.log(e)
                logout()
            })
    }

    function can(section: PermissionSectionKey, permission: PermissionType): boolean {
        if (permissions.value === null) return false

        const generalKey = `general-${permission}`
        const permissionKey = `${section}-${permission}`

        return permissions.value.includes(generalKey) || permissions.value.includes(permissionKey)
    }

    function canOneOf(permissionList: { section: PermissionSectionKey, permission: PermissionType }[]): boolean {
        for(const permission of permissionList) {
            if (can(permission.section, permission.permission)) return true
        }

        return false
    }

    function canPersonal(userId: number, section: PermissionSectionKey, permission: 'edit' | 'delete'): boolean {
        if (permissions.value === null || user.value === null) return false

        return (childrenUserIds.value.includes(userId) && can(section, `personnel_${permission}`)) || can(section, permission)
    }

    function guard(section: PermissionSectionKey, permission: PermissionType): void {
        loadUser()

        if (!can(section, permission)) router.push('/403')
    }

    // if at least one of the permissions is satisfied, then it's ok to go ahead
    function guardMany(permissionList: { section: PermissionSectionKey, permission: PermissionType }[]): void {
        loadUser()

        if (canOneOf(permissionList)) return

        router.push('/403')
    }

    function guardPersonal(userId: number, section: PermissionSectionKey, permission: 'edit' | 'delete'): void {
        loadUser()

        if (!canPersonal(userId, section, permission)) router.push('/403')
    }

    function isAdmin(): boolean {
        return user?.value?.role_id === 2
    }

    function isSuperAdmin(): boolean {
        return user?.value?.id === 1
    }

    return {
        user,
        permissions,
        childrenUserIds,
        login,
        logout,
        loadUser,
        hasToken,
        can,
        canOneOf,
        canPersonal,
        guard,
        guardMany,
        guardPersonal,
        isAdmin,
        isSuperAdmin
    }
})
