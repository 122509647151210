<template>
  <svg width="23" height="22" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.98828 13.4253C10.0508 13.4253 11.7383 11.7378 11.7383 9.67529C11.7383 7.61279 10.0508 5.92529 7.98828 5.92529C5.92578 5.92529 4.23828 7.61279 4.23828 9.67529C4.23828 11.7378 5.92578 13.4253 7.98828 13.4253ZM7.98828 7.61279C9.11328 7.61279 10.0508 8.55029 10.0508 9.67529C10.0508 10.8003 9.11328 11.7378 7.98828 11.7378C6.86328 11.7378 5.92578 10.8003 5.92578 9.67529C5.92578 8.55029 6.86328 7.61279 7.98828 7.61279Z"
      fill-rule="evenodd" clip-rule="evenodd" />
    <path
      d="M7.98831 14.063C5.4383 14.063 2.9633 14.963 1.0508 16.5755C0.713305 16.8755 0.638305 17.4005 0.938305 17.7755C1.2383 18.113 1.7633 18.188 2.1383 17.888C3.7508 16.5005 5.85081 15.7505 7.98831 15.7505C10.1258 15.7505 12.2258 16.5005 13.8383 17.888C13.9883 18.038 14.1758 18.0755 14.4008 18.0755C14.6258 18.0755 14.8883 17.963 15.0383 17.7755C15.3383 17.438 15.3008 16.8755 14.9258 16.5755C13.0133 14.963 10.5383 14.063 7.98831 14.063Z"
      fill-rule="evenodd" clip-rule="evenodd" />
    <path
      d="M22.6133 13.688H18.7883C18.3383 13.688 17.9258 14.063 17.9258 14.5505C17.9258 15.038 18.3008 15.413 18.7883 15.413H22.6133C23.0633 15.413 23.4758 15.038 23.4758 14.5505C23.4758 14.063 23.0633 13.688 22.6133 13.688Z"
      fill="" />
    <defs>
      <clipPath id="clip0_730_1265">
        <rect width="24" height="24" fill="white" transform="translate(0.113281 0.000488281)" />
      </clipPath>
    </defs>
  </svg>
</template>
