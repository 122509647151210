<template>
  <Card>
    <template #title>
      <div class="flex justify-between items-center">
        <span>Тизеры в топе</span>
        <a href="#" @click="openAddModal" class="text-primary font-light hover:underline">Добавить тизеры</a>
      </div>
    </template>

    <ManualTopTeaserList v-if="teasers.length" v-model="teasers"/>
    <p v-else>Добавьте тизеры</p>

    <ManualTopAddTeaserModal
        v-model="isModalVisible"
        @teasers:add="addTeasers"
        @teasers:replace="replaceTeasers"
    />
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import ManualTopTeaserList from "@/shared/Lists/ManualTopTeaserList.vue";
import ManualTopAddTeaserModal from "@/shared/Modals/ManualTopAddTeaserModal.vue";
import {type Ref, ref} from "vue";
import type {ManualTopTeaser} from "@/types/props";
import {useApi} from "@/stores/api";

const teasers: Ref<ManualTopTeaser[]> = defineModel({ required: true })

const isModalVisible = ref(false)

const api = useApi()

function openAddModal() {
  isModalVisible.value = true
}

function addTeasers(ids: number[]) {
  api.products.creativesOrdered(ids)
    .then(response => {
      teasers.value = [...response.creatives, ...teasers.value]
    })
}

function replaceTeasers(ids: number[]) {
  api.products.creativesOrdered(ids)
    .then(response => {
      teasers.value = response.creatives
    })
}
</script>
