<template>
  <div :class="class" class="relative">
    <div v-if="image" :class="{'border-red': errors?.length}" class="absolute top-1 right-1 z-9 flex gap-2 opacity-90 ">
      <IconButton v-if="image && !image.is_ready" @click="zoom(0.5)" class="items-center  bg-[#888888]">
        <MinusIcon class="fill-white transition" width="15" height="15" />
      </IconButton>
      <IconButton v-if="image && !image.is_ready" @click="zoom(2)" class="items-center  bg-[#888888]">
        <PlusIcon class="fill-white transition" width="15" height="15" />
      </IconButton>
      <IconButton @click="clearImage" class="items-center  bg-red text-primary" :class="{'cursor-not-allowed':disabled}">
        <TimesIcon class="fill-white transition" width="15" height="15" />
      </IconButton>
      <IconButton v-if="image && !image.is_ready" @click="apply" class="items-center  bg-[#33bb33]">
        <CheckmarkIcon class="fill-white transition" width="15" height="15" />
      </IconButton>
    </div>
    <cropper v-if="image && !image.is_ready" ref="firstCropper" :class="`w-full h-full`" :src="image.src"
      :min-width:="500" :min-height:="500" :stencil-size="{
        width: 1000,
        height: 1000 / aspectRatio,
      }" :default-size="{
        width: 500,
        height: 500 / aspectRatio
      }" :default-position="image.result && image.result.coordinates" :stencil-props="{
        handlers: {},
        movable: false,
        resizable: false,
        aspectRatio: aspectRatio,
      }" image-restriction="stencil">
    </cropper>
    <img v-if="image && image.is_ready && image.result" ref="firstCropper" :class="`w-full h-full`"
      :src="image.result.image" />

    <button v-if="!image" class="w-[100%] h-[100%] bg-gray text-sm" @click="selectFile()">Выберите файл <br> Размер от
      {{ sizeInfo }} пикселей <br> Разрешение jpg/png/webp</button>

  </div>

  <input v-if="!image" type="file" ref="file" @change="loadImage($event)" accept="image/webp;image/png" hidden>

</template>

<script setup lang="ts">
import { type Ref, ref, useSlots } from 'vue'
import type { AlphaNumerical, CropImage } from "@/types/props";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
import IconButton from '../Buttons/IconButton.vue';
import CheckmarkIcon from '@/icons/CheckmarkIcon.vue';
import TimesIcon from '@/icons/TimesIcon.vue';
import MinusIcon from '@/icons/MinusIcon.vue';
import PlusIcon from '@/icons/PlusIcon.vue';
const firstCropper = ref();
const file = ref();

const props = defineProps<{
  aspectRatio: number
  class: string
  sizeInfo: string
  errors?: string[]
  syncCroppers?: boolean
  disabled?:boolean
}>()

function clearImage() {
  if(props.disabled){
    return
  }
  if (image.value) {
    if (image.value.is_ready && !image.value.is_copy) {
      image.value.is_ready = false
    }
    else {
      image.value = null
      if (props.syncCroppers) {
        syncObject.value = null
      }
    }
  }
}

function zoom(val: number) {
  if (firstCropper) {
    firstCropper.value.zoom(val)
  }
}


function apply() {
  const resultImage = firstCropper.value.getResult().canvas.toDataURL();
  if (image.value) {
    image.value.result = { image: resultImage, coordinates: firstCropper.value.coordinates }
    image.value.is_ready = true
  }
}

function selectFile() {
  if (file)
    file.value.click();
}

function loadImage(event: Event) {
  const { files } = event.target as HTMLInputElement;
  if (files && files[0]) {
    if (image.value) {
      URL.revokeObjectURL(image.value.src)
    }
    const blob = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      image.value = {
        src: blob,
        type: files[0].type,
      };
      if (props.syncCroppers) {
        syncObject.value = {
          src: blob,
          type: files[0].type,
        };
      }
    };
    reader.readAsArrayBuffer(files[0]);
  }
}



const image: Ref<CropImage | null> = defineModel('image',{ required: true })
const syncObject: Ref<CropImage | null | undefined> = defineModel('syncObject',{ required: false })

</script>

<style></style>