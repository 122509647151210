<template>
  <div class="w-full flex flex-col gap-8 2xl:flex-row">
    <Card class="w-full sm:w-full">
      <template #title>Базовые настройки</template>

      <div class="mb-5.5 flex flex-col gap-5.5 w-full">
        <TextInput
            v-model="form.title"
            label="Заголовок"
            placeholder="Заголовок"
            parameter="title"
            class="w-full"
            :errors="errors.title"
        />

        <TextInput
            v-model="form.description"
            label="Описание"
            placeholder="Описание"
            parameter="description"
            class="w-full"
            :errors="errors.description"
        />

        <SelectInput
            v-model="form.locale"
            :options="localeOptions"
            parameter="locale"
            placeholder="Выберите из списка"
            label="Локализация"
            :errors="errors.locale"
        />

        <div>
          <label class="mb-3 block font-medium text-black dark:text-white">Гео</label>
          <SearchMultiSelect
              v-model="form.geo"
              parameter="geos"
              :options="geoOptions"
              placeholder="Выберите из списка"
              :errors="errors.geo"
              class="w-full"
          >
            <GeoIcon />
          </SearchMultiSelect>
        </div>

        <div>
          <SelectInput
              v-model="form.stream_id"
              :options="streamOptions"
              parameter="stream"
              placeholder="Выберите из списка"
              label="Поток"
              :errors="errors.stream_id"
              :disabled="!form.geo.length" type="number"
          />
        </div>

        <SelectInput
            v-model="form.rating"
            :options="ratingOptions"
            parameter="rating"
            placeholder="Выберите из списка"
            label="Рейтинг"
            :errors="errors.rating"
            type="number"
        />

        <SearchMultiSelect
            v-model="form.manual_tops"
            parameter="manual_tops"
            :options="topOptions"
            label="Ручные топы"
            :placeholder="Object.keys(topOptions).length === 0 ? 'Выберите гео и локализацию' : 'Выберите из списка'"
            :errors="errors.manual_tops"
            :disabled="Object.keys(topOptions).length === 0"
        />

        <Checkbox v-model="form.exclude_rotation" label="Отключить обычную ротацию" />

      </div>
      <div class=" gap-4.5 mt-5.5 hidden 2xl:flex">
        <RouterLink to="/products">
          <ClearButton>Отмена</ClearButton>
        </RouterLink>

        <StandardButton @click="submit">Сохранить</StandardButton>
      </div>
    </Card>


    <Card class="w-full h-[100%] sm:w-full mt-10 2xl:mt-0">
      <template #title>Изображения</template>
      <label class="mb-3 block font-medium text-black dark:text-white">
        Загрузка изображения по URL
      </label>
      <div class="mb-5.5 flex flex-row  gap-2 lg:w-full xl:w-7/8">
        <TextInput v-model="img_link" placeholder="Ссылка" parameter="img_link" class="w-full"/>
        <StandardButton @click="selectImage">Загрузить</StandardButton>
      </div>

      <div class="flex flex-col lg:flex-row gap-5.5 lg:w-full xl:w-7/8">
        <ImageCrop
            :errors="errors.image_sqr"
            :sync-croppers="true"
            v-model:image="form.image_sqr"
            v-model:sync-object="syncObject"
            :class="`w-[350px] h-[350px] lg:w-[200px] lg:h-[200px] xl:w-[300px] xl:h-[300px]`"
            :aspect-ratio="1"
            size-info="500x500"
        />
        <ImageCrop
            :errors="errors.image_rect"
            :sync-croppers="true"
            v-model:image="form.image_rect"
            v-model:sync-object="syncObject"
            :class="`w-[350px] h-[210px] lg:w-[200px] lg:h-[120px] xl:w-[300px] xl:h-[180px]`"
            :aspect-ratio="5 / 3"
            size-info="500x300"
        />
      </div>
      <p v-if="errors.images.length" class="text-red mt-2">{{ errors.images.join(', ') }}</p>

      <div class="flex gap-4.5 mt-5.5 2xl:hidden">
        <RouterLink to="/products">
          <ClearButton>Отмена</ClearButton>
        </RouterLink>

        <StandardButton @click="submit">Сохранить</StandardButton>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import TextInput from "@/shared/Inputs/TextInput.vue";
import Card from "@/shared/Card.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";
import { useApi } from "@/stores/api";
import { useAlerts } from "@/stores/alert";
import { ref, watch } from "vue";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import type {CropImage, MultiSelectOptions, SelectOption} from "@/types/props";
import SelectInput from "../Inputs/SelectInput.vue";
import useProductTeaserForm from "@/composables/useProductTeaserForm";
import SearchMultiSelect from "../Inputs/SearchMultiSelect.vue";
import GeoIcon from "@/icons/GeoIcon.vue";
import Checkbox from "../Inputs/Checkbox.vue";
import ImageCrop from "../Inputs/ImageCrop.vue"
import { type ManualTop } from "@/types/responses";


const api = useApi()
const alertStore = useAlerts()
const router = useRouter()
const userStore = useUserStore()


const {
  form,
  errors,
  validateForm,
  clearErrors,
  populateErrors,
  prepareToRequest
} = useProductTeaserForm()


const localeOptions = ref<SelectOption[]>([])
const streamOptions = ref<SelectOption[]>([])
const geoOptions = ref<string[]>([])
const img_link = ref<string>('')
const syncObject = ref<CropImage | null>(null)

const ratingOptions = ref<SelectOption[]>([
  { key: 1, value: '1(шок)' },
  { key: 2, value: '2' },
  { key: 3, value: '3' },
  { key: 4, value: '4' },
  { key: 5, value: '5(белый)' },
])

const topOptions = ref<MultiSelectOptions>({})
const rawManualTops = ref<ManualTop[]>([])

watch(syncObject, (sync) => {
  if (sync) {
    form.image_rect = { src: sync.src, type: sync.type }
    form.image_sqr = { src: sync.src, type: sync.type }
  } else {
    form.image_rect = sync
    form.image_sqr = sync
  }

}, { deep: true }
)

watch(form.geo, () => {
  api.products.streams({ geos: form.geo }).then(({streams}) => {
    streamOptions.value = streams.map((stream) => ({ key: stream.id, value: stream.name }))
    if (!streams.some((stream) => stream.id === form.stream_id)) {
      form.stream_id = 0
    }
  })

  filterManualTops()
}, { deep: true }
)

watch(() => form.locale, () => filterManualTops)

function filterManualTops() {
  topOptions.value = {}

  const manualTops = rawManualTops.value.filter(manualTop => {
    if (form.geo.includes(manualTop.geo)) {
      return !form.locale || manualTop.locale === form.locale
    }

    return false
  })

  manualTops.forEach(manualTop => {
    topOptions.value[manualTop.id] = 'ID:' + [manualTop.id, manualTop.key, manualTop.description].join(' ')
  })
}

function selectImage() {
  fetch(img_link.value)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      form.image_rect = { src: url }
      form.image_sqr = { src: url }
    });
}

api.products.productCreateOptions().then(({ geos, locales, manualTops }) => {
  geoOptions.value = geos;
  localeOptions.value = locales.map((item) => { return { key: item, value: item } })
  rawManualTops.value = manualTops
})

async function submit() {
  clearErrors()
  if (!validateForm() || !userStore.user) return
  let request = await prepareToRequest()
  api.products.productCreate(request)
    .then(response => {
      alertStore.flash('success', 'Товарный тизер успешно создан')
      router.push('/products')
    })
    .catch(err => {
      if (err instanceof UserServiceValidationError) {
        populateErrors(err)
      }
    })
}

</script>
