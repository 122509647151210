<template>
  <Card>
    <div class="flex justify-between items-center">
      <h2 class="text-title-md font-bold text-black dark:text-white">
        {{ roleName }}
      </h2>
      <IconButton
          @click="triggerEdit"
          v-if="notDefaultOrAdmin() && canEdit"
      >
        <PencilWithoutPaperIcon class="fill-current" width="25" height="25" />
      </IconButton>
    </div> 
    <div v-if="parent">
      <p class="font-bold text-black mt-4">
        Родительская роль
      </p>
      <p>
        {{ parent.name }}
      </p>
    </div>
  </Card>
</template>

<script setup lang="ts">
import Card from "@/shared/Card.vue";
import IconButton from "@/shared/Buttons/IconButton.vue";
import PencilWithoutPaperIcon from "@/icons/PencilWithoutPaperIcon.vue";
import type { Role } from "@/types/entities";
import {useUserStore} from "@/stores/user";

const props = defineProps<{
  roleName: string
  id: number
  parent: Role | null
  canEdit: boolean
}>()

const emit = defineEmits(['edit:trigger'])

function triggerEdit() {
  emit('edit:trigger')
}

const notDefaultOrAdmin = () => ![1, 2].includes(props.id)

</script>
