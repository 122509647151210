import { reactive, toRaw } from "vue";
import type { ProductTeaserForm, ProductTeaserData } from "@/types/props";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import Validator from "validatorjs";
import { VITE_API_IMG_PROXY_BASE_URL } from '@/application.config'
import type {ProductTeaserEditRequest} from "@/types/requests";

export default function useProductTeaserForm() {

    const form = reactive<ProductTeaserForm>({
        title: '',
        description: '',
        locale: '',
        geo: [],
        rating: 0,
        stream_id: 0,
        manual_tops: [],
        exclude_rotation: false,
        image_sqr: null,
        image_rect: null,
        has_image_changed: 0
    })

    const errors = reactive<Record<string, string[]>>({
        title: [],
        description: [],
        locale: [],
        geo: [],
        rating: [],
        manual_tops: [],
        images: [],
        stream_id: [],
    })

    function validateForm(): boolean {
        const validator = new Validator(form, {
            title: 'required|between:20,120',
            description: 'required',
            locale: 'not_in:0',
            stream_id: 'not_in:0',
            rating: 'not_in:0',
            geo: 'required|array',
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.title = validator.errors.get('title')
            errors.description = validator.errors.get('description')
            errors.locale = validator.errors.get('locale')
            errors.stream_id = validator.errors.get('stream_id')
            errors.rating = validator.errors.get('rating')
            errors.geo = validator.errors.get('geo')
        }

        if (!form.image_rect?.is_ready || !form.image_sqr?.is_ready) {
            errors.images.push("Необходимо выбрать оба изображения")
        }

        return passes && form.image_rect?.is_ready === true && form.image_sqr?.is_ready === true
    }

    function validateEditForm(): boolean {
        const validator = new Validator(form, {
            title: 'required|between:20,120',
            description: 'required|between:20,75',
            locale: 'not_in:0',
            stream_id: 'not_in:0',
            rating: 'not_in:0',
            geo: 'required|array',
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.title = validator.errors.get('title')
            errors.description = validator.errors.get('description')
            errors.locale = validator.errors.get('locale')
            errors.stream_id = validator.errors.get('stream_id')
            errors.rating = validator.errors.get('rating')
            errors.geo = validator.errors.get('geo')
        }
        return passes
    }

    function clearErrors() {
        errors.title = []
        errors.description = []
        errors.locale = []
        errors.images = []
        errors.geo = []
        errors.rating = []
        errors.manual_tops = []
        errors.stream_id = []
    }

    function clear() {
        form.title = ''
        form.description = ''
        form.locale = '0'
        form.stream_id = 0
        form.geo = []
        form.rating = 0
        form.manual_tops = []
        form.exclude_rotation = false
        form.image_sqr = null
        form.image_rect = null
        clearErrors()
    }

    function set(teaser: ProductTeaserData) {
        let image = teaser.images.find((img) => img.type === 1)
        form.title = teaser.title
        form.description = teaser.description
        form.locale = teaser.locale
        form.geo = teaser.stream.geo.length? teaser.stream.geo.split(',') : []
        form.stream_id = teaser.stream_id
        form.rating = teaser.rating
        form.manual_tops = teaser.manual_tops
        form.exclude_rotation = Boolean(teaser.exclude_rotation)
        form.image_rect = { src: '', is_copy: true, is_ready: true, result: { coordinates: { top: 0, left: 0, height: 300, width: 500 }, image: image?.path_rect ? VITE_API_IMG_PROXY_BASE_URL + image?.path_rect : '' } }
        form.image_sqr = { src: '', is_copy: true, is_ready: true, result: { coordinates: { top: 0, left: 0, height: 500, width: 500 }, image: image?.path_square ? VITE_API_IMG_PROXY_BASE_URL + image?.path_square : '' } }
    }

    async function prepareToRequest() {
        let data = new FormData();

        data.append('title', form.title)
        data.append('description', form.description)
        data.append('locale', form.locale)
        data.append('stream_id', form.stream_id.toString())
        data.append('rating', form.rating.toString())
        data.append('exclude_rotation', Number(form.exclude_rotation).toString())
        data.append('img_rect_padding_top', Math.floor(form.image_rect!.result!.coordinates!.top).toString())
        data.append('img_rect_padding_left', Math.floor(form.image_rect!.result!.coordinates!.left).toString())
        data.append('img_rect_box_width', Math.floor(form.image_rect!.result!.coordinates!.width).toString())
        data.append('img_rect_box_height', Math.floor(form.image_rect!.result!.coordinates!.height).toString())
        data.append('img_sqr_padding_top', Math.floor(form.image_sqr!.result!.coordinates!.top).toString())
        data.append('img_sqr_padding_left', Math.floor(form.image_sqr!.result!.coordinates!.left).toString())
        data.append('img_sqr_box_width', Math.floor(form.image_sqr!.result!.coordinates!.width).toString())
        data.append('img_sqr_box_height', Math.floor(form.image_sqr!.result!.coordinates!.height).toString())

        for (let manualTopId of form.manual_tops) {
            data.append('manual_tops[]', manualTopId.toString())
        }

        await addFile(form.image_rect!.src, data)
        return data
    }

    async function prepareToCopyRequest() {
        let data = new FormData();

        data.append('title', form.title)
        data.append('description', form.description)
        data.append('locale', form.locale)
        data.append('geo', form.geo.join(','))
        data.append('stream_id', form.stream_id.toString())
        data.append('rating', form.rating.toString())
        data.append('exclude_rotation', Number(form.exclude_rotation).toString())
        data.append('has_image_changed', form.has_image_changed.toString())
        if (form.has_image_changed) {
            data.append('img_rect_padding_top', Math.floor(form.image_rect!.result!.coordinates!.top).toString())
            data.append('img_rect_padding_left', Math.floor(form.image_rect!.result!.coordinates!.left).toString())
            data.append('img_rect_box_width', Math.floor(form.image_rect!.result!.coordinates!.width).toString())
            data.append('img_rect_box_height', Math.floor(form.image_rect!.result!.coordinates!.height).toString())
            data.append('img_sqr_padding_top', Math.floor(form.image_sqr!.result!.coordinates!.top).toString())
            data.append('img_sqr_padding_left', Math.floor(form.image_sqr!.result!.coordinates!.left).toString())
            data.append('img_sqr_box_width', Math.floor(form.image_sqr!.result!.coordinates!.width).toString())
            data.append('img_sqr_box_height', Math.floor(form.image_sqr!.result!.coordinates!.height).toString())
            await addFile(form.image_rect!.src, data)
        }

        return data
    }

    function prepareToUpdateRequest(): ProductTeaserEditRequest {
        return {
            stream_id: form.stream_id,
            title: form.title,
            description: form.description,
            locale: form.locale,
            rating: form.rating,
            exclude_rotation: Number(form.exclude_rotation)
        }
    }

    async function addFile(src: string, formData: FormData) {
        let visual = await fetch(src);
        let visual_as_blob = await visual.blob();
        var visual_ext = visual_as_blob.type.split('/').pop();
        let visual_as_file = new File([visual_as_blob], 'file-name-renamed.' + visual_ext, { lastModified: new Date().getTime(), type: visual_as_blob.type });
        formData.append('img', visual_as_file);
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    return {
        form,
        errors,
        validateForm,
        clearErrors,
        set,
        clear,
        populateErrors,
        prepareToRequest,
        prepareToUpdateRequest,
        validateEditForm,
        prepareToCopyRequest
    }
}
