<template>
  <div class="transition origin-top flex flex-col mt-2">
    <div class="flex gap-3 items-end">
      <CustomSelectInput v-model="section" parameter="section" :options="sectionOptions" label="Раздел" placeholder="Выберите из списка"/>
      <AddButton @click="addSection"/>
    </div>

    <div class="mt-6">
      <h4 class="text-black font-medium ">Доступные разделы</h4>
      <div v-if="sections.length" class="p-4 bg-whiten rounded mt-2 flex flex-col gap-2 max-h-[240px] overflow-y-auto">
        <div v-for="(section, index) in sections" class="rounded p-3 bg-white">
          <div class="flex w-full justify-between align-center">
            <p class="text-black font-medium">{{ section.name }}</p>
            <TrashIcon @click="deleteSection(index)" class="fill-body cursor-pointer hover:fill-red"/>
          </div>
          <div class="mt-2 gap-2 flex flex-wrap">
            <Checkbox
                v-model="section.permissions.read"
                :label="translatePermissionType('read')"
                :disabled="section.permissions.edit || section.permissions.delete"
            />
            <Checkbox
                v-model="section.permissions.personnel_read"
                :label="translatePermissionType('personnel_read')"
                :disabled="section.permissions.create || section.permissions.personnel_edit || section.permissions.personnel_delete"
            />
            <Checkbox v-model="section.permissions.create" :label="translatePermissionType('create')" @change="checkReadProperty(index)"/>
            <Checkbox v-model="section.permissions.edit" :label="translatePermissionType('edit')" @change="checkReadProperty(index)"/>
            <Checkbox v-model="section.permissions.personnel_edit" :label="translatePermissionType('personnel_edit')" @change="checkReadProperty(index)"/>
            <Checkbox v-model="section.permissions.delete" :label="translatePermissionType('delete')" @change="checkReadProperty(index)"/>
            <Checkbox v-model="section.permissions.personnel_delete" :label="translatePermissionType('personnel_delete')" @change="checkReadProperty(index)"/>
          </div>
        </div>
      </div>
      <p v-else class="mt-2">Ни один раздел еще не добавлен</p>
      <p v-if="errors && errors.length" class="mt-2 text-red" v-html="errors.join('<br>')"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, type Ref} from "vue";
import type {PermissionForm, PermissionSection, SelectOption} from "@/types/props";
import translatePermissionType from "@/helpers/translatePermissionType";
import AddButton from "@/shared/Buttons/AddButton.vue";
import TrashIcon from "@/icons/TrashIcon.vue";
import Checkbox from "@/shared/Inputs/Checkbox.vue";
import CustomSelectInput from "@/shared/Inputs/CustomSelectInput.vue";
import {useRoleForm} from "@/stores/roleForm";

const props = defineProps<{
  sectionOptions: SelectOption[]
  errors?: string[]
}>()

const roleForm = useRoleForm()

const sections: Ref<PermissionSection[]> = defineModel({ required: true })

const section = ref('')

function addSection(): void {
  const seekSection = props.sectionOptions.find(option => option.key === section.value)
  if (seekSection) {
    if (sections.value.find(formSection => formSection.key === section.value)) return

    sections.value.push({
      key: seekSection.key.toString(),
      name: seekSection.value.toString(),
      permissions: roleForm.emptyPermissionForm()
    })
  }
}

function deleteSection(index: number): void {
  if (sections.value[index]) {
    sections.value.splice(index, 1)
  }
}

function checkReadProperty(index: number) {
  const permissions = sections.value[index].permissions;

  if (permissions.edit || permissions.delete) {
    sections.value[index].permissions.read = true

  } else if (permissions.create || permissions.personnel_edit || permissions.personnel_delete) {
    sections.value[index].permissions.personnel_read = true
  }
}

</script>
