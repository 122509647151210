<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div v-if="visible"
        class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-142.5 rounded-lg bg-white py-12 px-8 text-center dark:bg-boxdark md:py-15 md:px-17.5">
          <span class="mx-auto inline-block">
            <WarningIcon />
          </span>
          <h3 class="mt-5.5 pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl mb-10">
            <slot name="title"></slot>
          </h3>
          <p class="mt-5.5 pb-2 text-sm text-darkgray dark:text-white sm:text-sm mb-10">
            <slot name="text"></slot>
          </p>
          <div class="-mx-3 flex flex-wrap gap-y-4">
            <div class="w-full px-3 2xsm:w-1/2">
              <button @click="closeSelf"
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1">
                Отмена
              </button>
            </div>
            <div class="w-full px-3 2xsm:w-1/2">
              <button @click="submit(id)"
                class="block w-full rounded border border-meta-1 bg-meta-1 p-3 text-center font-medium text-white transition hover:bg-opacity-90">
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import WarningIcon from "@/icons/WarningIcon.vue";
import { type Ref } from "vue";

const props = defineProps<{
  submit: Function,
  id: number | null
}>()

const visible: Ref<boolean> = defineModel({ required: true })

const emit = defineEmits(['refresh:table', 'init:action'])

function closeSelf() {
  visible.value = false
}

</script>
