<template>
  <div>
    <label v-if="label" class="mb-3 block text-sm font-medium text-black dark:text-white">
      {{ label }}
    </label>

    <div class="relative z-20 bg-transparent dark:bg-form-input">
      <span class="absolute top-1/2 left-4 z-30 -translate-y-1/2"><GeoIcon/></span>
      <select
          v-model="selectModel"
          :disabled="disabled"
          class="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-12 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
          :class="{ 'border-red': errors && errors.length }"
      >
        <option value="">{{ placeholder }}</option>
        <option v-for="option in options" :value="option.key">{{ option.value }}</option>
      </select>
      <span class="absolute top-1/2 right-4 z-30 -translate-y-1/2"><CaretInputIcon/></span>
    </div>
    <p v-if="errors" class="text-red mt-2">{{ errors.join(', ') }}</p>
  </div>
</template>

<script setup lang="ts">
import {type Ref} from "vue";
import type {AlphaNumerical, SelectOption} from "@/types/props";
import CaretInputIcon from "@/icons/CaretInputIcon.vue";
import GeoIcon from "@/icons/GeoIcon.vue";

defineProps<{
  parameter: string
  placeholder: string
  options: SelectOption[]
  label?: string
  disabled?: boolean
  errors?: string[]
}>()

const selectModel: Ref<AlphaNumerical> = defineModel({ required: true })
</script>
