<template>
  <Teleport to="#modalContainer">
    <Transition name="fade">
      <div  v-if="visible" class="fixed top-0 left-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5">
        <div class="w-full max-w-[625px] rounded-lg bg-white dark:bg-boxdark max-h-full overflow-y-auto">

          <div class="flex w-full justify-between border-b border-stroke p-8.5">
            <h3 class="font-inter font-bold text-2xl text-black">Добавление тизеров</h3>
            <CloseButton @click="closeSelf" />
          </div>

          <div class="p-8.5 !pb-6">
            <TextArea
                v-model="teasers"
                parameter="addedTeasers"
                label="Введите id тизеров"
            />

            <div class="flex justify-between mt-8">
              <div class="flex gap-3">
                <StandardButton @click="addTeasers">Добавить</StandardButton>
                <StandardButton @click="replaceTeasers" class="bg-danger">Заменить</StandardButton>
              </div>
              <ClearButton @click="closeSelf">Закрыть</ClearButton>
            </div>
          </div>
        </div>

      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {ref, type Ref} from "vue";
import CloseButton from "@/shared/Buttons/CloseButton.vue";
import TextArea from "@/shared/Inputs/TextArea.vue";
import StandardButton from "@/shared/Buttons/StandardButton.vue";
import ClearButton from "@/shared/Buttons/ClearButton.vue";

const visible: Ref<boolean> = defineModel({ required: true })

const emit = defineEmits(['teasers:add', 'teasers:replace'])
const teasers = ref('')

function closeSelf() {
  visible.value = false
  teasers.value = ''
}

function addTeasers() {
  emit('teasers:add', teasers.value.split('\n').map(id => {
    if (id) return Number(id)
  }))
  closeSelf()
}

function replaceTeasers() {
  emit('teasers:replace', teasers.value.split('\n').map(id => {
    if (id) return Number(id)
  }))
  closeSelf()
}

</script>
