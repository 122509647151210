import { reactive, toRaw } from "vue";
import type { AccountData, AccountForm } from "@/types/props";
import { UserServiceValidationError } from "@/errors/UserServiceValidationError";
import Validator from "validatorjs";

export default function useAccountForm() {

    const form = reactive<AccountForm>({
        utm_source: '',
        source_id: 0,
        campaign_settings_template_id: 0,
        login: '',
        password: '',
        is_shared: true,
        users: []
    })

    const errors = reactive<Record<string, string[]>>({
        utm_source: [],
        source_id: [],
        campaign_settings_template_id: [],
        login: [],
        password: [],
        users: []
    })

    function validateForm(): boolean {
        const validator = new Validator(toRaw(form), {
            utm_source: 'required',
            source_id: 'required',
            campaign_settings_template_id: 'required',
            login: 'required',
            password: 'required',
            users: 'required'
        })

        const passes = Boolean(validator.passes())

        if (!passes) {
            errors.utm_source = validator.errors.get('utm_source')
            errors.source_id = validator.errors.get('source_id')
            errors.campaign_settings_template_id = validator.errors.get('campaign_settings_template_id')
            errors.login = validator.errors.get('login')
            errors.password = validator.errors.get('password')
            errors.users = validator.errors.get('users')
        }

        return passes
    }


    function clearErrors() {
        errors.utm_source = []
        errors.source_id = []
        errors.campaign_settings_template_id = []
        errors.login = []
        errors.password = []
        errors.users = []
    }

    function clear() {
        form.utm_source = ''
        form.source_id = 0
        form.campaign_settings_template_id = 0
        form.login = ''
        form.password = ''
        form.is_shared = true
        form.users = []
        clearErrors()
    }



    function set(accountData: AccountData) {
        form.utm_source = accountData.utm_source
        form.source_id = accountData.source_id
        form.campaign_settings_template_id = accountData.campaign_settings_template_id
        form.login = accountData.login
        form.password = accountData.password
        form.is_shared = accountData.is_shared === 1
        form.users = Object.keys(accountData.users)
    }

    function populateErrors(err: UserServiceValidationError): void {
        for (const [field, validationErrors] of Object.entries(err.validationErrors)) {
            if (errors.hasOwnProperty(field)) {
                errors[field] = validationErrors
            }
        }
    }

    return {
        form,
        errors,
        validateForm,
        clearErrors,
        set,
        clear,
        populateErrors
    }
}
