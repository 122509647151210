import {defineStore} from "pinia";
import {computed, ref} from "vue";
import type {LoadingQueue} from "@/types/props";

export const useLoadingQueue = defineStore('loadingQueue', () => {
    const loadingQueue: LoadingQueue = ref(new Set<string>())
    const isLoading = computed(() => loadingQueue.value.size !== 0)

    return {
        loadingQueue,
        isLoading
    }
})
